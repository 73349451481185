import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { Organization } from 'src/app/models/organization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { AppData } from 'src/app/singletons/app-data';

@Component({
  selector: 'app-preregister',
  templateUrl: './preregister.component.html',
  styleUrls: ['./preregister.component.css']
})
export class PreregisterComponent implements OnInit {

  public preregisterToken = '';

  public password = '';
  public passwordAgain = '';
  public name = '';

  constructor(
    private _organizationService: OrganizationService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    public app: AppComponent,
    private _appData: AppData,
    private _translate: TranslateService,
  ) { }

  ngOnInit(): void {
    const urlSplitted = this._router.url.split('/');

    // firstly, try to grab identificationCode from url
    if (urlSplitted.length === 3 && urlSplitted[urlSplitted.length - 1].length === 24) {
      // code from url should have 16 characters
      this.preregisterToken = urlSplitted[urlSplitted.length - 1];
    }

    // should be filled
    if (this.preregisterToken === '') {
      this._router.navigate(['/'], { replaceUrl: true });
    }

    this.app.showLoading();
    // check if token valid and fetch prefilled name
    this._authenticationService
      .checkPreregisterToken(this.preregisterToken)
      .subscribe((res) => {
        this.name = res.name as string;
        this.app.hideLoading();
      }, () => {
        this._translate.get('AUTH.PREREGISTER.N_PREREGISTER_TOKEN_CHECK_WRONG').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
        this._router.navigate(['/'], { replaceUrl: true });
        this.app.hideLoading();
      });
  }

  public register(): void {
    if (!this.password
      || !/(?=.*\d)(?=.*[a-z,A-Z]).{8,}/.test(this.password)) {
      this._translate.get('AUTH.CHANGE_PASSWORD.N_FILL_NEW_PASSWORD').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    if (!this.passwordAgain
      || !/(?=.*\d)(?=.*[a-z,A-Z]).{8,}/.test(this.passwordAgain)) {
      this._translate.get('AUTH.CHANGE_PASSWORD.N_FILL_NEW_PASSWORD_AGAIN').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    if (this.password !== this.passwordAgain) {
      this._translate.get('AUTH.CHANGE_PASSWORD.N_PASSWORDS_SAME').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    if (this.name === '') {
      this._translate.get('AUTH.PREREGISTER.N_NAME_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    this.app.showLoading();
    this._authenticationService
      .preregister(this.preregisterToken, this.password, this.passwordAgain, this.name)
      .subscribe((res) => {
        this._setOrganization(res.organizationCode as string);

        this._translate.get('AUTH.PREREGISTER.N_PREREGISTER_SUCCESSFUL').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
        this._router.navigate(['login'], { replaceUrl: true });
        this.app.hideLoading();
      }, (errorStatus) => {
        if (errorStatus < 500) {
          this._translate.get('GENERAL.N_BAD_REQUEST').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
        } else {
          this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
        }
        this.app.hideLoading();
      });
  }

  private _setOrganization(organizationCode: string): void {
    localStorage.setItem('identificationCode', organizationCode);

    if (this._appData.organizationValue.identificationCode.length === 0
      || this._appData.organizationValue._id !== organizationCode) {
      // organization is unknown for now or is not set right, try to find it by organizationId
      this._organizationService
        .get(organizationCode)
        .subscribe((res) => {
          if (res) {
            this._appData.setOrganization(new Organization(res._id, res.name, res.language, res._id, res.state, res.categories));
            localStorage.setItem('identificationCode', res._id);
          }
        });
    }
  }
}
