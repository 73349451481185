/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ContentChange } from 'ngx-quill';
import { AppComponent } from 'src/app/app.component';
import { NotifyAbout } from 'src/app/models/report-state';

@Component({
  selector: 'app-notify-about',
  templateUrl: './notify-about.component.html',
  styleUrls: ['./notify-about.component.css']
})
export class NotifyAboutComponent implements OnInit {

  @ViewChild('template', { static: true }) template!: TemplateRef<unknown>;

  @Input() notifyAboutObject!: NotifyAbout;

  @Output() goToNextStateNotifyNow = new EventEmitter();

  public notifyNowTextLength = 0;

  constructor(
    private viewContainerRef: ViewContainerRef,
    public app: AppComponent,
  ) { }

  ngOnInit(): void {
    //this and ViewChild (and ng-template in html file) are here to create embedded view (without additional html tag)
    this.viewContainerRef.createEmbeddedView(this.template);
  }

  public notifyNowTextChanged(event: ContentChange): void {
    this.notifyNowTextLength = event.text.length - 1;
  }
}
