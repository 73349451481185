/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ContentChange } from 'ngx-quill';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { ConfirmDialogComponent } from 'src/app/components/widgets/confirm-dialog/confirm-dialog.component';
import { Attachment, Report } from 'src/app/models/report';
import { ExecutionState, NotifyCompetentPersonAboutExecutionState } from 'src/app/models/report-state';
import { ReportStateService } from 'src/app/services/report-state.service';
import { AppData } from 'src/app/singletons/app-data';
import { Constants } from 'src/app/utilities/constants';
import { ReportStateHelper } from 'src/app/utilities/report-state-helper';

@Component({
  selector: 'app-notify-competent-person-about-execution-state',
  templateUrl: './notify-competent-person-about-execution-state.component.html',
  styleUrls: ['./notify-competent-person-about-execution-state.component.css']
})
export class NotifyCompetentPersonAboutExecutionStateComponent implements OnInit, OnDestroy {

  public reportStateHelper = ReportStateHelper;

  public report!: Report;

  private _activeReportStateSubscription$!: Subscription;
  public state!: NotifyCompetentPersonAboutExecutionState;

  public stateFormData = new FormData();

  public notifyCompetentPersonTextLength = 0;

  private _stateApi = '';

  constructor(
    private _appData: AppData,
    private _reportStateService: ReportStateService,
    public dialog: MatDialog,
    public app: AppComponent,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    if (!this._appData.activeReportValue) {
      return;
    }

    this.report = this._appData.activeReportValue;
    this._activeReportStateSubscription$ = this._appData.activeReportState.subscribe((state) => {
      if (!state) {
        return;
      }

      this.state = state as NotifyCompetentPersonAboutExecutionState;
      this._stateApi = Constants.stateApiMap.get(this.state.id) ?? '';

      const executionState = this.report.states.find(state => state.id === 'ExecutionState') as ExecutionState;

      if (!this.state.notifyCompetentPersonTextHtml) {
        this.translate.get('OVERVIEW.DETAIL.NOTIFY_CP_EXECUTION.T_NOTIFY_CP_EXECUTION_HTML', {
          executionText: executionState?.executionTextHtml ?? '',
          oeName: this._appData.accountValue?.name ?? '',
          orgName: this._appData.organizationValue.name,
        }).subscribe((res: string) => {
          this.state.notifyCompetentPersonTextHtml = res;
        }).unsubscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this._activeReportStateSubscription$.unsubscribe();
  }

  public goBack(): void {
    this._appData.setActiveReportState(null);
  }

  public textChanged(event: ContentChange): void {
    this.notifyCompetentPersonTextLength = event.text.length - 1;
  }

  public confirm(): void {
    if (!this.state.notifyCompetentPersonTextHtml || this.state.notifyCompetentPersonTextHtml.trim().length === 0) {
      this.translate.get('OVERVIEW.DETAIL.NOTIFY_CP_EXECUTION.FILL_TEXT').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    this.translate.get('OVERVIEW.DETAIL.NOTIFY_CP_EXECUTION.DIALOG_TEXT').subscribe((res: string) => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '40rem',
        panelClass: 'dialog-container',
        data: {
          title: res,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (!result) {
          return;
        }

        this.stateFormData.set('state', JSON.stringify(this.state));

        this.app.showLoading();
        this._reportStateService.send(`${this._stateApi}/confirm`, this.report._id, this.report.lastUpdated, this.stateFormData).subscribe(res => {
          if (res) {
            // replace old report with the new one
            this.report = res.report;
            this._appData.setActiveReport(res.report);
            this._appData.replaceSingleReport(res.report);
            this._appData.setNewMessage(res.message);
            // set active report to null (=> redirect to states overview)
            this._appData.setActiveReportState(null);
            this.translate.get('OVERVIEW.DETAIL.NOTIFY_CP_ACCEPTED.N_MESSAGE_SENT').subscribe((res: string) => {
              this.app.buildNotification(res);
            }).unsubscribe();
          }
          this.app.hideLoading();
        }, (errorStatus) => {
          if (errorStatus === 409) {
            this.translate.get('GENERAL.N_DATA_CHANGED').subscribe((res: string) => {
              this.app.buildNotification(res);
            }).unsubscribe();
          } else {
            this.translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
              this.app.buildNotification(res);
            }).unsubscribe();
            this.app.hideLoading();
          }
        });
      });
    }).unsubscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onFileSelected(event: any): void {
    const attachment: File = event.target.files[0];

    if (!attachment) {
      return;
    }

    if (attachment.size > Constants.maxSizeOfInputFile) {
      this.translate.get('GENERAL.N_ATTACHMENT_SIZE_LIMIT').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    this.stateFormData.set('attachment', attachment);
    this.state.attachment = new Attachment('', attachment.name);
  }

  public removeAttachment(): void {
    if (!this.state.attachment) {
      return;
    }

    this.stateFormData.delete('attachment');
    this.translate.get('GENERAL.N_ATTACHMENT_REMOVED', { name: this.state.attachment.name }).subscribe((res: string) => {
      this.app.buildNotification(res);
    }).unsubscribe();
    this.state.attachment = undefined;
  }
}