import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AppComponent } from 'src/app/app.component';
import { Note, Report } from 'src/app/models/report';
import { AppData } from 'src/app/singletons/app-data';
import { ReportService } from 'src/app/services/report.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ReportStateHelper } from 'src/app/utilities/report-state-helper';
import { Subscription } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NoteDialogComponent } from '../../widgets/note-dialog/note-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/utilities/constants';
import { Account } from 'src/app/models/account';
import { ReportCategory } from 'src/app/models/report-category';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';

class AdvancedFilter {
  fullText: string;
  fullTextRaw: string;
  number: number | undefined;
  dateFrom: Date | undefined;
  dateTo: Date | undefined;
  state: string;
  deadline: string;

  constructor() {
    this.fullText = '';
    this.fullTextRaw = '';
    this.number = undefined;
    this.dateFrom = undefined;
    this.dateTo = undefined;
    this.state = '-';
    this.deadline = '-';
  }
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit, OnDestroy {

  public utilities = Utilities;
  public reportStateHelper = ReportStateHelper;

  public reportCategories: ReportCategory[] = [];
  public categoryNewReportsCountMap = new Map<string, number>();

  private _activeCategorySubscription$!: Subscription;
  public activeCategory: ReportCategory | null = null;

  private _reportsSubscription$!: Subscription;
  public reports: Report[] = [];

  private _activeRoleSubscription$!: Subscription;
  public activeRole!: string;

  private _accountSubscription$!: Subscription;
  public account!: Account | null;

  // Table
  public dataSource!: MatTableDataSource<Report>;
  @ViewChild(MatPaginator)
  set paginator(value: MatPaginator) {
    if (this.dataSource !== undefined) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort) sort!: MatSort;
  public headersCp: string[] = [];
  public headersOe: string[] = [];

  private _shouldReloadReports = false;
  private _workingWithNote = false;

  public searchPlaceholder = '';

  public reportStateLabelMap!: Map<string, string>;

  public advancedFilterVisible = false;
  public advancedFilter = new AdvancedFilter();
  public advancedFilterStateLabels = ['-'];
  public advancedFilterDeadlineLabels = ['-'];

  constructor(
    private _appData: AppData,
    private _router: Router,
    private _reportService: ReportService,
    public app: AppComponent,
    public dialog: MatDialog,
    public translate: TranslateService,
  ) {
    this.translate.get('OVERVIEW.REPORTS.SEARCH_PLACEHOLDER').subscribe((res: string) => {
      this.searchPlaceholder = res;
    }).unsubscribe();
  }

  private _setCategoryNewReportsCountMap(category?: ReportCategory): void {
    this.reportCategories.forEach(c => {
      if (!category || c.id === category.id) {
        const newReportsCount = this.reports.filter(r => !r.seen && r.detail.categoryId === c.id).length;
        this.categoryNewReportsCountMap.set(c.id, newReportsCount);
      }
    });
  }

  private _setReports(reports: Report[]): void {
    this.reports = reports;
    this._setCategoryNewReportsCountMap();
    // reports filtered by activeCategory
    const reportsView = this.reports.filter(report => report.detail.categoryId === this.activeCategory?.id);
    this.dataSource = new MatTableDataSource(reportsView);
    this.dataSource.sort = this.sort;

    this.reportStateLabelMap = new Map<string, string>();
    reportsView.forEach(report => {
      const translateId = ReportStateHelper.getStateLabelTranslateId(report.states[report.states.length - 1], true, false);
      const label = this.translate.instant(`OVERVIEW.DETAIL.STATE_LABELS.${translateId}`, { tillDays: ReportStateHelper.getStateTillDays(report) });
      this.reportStateLabelMap.set(report._id, label);
    });

    this.dataSource.sortingDataAccessor = this._sortingDataAccessor;

    this.advancedFilterStateLabels = [
      '-',
      ...(Constants.stateLabelsForRolesMap.get(this.activeRole)?.get(this.activeCategory?.type ?? '') ?? [])
    ];

    if (this.activeRole === 'cp') {
      this.advancedFilterDeadlineLabels = this.activeCategory?.type === 'serious'
        ? [
          '-',
          'ADVANCED_FILTER_DEADLINE_ERROR',
          'ADVANCED_FILTER_DEADLINE_WARNING',
          'ADVANCED_FILTER_DEADLINE_RUNNING',
          'ADVANCED_FILTER_DEADLINE_WAITING',
          'ADVANCED_FILTER_DEADLINE_ARCHIVED',
        ]
        : [
          '-',
          'ADVANCED_FILTER_DEADLINE_RUNNING',
          'ADVANCED_FILTER_DEADLINE_WAITING',
          'ADVANCED_FILTER_DEADLINE_ARCHIVED',
        ];

      this.dataSource.filterPredicate = (report: Report, filter: string) => {
        const advancedFilter: AdvancedFilter = JSON.parse(filter);

        const tillDays = ReportStateHelper.getStateTillDays(report);

        const fullTextFilterResult = report.detail.number.toString().indexOf(advancedFilter.fullText) !== -1
          || Utilities.dateFormat(report.detail.reportedAt).indexOf(advancedFilter.fullText) !== -1
          || report.detail.subject.toLowerCase().indexOf(advancedFilter.fullText) !== -1
          || this.reportStateLabelMap.get(report._id)?.toLowerCase().indexOf(advancedFilter.fullText) !== -1
          || (tillDays?.toString() ?? '-').toLowerCase().indexOf(advancedFilter.fullText) !== -1;

        const numberFilterResult = this.advancedFilter.number ? report.detail.number === this.advancedFilter.number : true;

        const dateFilterResult = Utilities.isBetweenDatesOnlyDate(new Date(report.detail.reportedAt), this.advancedFilter.dateFrom, this.advancedFilter.dateTo);

        const statesFilterResult = this.advancedFilter.state === '-'
          ? true
          : this.advancedFilter.state === ReportStateHelper.getStateLabelTranslateId(report.states[report.states.length - 1], true, false);

        let deadlineFilterResult = true;
        switch (this.advancedFilter.deadline) {
          case 'ADVANCED_FILTER_DEADLINE_ERROR':
            deadlineFilterResult = (tillDays !== undefined && tillDays !== null) ? (tillDays as number) <= 0 : false;
            break;
          case 'ADVANCED_FILTER_DEADLINE_WARNING':
            deadlineFilterResult = tillDays ? ((tillDays as number) > 0 && (tillDays as number) <= Constants.tillDaysDeadlineWarning) : false;
            break;
          case 'ADVANCED_FILTER_DEADLINE_RUNNING':
            deadlineFilterResult = tillDays ? (tillDays as number) > Constants.tillDaysDeadlineWarning : false;
            break;
          case 'ADVANCED_FILTER_DEADLINE_WAITING':
            deadlineFilterResult = report.states[report.states.length - 1].id === 'WaitingForEvaluationByObligedEntityState';
            break;
          case 'ADVANCED_FILTER_DEADLINE_ARCHIVED':
            deadlineFilterResult = report.states[report.states.length - 1].id.startsWith('ArchivedForRoleState');
            break;
          default:
            deadlineFilterResult = true;
            break;
        }

        return fullTextFilterResult && numberFilterResult && dateFilterResult && statesFilterResult && deadlineFilterResult;
      }
    } else {
      this.advancedFilterDeadlineLabels = this.activeCategory?.type === 'serious'
        ? [
          '-',
          'ADVANCED_FILTER_DEADLINE_ERROR',
          'ADVANCED_FILTER_DEADLINE_WARNING',
          'ADVANCED_FILTER_DEADLINE_RUNNING',
          'ADVANCED_FILTER_DEADLINE_ARCHIVED',
        ]
        : [
          '-',
          'ADVANCED_FILTER_DEADLINE_RUNNING',
          'ADVANCED_FILTER_DEADLINE_ARCHIVED',
        ];

      this.dataSource.filterPredicate = (report: Report, filter: string) => {
        const advancedFilter: AdvancedFilter = JSON.parse(filter);

        const tillDays = ReportStateHelper.getStateTillDays(report);

        const fullTextFilterResult = report.detail.number.toString().indexOf(advancedFilter.fullText) !== -1
          || Utilities.dateFormat(report.detail.reportedAt).indexOf(advancedFilter.fullText) !== -1
          || (report.competentPerson?.name && report.competentPerson.name.toLowerCase().indexOf(advancedFilter.fullText) !== -1)
          || (report.summaryByCompetentPerson && report.summaryByCompetentPerson.toLowerCase().indexOf(advancedFilter.fullText) !== -1)
          || this.reportStateLabelMap.get(report._id)?.toLowerCase().indexOf(advancedFilter.fullText) !== -1
          || (ReportStateHelper.getStateTillDays(report)?.toString() ?? '-').toLowerCase().indexOf(advancedFilter.fullText) !== -1;

        const numberFilterResult = this.advancedFilter.number ? report.detail.number === this.advancedFilter.number : true;

        const dateFilterResult = Utilities.isBetweenDatesOnlyDate(new Date(report.detail.reportedAt), this.advancedFilter.dateFrom, this.advancedFilter.dateTo);

        const statesFilterResult = this.advancedFilter.state === '-'
          ? true
          : this.advancedFilter.state === ReportStateHelper.getStateLabelTranslateId(report.states[report.states.length - 1], true, false);

        let deadlineFilterResult = true;
        switch (this.advancedFilter.deadline) {
          case 'ADVANCED_FILTER_DEADLINE_ERROR':
            deadlineFilterResult = (tillDays !== undefined && tillDays !== null) ? (tillDays as number) <= 0 : false;
            break;
          case 'ADVANCED_FILTER_DEADLINE_WARNING':
            deadlineFilterResult = tillDays ? ((tillDays as number) > 0 && (tillDays as number) <= Constants.tillDaysDeadlineWarning) : false;
            break;
          case 'ADVANCED_FILTER_DEADLINE_RUNNING':
            deadlineFilterResult = tillDays ? (tillDays as number) > Constants.tillDaysDeadlineWarning : false;
            break;
          case 'ADVANCED_FILTER_DEADLINE_ARCHIVED':
            deadlineFilterResult = report.states[report.states.length - 1].id.startsWith('ArchivedForRoleState');
            break;
          default:
            deadlineFilterResult = true;
            break;
        }

        return fullTextFilterResult && numberFilterResult && dateFilterResult && statesFilterResult && deadlineFilterResult;
      }
    }
  }

  ngOnInit(): void {
    if (!this._appData.accountValue) {
      return;
    }

    this._activeRoleSubscription$ = this._appData.activeRole.subscribe(activeRole => {
      if (!activeRole) {
        return;
      }

      const activeRoleFromStorage = localStorage.getItem('activeRole');
      if (activeRoleFromStorage) {
        this.activeRole = activeRoleFromStorage;
      }

      // notify that reports should change
      if (!this.activeRole) {
        this.activeRole = activeRole;
        localStorage.setItem('activeRole', this.activeRole);
        return;
      }

      if (this.activeRole !== activeRole) {
        this.activeRole = activeRole;
        this._shouldReloadReports = true;
        this._appData.setReports([]);
        localStorage.setItem('activeRole', this.activeRole);
      }
    });

    this._activeCategorySubscription$ = this._appData.activeCategory.subscribe(activeCategory => {
      this.activeCategory = activeCategory;
      this._setReports(this.reports);
    });

    this._reportsSubscription$ = this._appData.reports.subscribe(reports => {
      if (reports && !this._shouldReloadReports) {
        this._setReports(reports);
        return;
      }
      this._shouldReloadReports = false;

      if (!this._appData.accountValue) {
        return;
      }

      this.app.showLoading();
      this._reportService.getAll(this._appData.accountValue.organizationId, this.activeRole).subscribe(reports => {
        if (reports) {
          this._appData.setReports(reports);
        }
        this.app.hideLoading();
      }, () => {
        this.translate.get('OVERVIEW.REPORTS.N_REPORTS_NOT_LOADED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();

        this.app.hideLoading();
      });
    });

    this._accountSubscription$ = this._appData.account.subscribe(account => {
      this.account = account;
      this.reportCategories = this.account?.categories ?? this._appData.organizationValue.categories;

      if (this._appData.activeCategoryValue === null) {
        // set first category as active if not set already
        this._appData.setActiveCategory(this.reportCategories[0]);
      }
    });

    // do not display tillDays for 'lockedReadOnly'
    this.headersCp = Utilities.canAccountEdit(this.account)
      ? ['action', 'tillDays', 'number', 'reportedAt', 'subject', 'state', 'note']
      : ['action', 'number', 'reportedAt', 'subject', 'state', 'note'];
    this.headersOe = Utilities.canAccountEdit(this.account)
      ? ['action', 'tillDays', 'number', 'reportedByCompetentPerson', 'competentPerson', 'summaryByCompetentPerson', 'state', 'note']
      : ['action', 'number', 'reportedByCompetentPerson', 'competentPerson', 'summaryByCompetentPerson', 'state', 'note'];

    // need to reset active report state, it will be correctly set based on user's actions
    this._appData.setActiveReportState(null);

    this._workingWithNote = false;
    this._shouldReloadReports = false;

    // always set this on component init
    this._setCategoryNewReportsCountMap();
  }

  ngOnDestroy(): void {
    this._accountSubscription$.unsubscribe();
    this._reportsSubscription$.unsubscribe();
    this._activeCategorySubscription$.unsubscribe();
    this._activeRoleSubscription$.unsubscribe();
  }

  public setActiveCategory($event: MatTabChangeEvent): void {
    this._appData.setActiveCategory(this.reportCategories[$event.index]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _sortingDataAccessor(report: Report, property: string): any {
    // let translateId = '';
    switch (property) {
      case 'number': return report.detail.number;
      case 'reportedAt': return report.detail.reportedAt;
      case 'subject': return report.detail.subject;
      // throws err, translate is undefined; if done with map, it does not work either
      // case 'state':
      //   translateId = ReportStateHelper.getStateLabelTranslateId(report.states[report.states.length - 1], true, report.detail.isContactAddressFilled, false);
      //   return this.translate.instant(`OVERVIEW.DETAIL.STATE_LABELS.${translateId}`, { tillDays: ReportStateHelper.getStateTillDays(report) }).toLowerCase();
      case 'tillDays': return ReportStateHelper.getStateTillDays(report);
      // for oe only below:
      case 'reportedByCompetentPerson': return report.detail.reportedAt;
      case 'competentPerson': return report.competentPerson?.name ? report.competentPerson.name : '-';
      case 'summaryByCompetentPerson': return report.summaryByCompetentPerson ? report.summaryByCompetentPerson.toLowerCase() : '';
      default: return '';
    }
  }

  private _applyFilterTimer: number | undefined;
  public applyFilter(): void {
    clearTimeout(this._applyFilterTimer);

    this._applyFilterTimer = window.setTimeout(() => {
      this.advancedFilter.fullText = this.advancedFilter.fullTextRaw.trim().toLowerCase();
      this.advancedFilter.dateFrom = this.advancedFilter.dateFrom ? new Date(this.advancedFilter.dateFrom) : undefined;
      this.advancedFilter.dateTo = this.advancedFilter.dateTo ? new Date(this.advancedFilter.dateTo) : undefined;
      this.dataSource.filter = JSON.stringify(this.advancedFilter);

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }, 300);
  }

  public applyAdvancedFilter(): void {
    this.applyFilter();
  }

  public cancelAdvancedFilter(): void {
    this.advancedFilter = new AdvancedFilter();
    this.applyAdvancedFilter();
  }

  public toggleAdvancedFilter(): void {
    this.advancedFilterVisible = !this.advancedFilterVisible;

    const collapsible = (document.getElementsByClassName('height-transition-container')[0]) as HTMLElement;
    if (this.advancedFilterVisible) {
      let height = 0;
      for (let i = 0; i < collapsible.children.length; i++) {
        const innerNodeElem = collapsible.children[i] as HTMLElement;
        for (let i = 0; i < innerNodeElem.children.length; i++) {
          const innerInnerNodeElem = collapsible.children[i] as HTMLElement;
          height += innerInnerNodeElem.scrollHeight;
        }
        height += innerNodeElem.scrollHeight;
      }
      collapsible.style.maxHeight = `${collapsible.scrollHeight + height}px`;
    } else {
      collapsible.style.maxHeight = '0';
    }
  }

  public reloadData(): void {
    this._shouldReloadReports = true;
    this._appData.setReports([]);
  }

  public getVisualStateInfoClassButton(report: Report): string {
    const tillDays = ReportStateHelper.getStateTillDays(report);
    if (tillDays === undefined) return 'icon-action-button visual-state-archive';
    if (tillDays === false || tillDays === null || (tillDays as number) > Constants.tillDaysDeadlineWarning || !Utilities.canAccountEdit(this.account)) return 'icon-action-button visual-state-ok';
    if ((tillDays as number) > 0) return 'icon-action-button visual-state-warning';
    return 'icon-action-button visual-state-alert';
  }

  public getVisualStateInfoIcon(report: Report): string {
    const tillDays = ReportStateHelper.getStateTillDays(report);
    if (tillDays === undefined) return 'done';
    if (tillDays === null) return 'schedule';
    if (tillDays === false || (tillDays as number) > Constants.tillDaysDeadlineWarning || !Utilities.canAccountEdit(this.account)) return 'north_east';
    if ((tillDays as number) > 0) return 'warning';
    return 'priority_high';
  }

  public goToDetail(report: Report): void {
    if (this._workingWithNote) {
      return;
    }

    if (!report.seen && Utilities.canAccountEdit(this.account)) {
      report.seen = true;
      this._reportService.seen(report._id, this.activeRole).subscribe(res => {
        if (res) {
          report.lastUpdated = res.reportLastUpdated;
        }
      });
    }

    this._appData.setActiveReport(report);
    this._router.navigate([`/overview/detail/${report.detail.number}`]);
  }

  public openNote(report: Report, note: Note): void {
    // prevents from opening detail
    this._workingWithNote = true;

    const noteCopy = new Note(note.id, note.body);

    const dialogRef = this.dialog.open(NoteDialogComponent, {
      width: '40rem',
      panelClass: 'dialog-container',
      data: { note: noteCopy, isEditing: true, report: report, activeRole: this.activeRole, canEdit: Utilities.canAccountEdit(this.account) }
    });

    dialogRef.afterClosed().subscribe(result => {
      this._workingWithNote = false;
      if (!result) {
        return;
      }

      this.translate.get('GENERAL.N_CHANGES_SUCCESSFUL').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
    });
  }

  public addNote(report: Report): void {
    this._workingWithNote = true;

    const lastNoteId = (report.detail.notes && report.detail.notes.length > 0) ? report.detail.notes[report.detail.notes.length - 1].id : 0;

    const newNote = new Note(lastNoteId + 1);

    const dialogRef = this.dialog.open(NoteDialogComponent, {
      width: '40rem',
      panelClass: 'dialog-container',
      data: { note: newNote, isEditing: false, report: report, activeRole: this.activeRole, canEdit: Utilities.canAccountEdit(this.account) }
    });

    dialogRef.afterClosed().subscribe(result => {
      this._workingWithNote = false;
      if (!result) {
        return;
      }

      this.translate.get('OVERVIEW.N_NOTE_SAVED').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
    });
  }
}
