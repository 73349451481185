export class ReportCategory {
  id!: string;
  name!: string;
  description!: string;
  type!: string;
  order!: number;

  constructor() {
    this.id = '';
    this.name = '';
    this.description = '';
    this.type = '';
    this.order = -1;
  }
}