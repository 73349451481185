<div class="content-container">
  <div class="content-centered">
    <h1 class="custom-h1" translate>ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.HEADING</h1>

    <a [routerLink]="['/organization-administration']" class="a-btn a-primary top-left-action-button" translate>
      <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>
      ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.BACK
    </a>

    <div class="visual-container">
      <section class="account-info">
        <h2>{{ account?.name }}</h2>
        <span *ngFor="let role of account?.roles" translate>{{ utilities.getRoleTextId(role) | translate }}</span>
      </section>

      <div *ngIf="utilities.canAccountEdit(account)" class="account-groups-container">
        <section *ngFor="let accountGroup of accountGroupsToDisplay" class="account-group">
          <h2>{{ 'ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.' + accountGroup.headingLabelId | translate }}</h2>
          <div *ngFor="let acc of accountGroup.accounts"
            class="account-card {{ acc.state === 'lockedReadWrite' ? 'account-card-locked-read-write' : '' }} {{ acc.state === 'lockedReadOnly' ? 'account-card-locked-read-only' : '' }}">
            <!-- Account info -->
            <dl>
              <dt translate>ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.NAME_LABEL</dt>
              <dd>{{ acc.name }}</dd>
              <dt translate>ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.EMAIL_LABEL</dt>
              <dd>{{ acc.email }}</dd>
              <ng-container *ngIf="acc.attachments.length > 0">
                <dt translate>ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.UPLOADED_ATTACHMENTS</dt>
                <dd>
                  <div class="file-list-container">
                    <button *ngFor="let attachment of acc.attachments" type="button" class="inline-button"
                      (click)="downloadAttachment(acc, attachment)">
                      <span>{{ attachment.name }}</span>
                      <mat-icon fontSet="material-icons-outlined">file_download</mat-icon>
                      <button type="button" class="inline-button"
                        (click)="$event.stopPropagation(); removeAttachment(acc, attachment);">
                        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                      </button>
                    </button>
                  </div>
                </dd>
              </ng-container>
            </dl>

            <!-- Categories -->
            <div
              *ngIf="organization.categories.length > 1 && (acc.roles.indexOf('cp') !== -1 || acc.roles.indexOf('oe') !== -1)"
              class="categories">
              <h3 translate>ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.CATEGORIES_HEADER</h3>
              <div class="chips-container">
                <div *ngFor="let category of acc.categories" class="chip-wrapper chip-wrapper-primary">
                  {{ utilities.shortText(category.name, 20) }}
                </div>
                <div *ngIf="acc.categories.length === 0" translate>
                  ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.CATEGORIES_EMPTY
                </div>
              </div>
            </div>

            <!-- OA notice -->
            <div *ngIf="acc.roles.indexOf('oa') !== -1" class="account-more-container-left">
              <span class="left-notice" translate>ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.HAS_OA_RIGHTS</span>
            </div>

            <!-- More info (settings + menu) -->
            <div class="account-more-container">
              <!-- acc.state === 'active' -->
              <ng-container *ngIf="acc.state === 'active'">
                <span class="right-notice" [translateParams]="{ date: utilities.dateFormat(acc.lockedReadWriteAt) }"
                  translate>
                  ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.ACCOUNT_ACTIVE</span>
                <button type="button" class="inline-button options-account-btn" aria-label="Open account menu"
                  [matMenuTriggerFor]="menu">
                  <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                  <mat-card class="mat-elevation-z more-container">
                    <button *ngIf="utilities.canAccountEdit(acc) && account?._id !== acc._id" type="button"
                      class="inline-button lock-account-btn" aria-label="Edit" (click)="editAccount(acc)">
                      <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                      <span translate>GENERAL.EDIT</span>
                    </button>
                    <input type="file" class="file-input" (change)="onFileSelected($event, acc)" #fileupload>
                    <button type="button" class="inline-button"
                      (click)="acc.attachments.length < 5 ? fileupload.click() : cannotUploadMore()" translate>
                      <mat-icon fontSet="material-icons-outlined">attach_file</mat-icon>
                      ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.UPLOAD_ATTACHMENT
                    </button>
                    <button *ngIf="acc._id !== account?._id" type="button" class="inline-button lock-account-btn"
                      aria-label="Lock account" (click)="lockAccountReadWrite(acc)">
                      <mat-icon fontSet="material-icons-outlined">lock</mat-icon>
                      <span translate>ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.LOCK_READ_WRITE</span>
                    </button>
                  </mat-card>
                </mat-menu>
              </ng-container>

              <!-- acc.state === 'lockedReadWrite' -->
              <ng-container *ngIf="acc.state === 'lockedReadWrite'">
                <span class="right-notice" [translateParams]="{ date: utilities.dateFormat(acc.lockedReadWriteAt) }"
                  translate>
                  ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.ACCOUNT_LOCKED_READ_WRITE</span>
                <button type="button" class="inline-button options-account-btn" aria-label="Open account menu"
                  [matMenuTriggerFor]="menu">
                  <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                  <mat-card class="mat-elevation-z more-container">
                    <button *ngIf="utilities.canAccountEdit(acc) && account?._id !== acc._id" type="button"
                      class="inline-button lock-account-btn" aria-label="Edit" (click)="editAccount(acc)">
                      <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                      <span translate>GENERAL.EDIT</span>
                    </button>
                    <input type="file" class="file-input" (change)="onFileSelected($event, acc)" #fileupload>
                    <button type="button" class="inline-button"
                      (click)="acc.attachments.length < 5 ? fileupload.click() : cannotUploadMore()" translate>
                      <mat-icon fontSet="material-icons-outlined">attach_file</mat-icon>
                      ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.UPLOAD_ATTACHMENT
                    </button>
                    <button *ngIf="acc._id !== account?._id" type="button" class="inline-button lock-account-btn"
                      aria-label="Lock account" (click)="lockAccountReadOnly(acc)">
                      <mat-icon fontSet="material-icons-outlined">block</mat-icon>
                      <span translate>ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.LOCK_READ_ONLY</span>
                    </button>
                  </mat-card>
                </mat-menu>
              </ng-container>

              <span *ngIf="!utilities.canAccountEdit(acc)" class="right-notice"
                [translateParams]="{ date: utilities.dateFormat(acc.lockedReadOnlyAt) }" translate>
                ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.ACCOUNT_LOCKED_READ_ONLY</span>
            </div>
          </div>

          <button type="button" class="button-primary button-confirm" (click)="addAccount(accountGroup.role)">
            {{ 'ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.ADD_' + accountGroup.addLabelId | translate }}<mat-icon
              fontSet="material-icons-outlined">person_add</mat-icon>
          </button>
        </section>
      </div>
    </div>
  </div>
</div>