import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { AppData } from 'src/app/singletons/app-data';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-inserted',
  templateUrl: './inserted.component.html',
  styleUrls: ['./inserted.component.css']
})
export class InsertedComponent implements OnInit {
  public identificationCode: string | null = null;

  public wbEmailPlaceholder = '';
  public wbEmail = '';

  public subscribed = false;

  constructor(
    private _appData: AppData,
    public app: AppComponent,
    private _translate: TranslateService,
    private _reportService: ReportService,
  ) { }

  ngOnInit(): void {
    this.identificationCode = this._appData.reportIdentificationCode;

    this._translate.get('AUTH.LOGIN.EMAIL_PLACEHOLDER').subscribe((res: string) => {
      this.wbEmailPlaceholder = res;
    }).unsubscribe();
  }

  public copyDone(): void {
    this._translate.get('GENERAL.N_COPIED').subscribe((res: string) => {
      this.app.buildNotification(res);
    }).unsubscribe();
  }

  public sendWbEmail(): void {
    if (!this.identificationCode) {
      return;
    }

    if (!this.wbEmail
      || !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(this.wbEmail)) {
      this._translate.get('AUTH.LOGIN.N_EMAIL_FORMAT').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    this.app.showLoading();
    this._reportService
      .subscribeForReportUpdate(this.identificationCode, this.wbEmail)
      .subscribe(() => {
        this.subscribed = true;
        this._translate.get('REPORT.INSERTED.N_SUBSCRIBED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
        this.app.hideLoading();
      }, () => {
        this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
        this.app.hideLoading();
      });
  }
}
