<ng-container
  *ngIf="accountDialogMode === AccountDialogMode.editSelf || accountDialogMode === AccountDialogMode.create">
  <!-- Email -->
  <label for="email" class="label-primary" translate>WIDGETS.ACCOUNT_DIALOG.EMAIL_LABEL</label>
  <div id="account-details-holder" class="element-hint-below">
    <input id="email" name="email" type="email" class="input-primary" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
      [placeholder]="emailPlaceholder" [(ngModel)]="account.email" required #email="ngModel" (input)="onFieldInput()" />
    <div class="hint-multi">
      <span *ngIf="accountDialogMode === AccountDialogMode.create" translate>WIDGETS.ACCOUNT_DIALOG.EMAIL_HINT</span>
      <span *ngIf="account.email.length > 50">{{ account.email.length }}/100</span>
    </div>
  </div>

  <!-- Name -->
  <label for="accountname" class="label-primary" translate>WIDGETS.ACCOUNT_DIALOG.NAME_LABEL</label>
  <div id="account-details-holder" class="element-hint-below">
    <input id="accountname" name="accountname" type="text" class="input-primary" [(ngModel)]="account.name" required
      #accountname="ngModel" (input)="onFieldInput()" />
    <div class="hint-multi">
      <span></span>
      <span *ngIf="account.name.length > 50">{{ account.name.length }}/100</span>
    </div>
  </div>
</ng-container>

<!-- Categories -->
<assign-categories *ngIf="organization.categories.length > 1 && canAssignCategories()" [organizationCategories]="organization.categories"
  [accountCategories]="account.categories" (categoriesChanged)="categoriesChanged()"></assign-categories>

<!-- Will be OA -->
<mat-checkbox *ngIf="canModifyWillBeOa()"
  id="will-be-oa" name="will-be-oa" type="checkbox" color="primary" [(ngModel)]="willBeOa"
  (ngModelChange)="onFieldInput()">
  {{ 'WIDGETS.ACCOUNT_DIALOG.WILL_BE_OA' | translate }}
</mat-checkbox>

<!-- Notify about checkboxes -->
<ng-container *ngIf="accountDialogMode === AccountDialogMode.editSelf && isNotOaOnly()">
  <mat-checkbox id="notify-about-new-reports" name="notify-about-new-reports" type="checkbox" color="primary"
    [(ngModel)]="account.notifyAboutNewReports" (ngModelChange)="onFieldInput()">
    {{ 'WIDGETS.ACCOUNT_DIALOG.NOTIFY_ABOUT_NEW_REPORTS' | translate }}
  </mat-checkbox>
  <mat-checkbox id="notify-about-report-state-changes" name="notify-about-report-state-changes" type="checkbox"
    color="primary" [(ngModel)]="account.notifyAboutReportStateChanges" (ngModelChange)="onFieldInput()">
    {{ 'WIDGETS.ACCOUNT_DIALOG.NOTIFY_ABOUT_REPORT_STATE_CHANGES' | translate }}
  </mat-checkbox>
  <mat-checkbox id="notify-about-deadlines" name="notify-about-deadlines" type="checkbox" color="primary"
    [(ngModel)]="account.notifyAboutDeadlines" (ngModelChange)="onFieldInput()">
    {{ 'WIDGETS.ACCOUNT_DIALOG.NOTIFY_ABOUT_DEADLINES' | translate }}
  </mat-checkbox>
  <mat-checkbox *ngIf="account.roles.indexOf('cp') !== -1" id="notify-about-new-messages"
    name="notify-about-new-messages" type="checkbox" color="primary" [(ngModel)]="account.notifyAboutNewMessages"
    (ngModelChange)="onFieldInput()">
    {{ 'WIDGETS.ACCOUNT_DIALOG.NOTIFY_ABOUT_NEW_MESSAGES' | translate }}
  </mat-checkbox>
</ng-container>

<!-- Dialog actions -->
<div class="dialog-actions">
  <button *ngIf="inputChanged" [disabled]="saveDisabled()" type="button" class="button-primary button-confirm"
    (click)="save()" translate>GENERAL.SAVE</button>
  <button *ngIf="inputChanged" type="button" class="button-primary" (click)="dialogRef.close(false)"
    translate>GENERAL.CANCEL</button>
  <button *ngIf="!inputChanged" type="button" class="button-primary" (click)="dialogRef.close(false)"
    translate>GENERAL.BACK</button>
</div>