import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { ConfirmDialogComponent } from 'src/app/components/widgets/confirm-dialog/confirm-dialog.component';
import { Report } from 'src/app/models/report';
import { ReportEvaluationState } from 'src/app/models/report-state';
import { ReportStateService } from 'src/app/services/report-state.service';
import { AppData } from 'src/app/singletons/app-data';
import { Constants } from 'src/app/utilities/constants';
import { ReportStateHelper } from 'src/app/utilities/report-state-helper';
import { Utilities } from 'src/app/utilities/utilities';

enum ComponentState {
  NotifyNowAboutExtending, ActionChoice, ExtendDeadline
}

@Component({
  selector: 'app-report-evaluation-state',
  templateUrl: './report-evaluation-state.component.html',
  styleUrls: ['./report-evaluation-state.component.css']
})
export class ReportEvaluationStateComponent implements OnInit, OnDestroy {

  public componentStateEnum = ComponentState;
  public reportStateHelper = ReportStateHelper;

  public report!: Report;

  private _activeReportStateSubscription$!: Subscription;
  public state!: ReportEvaluationState;

  public componentState = ComponentState.ActionChoice;

  public stateFormData = new FormData();

  public arrayFromZeroToTwentynine: number[];

  // correctly set in onInit below
  public extendDeadlineCountRemaining = 2;

  private _stateApi = '';

  constructor(
    private _appData: AppData,
    private _reportStateService: ReportStateService,
    public dialog: MatDialog,
    public app: AppComponent,
    public translate: TranslateService,
  ) {
    // 0-29
    this.arrayFromZeroToTwentynine = Array.from({ length: 30 }, (e, i) => i);
  }

  ngOnInit(): void {
    if (!this._appData.activeReportValue) {
      return;
    }

    this.report = this._appData.activeReportValue;
    this._activeReportStateSubscription$ = this._appData.activeReportState.subscribe((state) => {
      if (!state) {
        return;
      }

      this.state = state as ReportEvaluationState;
      this._stateApi = Constants.stateApiMap.get(this.state.id) ?? '';

      this.extendDeadlineCountRemaining = this.state.extendedTwiceDate ? 0 : (this.state.extendedOnceDate ? 1 : 2);

      // if address filled and not already notified (either extendOnce or extendTwice)
      if ((this.state.extendedOnceDate
        && !this.state.notifiedAboutExtendingOnce.dateNotified)
        || (this.state.extendedTwiceDate
          && !this.state.notifiedAboutExtendingTwice.dateNotified)) {
        this.componentState = ComponentState.NotifyNowAboutExtending;
      }
    });

    // extend once text
    if (!this.state.notifiedAboutExtendingOnce.notifyNowTextHtml) {
      this.translate.get('OVERVIEW.DETAIL.REPORT_EVALUATION.T_EXTEND_ONCE_TEXT_HTML',
        {
          date: Utilities.dateFormat(this.report.detail.reportedAt),
          extendDays: this.state.extendedOnceDaysCount,
          cpName: this._appData.accountValue?.name ?? '',
          orgName: this._appData.organizationValue.name
        }).subscribe((res: string) => {
          this.state.notifiedAboutExtendingOnce.notifyNowTextHtml = res;
        }).unsubscribe();
    }

    // extend twice text
    if (!this.state.notifiedAboutExtendingTwice.notifyNowTextHtml) {
      this.translate.get('OVERVIEW.DETAIL.REPORT_EVALUATION.T_EXTEND_TWICE_TEXT_HTML',
        {
          date: Utilities.dateFormat(this.report.detail.reportedAt),
          extendDays: this.state.extendedOnceDaysCount,
          cpName: this._appData.accountValue?.name ?? '',
          orgName: this._appData.organizationValue.name
        }).subscribe((res: string) => {
          this.state.notifiedAboutExtendingTwice.notifyNowTextHtml = res;
        }).unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this._activeReportStateSubscription$.unsubscribe();
  }

  public goBack(): void {
    if (this.componentState === ComponentState.ActionChoice
      || this.componentState === ComponentState.NotifyNowAboutExtending) {
      this._appData.setActiveReportState(null);
      return;
    }

    this.componentState = ComponentState.ActionChoice;
  }

  // *************** ACTION CHOICE

  public reportIsJust(): void {
    this.translate.get('OVERVIEW.DETAIL.REPORT_EVALUATION.DIALOG_IS_JUST_TITLE').subscribe((dialogTitle: string) => {
      this.translate.get('GENERAL.CANNOT_BE_UNDONE').subscribe((dialogBody: string) => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '40rem',
          panelClass: 'dialog-container',
          data: {
            title: dialogTitle,
            body: dialogBody,
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (!result) {
            return;
          }

          this.stateFormData = new FormData();
          this.stateFormData.set('state', JSON.stringify(this.state));
          this.stateFormData.set('isJust', JSON.stringify(true));

          this.app.showLoading();
          this._reportStateService.send(`${this._stateApi}/just`, this.report._id, this.report.lastUpdated, this.stateFormData).subscribe(res => {
            if (res) {
              // replace old report with the new one
              this.report = res.report;
              this._appData.setActiveReport(res.report);
              this._appData.replaceSingleReport(res.report);
              this._appData.setNewMessage(res.message);
              // set active report to null (=> redirect to states overview)
              this._appData.setActiveReportState(null);
              this.translate.get('OVERVIEW.DETAIL.REPORT_EVALUATION.N_REPORT_IS_JUST').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            }
            this.app.hideLoading();
          }, (errorStatus) => {
            if (errorStatus === 409) {
              this.translate.get('GENERAL.N_DATA_CHANGED').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            } else {
              this.translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            }
            this.app.hideLoading();
          });
        });
      });
    });
  }

  public reportIsNotJust(): void {
    this.translate.get('OVERVIEW.DETAIL.REPORT_EVALUATION.DIALOG_IS_NOT_JUST_TITLE').subscribe((dialogTitle: string) => {
      this.translate.get('GENERAL.CANNOT_BE_UNDONE').subscribe((dialogBody: string) => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '40rem',
          panelClass: 'dialog-container',
          data: {
            title: dialogTitle,
            body: dialogBody,
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (!result) {
            return;
          }

          this.stateFormData = new FormData();
          this.stateFormData.set('state', JSON.stringify(this.state));
          this.stateFormData.set('isJust', JSON.stringify(false));

          this.app.showLoading();
          this._reportStateService.send(`${this._stateApi}/just`, this.report._id, this.report.lastUpdated, this.stateFormData).subscribe(res => {
            if (res) {
              // replace old report with the new one
              this.report = res.report;
              this._appData.setActiveReport(res.report);
              this._appData.replaceSingleReport(res.report);
              this._appData.setNewMessage(res.message);
              // set active report to null (=> redirect to states overview)
              this._appData.setActiveReportState(null);
              this.translate.get('OVERVIEW.DETAIL.REPORT_EVALUATION.N_REPORT_IS_NOT_JUST').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            }
            this.app.hideLoading();
          }, (errorStatus) => {
            if (errorStatus === 409) {
              this.translate.get('GENERAL.N_DATA_CHANGED').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            } else {
              this.translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            }
            this.app.hideLoading();
          });
        });
      });
    });
  }

  // *************** EXTEND DEADLINE

  public goExtendDeadline(): void {
    if (this.extendDeadlineCountRemaining === 0) {
      this.translate.get('OVERVIEW.DETAIL.REPORT_EVALUATION.N_DEADLINE_ALREADY_EXTENDED_TWICE').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    this.componentState = ComponentState.ExtendDeadline;
  }

  public confirmExtendedDaysCount(): void {
    if (this.extendDeadlineCountRemaining === 2 && !this.state.extendedOnceDaysCount) {
      this.translate.get('OVERVIEW.DETAIL.REPORT_EVALUATION.N_FILL_EXTEND_DAYS').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    if (this.extendDeadlineCountRemaining === 1 && !this.state.extendedTwiceDaysCount) {
      this.translate.get('OVERVIEW.DETAIL.REPORT_EVALUATION.N_FILL_EXTEND_DAYS').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    const extendDaysCount = this.extendDeadlineCountRemaining === 2
      ? this.state.extendedOnceDaysCount
      : this.state.extendedTwiceDaysCount;

    this.translate.get('OVERVIEW.DETAIL.REPORT_EVALUATION.DIALOG_REALLY_EXTEND_TITLE', { extendDays: extendDaysCount }).subscribe((dialogTitle: string) => {
      this.translate.get('GENERAL.CANNOT_BE_UNDONE').subscribe((dialogBody: string) => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '40rem',
          panelClass: 'dialog-container',
          data: {
            title: dialogTitle,
            body: dialogBody,
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (!result) {
            return;
          }

          this.stateFormData = new FormData();
          this.stateFormData.set('state', JSON.stringify(this.state));

          this.app.showLoading();
          this._reportStateService.send(`${this._stateApi}/extend_deadline`, this.report._id, this.report.lastUpdated, this.stateFormData).subscribe(res => {
            if (res) {
              // replace old report with the new one
              this.report = res.report;
              this._appData.setActiveReport(res.report);
              this._appData.replaceSingleReport(res.report);
              this._appData.setNewMessage(res.message);

              this.state = this.report.states.find(state => state.id === this.state.id) as ReportEvaluationState;
              this._appData.setActiveReportState(this.state);

              this.extendDeadlineCountRemaining--;

              this.componentState = ComponentState.NotifyNowAboutExtending;

              this.translate.get('OVERVIEW.DETAIL.REPORT_EVALUATION.N_DEADLINE_EXTENDED').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            }
            this.app.hideLoading();
          }, (errorStatus) => {
            if (errorStatus === 409) {
              this.translate.get('GENERAL.N_DATA_CHANGED').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            } else {
              this.translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            }
            this.app.hideLoading();
          });
        });
      });
    });
  }

  // *************** NOTIFY ABOUT EXTENDING BOTH

  private _extendNotifyNow(specifier: string): void {
    this.stateFormData = new FormData();
    this.stateFormData.set('state', JSON.stringify(this.state));

    this.app.showLoading();
    this._reportStateService.send(`${this._stateApi}/${specifier}_notify_now`, this.report._id, this.report.lastUpdated, this.stateFormData).subscribe(res => {
      if (res) {
        // replace old report with the new one
        this.report = res.report;
        this._appData.setActiveReport(res.report);
        this._appData.replaceSingleReport(res.report);
        this._appData.setNewMessage(res.message);

        this.state = this.report.states.find(state => state.id === this.state.id) as ReportEvaluationState;
        this._appData.setActiveReportState(this.state);
        // go to action choice
        this.componentState = ComponentState.ActionChoice;
        this.translate.get('OVERVIEW.DETAIL.REPORT_EVALUATION.N_NOTIFIED_ABOUT_EXTENDING').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      }
      this.app.hideLoading();
    }, (errorStatus) => {
      if (errorStatus === 409) {
        this.translate.get('GENERAL.N_DATA_CHANGED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      } else if (errorStatus < 500) {
        this.translate.get('GENERAL.N_BAD_REQUEST_OR_UNAUTHORIZED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      } else {
        this.translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      }
      this.app.hideLoading();
    });
  }

  // *************** NOTIFY ABOUT EXTENDING ONCE

  public goToNextStateNotifyNowExtendOnce(): void {
    if (!this.state.notifiedAboutExtendingOnce.notifyNowTextHtml || this.state.notifiedAboutExtendingOnce.notifyNowTextHtml.trim().length === 0) {
      this.translate.get('OVERVIEW.DETAIL.N_FILL_TEXT_FOR_WB').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }
    this._extendNotifyNow('extend_once');
  }

  // *************** NOTIFY ABOUT EXTENDING TWICE

  public goToNextStateNotifyNowExtendTwice(): void {
    if (!this.state.notifiedAboutExtendingTwice.notifyNowTextHtml || this.state.notifiedAboutExtendingTwice.notifyNowTextHtml.trim().length === 0) {
      this.translate.get('OVERVIEW.DETAIL.N_FILL_TEXT_FOR_WB').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }
    this._extendNotifyNow('extend_twice');
  }
}