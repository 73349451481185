<ol class="stepper">
  <li class="step step-active step-no-action">
    <div class="step-number">{{ state.number }}</div>
    <div class="step-label">{{ 'OVERVIEW.DETAIL.STATE_LABELS.' + reportStateHelper.getStateLabelTranslateId(state, true) | translate:{ tillDays:
      reportStateHelper.getStateTillDays(report) } }}
    </div>
  </li>
</ol>

<button type="button" class="inline-button go-back-in-states-button" (click)="goBack()" translate>
  <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>GENERAL.STEP_BACK
</button>

<!-- Execute -->
<section *ngIf="componentState === componentStateEnum.Execute" class="shrinked shrinked-more-width">
  <section id="text-from-oe-container">
    <h3 class="custom-h3" translate>OVERVIEW.DETAIL.EXECUTION.EXECUTE_HEADING</h3>
    <button type="button" class="button-primary button-confirm" (click)="executionEnded()" translate>
      OVERVIEW.DETAIL.EXECUTION.EXECUTION_ENDED
    </button>
  </section>
</section>

<!-- Describe execution -->
<section *ngIf="componentState === componentStateEnum.DescribeExecution" class="shrinked shrinked-more-width">
  <label class="label-primary" translate>OVERVIEW.DETAIL.EXECUTION.DESCRIBE_LABEL</label>
  <div class="element-hint-below">
    <quill-editor class="editor" [(ngModel)]="state.executionTextHtml" placeholder="" [styles]="{'min-height': '25rem'}"
      [maxLength]="100000" [required]="true" [sanitize]="true" (onContentChanged)="executionTextChanged($event)">
    </quill-editor>
    <div class="hint-multi">
      <span></span>
      <span *ngIf="executionTextLength > 85000">{{ executionTextLength }} / 100 000</span>
    </div>
  </div>

  <button type="button" class="button-primary button-confirm" (click)="confirmDescription()" translate>
    OVERVIEW.DETAIL.EXECUTION.DESCRIBE_CONFIRM
  </button>
</section>