import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppData } from '../singletons/app-data';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private _appData: AppData,
    private _router: Router,
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401 && this._appData.accountValue) {
        this._router.navigate(['/login'], { replaceUrl: true });
        this._appData.autoLogout();
      }

      return throwError(err.status);
    }))
  }
}