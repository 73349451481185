<ol class="stepper">
  <li class="step step-active step-no-action">
    <div class="step-number">{{ state.number }}</div>
    <div class="step-label">{{ 'OVERVIEW.DETAIL.STATE_LABELS.' + reportStateHelper.getStateLabelTranslateId(state, true)
      | translate:{ tillDays: reportStateHelper.getStateTillDays(report) } }}
    </div>
  </li>
</ol>

<button type="button" class="inline-button go-back-in-states-button" (click)="goBack()" translate>
  <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>GENERAL.STEP_BACK
</button>

<section class="shrinked">
  <section>
    <button type="button" class="button-primary button-confirm" (click)="understood()">
      {{ 'OVERVIEW.DETAIL.GT_NOTICE_REPORT.NOTICED_' + (activeRole ? activeRole : 'cp') | uppercase | translate }}
    </button>
  </section>
</section>