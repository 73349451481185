<div class="content-container">
  <div class="content-centered">
    <h1 class="custom-h1" translate>AUTH.PREREGISTER.HEADING</h1>
    <div class="visual-container">
      <form>
        <label for="password" class="label-primary" translate>AUTH.PREREGISTER.PASSWORD_LABEL</label>
        <div class="element-hint-below">
          <input id="password" name="password" type="password" class="input-primary" [(ngModel)]="password" required
            #passwordmodel="ngModel" pattern="(?=.*\d)(?=.*[a-z,A-Z]).{8,}">
          <div class="hint-multi">
            <span translate>AUTH.PREREGISTER.PASSWORD_HINT</span>
            <span *ngIf="password.length < 8">{{ password.length }}/8</span>
            <span *ngIf="password.length >= 8">{{ password.length }}</span>
          </div>
        </div>
        <label for="password-again" class="label-primary" translate>AUTH.PREREGISTER.PASSWORD_AGAIN_LABEL</label>
        <div class="element-hint-below">
          <input id="password-again" name="password-again" type="password" class="input-primary"
            [(ngModel)]="passwordAgain" required #passwordagain="ngModel" pattern="(?=.*\d)(?=.*[a-z,A-Z]).{8,}">
          <div class="hint-multi">
            <span translate>AUTH.PREREGISTER.PASSWORD_AGAIN_HINT</span>
            <span *ngIf="passwordAgain.length < 8">{{ passwordAgain.length }}/8</span>
            <span *ngIf="passwordAgain.length >= 8">{{ passwordAgain.length }}</span>
          </div>
        </div>
        <label for="name" class="label-primary" translate>AUTH.PREREGISTER.NAME_LABEL</label>
        <div class="element-hint-below">
          <input id="name" name="name" type="text" class="input-primary" [(ngModel)]="name" required
            #namemodel="ngModel">
          <div class="hint-multi">
            <span translate>AUTH.PREREGISTER.NAME_HINT</span>
            <span *ngIf="name.length > 50">{{ name.length }}/100</span>
          </div>
        </div>
        <button type="submit" class="button-primary button-confirm" (click)="register()"
          translate>AUTH.PREREGISTER.CONFIRM</button>
      </form>
    </div>
  </div>
</div>