<div class="content-container">
  <div class="content-centered">
    <h1 class="custom-h1" translate>AUTH.CHANGE_PASSWORD.HEADING</h1>

    <a [routerLink]="['/account']" class="a-btn a-primary top-left-action-button" translate>
      <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>AUTH.CHANGE_PASSWORD.BACK_TO_ACCOUNT
    </a>

    <div class="visual-container">
      <form>
        <label for="old-password" class="label-primary" translate>AUTH.CHANGE_PASSWORD.OLD_PASSWORD_LABEL</label>
        <div class="element-hint-below">
          <input id="old-password" name="old-password" type="password" class="input-primary" [(ngModel)]="oldPassword"
            required #oldpassword="ngModel" pattern="(?=.*\d)(?=.*[a-z,A-Z]).{8,}">
          <div class="hint-multi">
            <span translate>AUTH.CHANGE_PASSWORD.OLD_PASSWORD_HINT</span>
            <span *ngIf="oldPassword.length < 8">{{ oldPassword.length }}/8</span>
            <span *ngIf="oldPassword.length >= 8">{{ oldPassword.length }}</span>
          </div>
        </div>
        <label for="new-password" class="label-primary" translate>AUTH.CHANGE_PASSWORD.NEW_PASSWORD_LABEL</label>
        <div class="element-hint-below">
          <input id="new-password" name="new-password" type="password" class="input-primary" [(ngModel)]="newPassword"
            required #newpassword="ngModel" pattern="(?=.*\d)(?=.*[a-z,A-Z]).{8,}">
          <div class="hint-multi">
            <span translate>AUTH.CHANGE_PASSWORD.NEW_PASSWORD_HINT</span>
            <span *ngIf="newPassword.length < 8">{{ newPassword.length }}/8</span>
            <span *ngIf="newPassword.length >= 8">{{ newPassword.length }}</span>
          </div>
        </div>
        <label for="new-password-again" class="label-primary"
          translate>AUTH.CHANGE_PASSWORD.NEW_PASSWORD_AGAIN_LABEL</label>
        <div class="element-hint-below">
          <input id="new-password-again" name="new-password-again" type="password" class="input-primary"
            [(ngModel)]="newPasswordAgain" required #newpasswordagain="ngModel" pattern="(?=.*\d)(?=.*[a-z,A-Z]).{8,}">
          <div class="hint-multi">
            <span translate>AUTH.CHANGE_PASSWORD.NEW_PASSWORD_AGAIN_HINT</span>
            <span *ngIf="newPasswordAgain.length < 8">{{ newPasswordAgain.length }}/8</span>
            <span *ngIf="newPasswordAgain.length >= 8">{{ newPasswordAgain.length }}</span>
          </div>
        </div>
        <button type="submit" class="button-primary button-confirm" (click)="changePassword()" translate>
          AUTH.CHANGE_PASSWORD.CONFIRM<mat-icon fontSet="material-icons-outlined">lock_open</mat-icon></button>
      </form>
    </div>
  </div>
</div>