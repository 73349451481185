import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { OkDialogComponent } from './components/widgets/ok-dialog/ok-dialog.component';
import { Account } from './models/account';
import { Organization } from './models/organization';
import { AuthenticationService } from './services/authentication.service';
import { AppData } from './singletons/app-data';
import { Constants } from './utilities/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let buildNotification: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  private _organizationSubscription$!: Subscription;
  public organization!: Organization | null;

  private _accountSubscription$!: Subscription;
  public account: Account | null | undefined;

  private _activeRoleSubscription$!: Subscription;
  public activeRole: string | null | undefined;

  public loadingHidden = true;

  private _extendTokenInterval: number | undefined;

  public currentLang: string | undefined;

  constructor(
    private _router: Router,
    private _appData: AppData,
    private _translate: TranslateService,
    public dialog: MatDialog,
    private _authenticationService: AuthenticationService
  ) {
    //translation init
    _translate.addLangs(['cz', 'en']);
    this.prepareLang();
  }

  prepareLang(lang?: string, source?: string): void {
    // source = 'browser' < 'org' < 'acc' < 'man'
    // manual has the highest priority
    const langFromLocalStorage = localStorage.getItem('language');
    const langSourceFromLocalStorage = localStorage.getItem('lang-source');

    //the lang to use, if the lang isn't available, it will use the current loader to get them
    if (!lang) {
      if (!langFromLocalStorage) {
        const browserLang = this._translate.getBrowserLang();
        lang = browserLang?.match(/en|cz/) ? browserLang : 'cz';
        source = 'browser';
      } else {
        lang = langFromLocalStorage;
      }
    } else if (lang && source === 'man') {
      // go next
    } else if (lang && !langFromLocalStorage) {
      // go next
    } else if (
      langFromLocalStorage &&
      (langSourceFromLocalStorage === 'acc' ||
        langSourceFromLocalStorage === 'man')
    ) {
      //prefer choice made by user
      lang = langFromLocalStorage;
    }

    this._translate.use(lang);
    this.currentLang = lang;
    if (
      !langFromLocalStorage ||
      source === 'man' ||
      (langSourceFromLocalStorage === 'browser' && source === 'acc') ||
      (langSourceFromLocalStorage === 'browser' && source === 'org') ||
      (langSourceFromLocalStorage === 'org' && source === 'acc')
    ) {
      localStorage.setItem('language', lang);
      if (source) {
        localStorage.setItem('lang-source', source);
      }
    }
  }

  changeLang(lang: string): void {
    this.prepareLang(lang, 'man');
  }

  ngOnInit(): void {
    this._organizationSubscription$ = this._appData.organization.subscribe(
      (organization) => {
        this.organization = organization;
        this.prepareLang(this.organization.language, 'org');
      }
    );
    this._accountSubscription$ = this._appData.account.subscribe((account) => {
      this.account = account;
      if (this.account) {
        this.prepareLang(this.account.language, 'acc');
      }
    });
    this._activeRoleSubscription$ = this._appData.activeRole.subscribe(
      (activeRole) => {
        this.activeRole = activeRole;
      }
    );

    this.loadingHidden = true;

    if (!this._extendTokenInterval) {
      this._extendTokenInterval = window.setInterval(() => {
        if (this.account && this.organization) {
          this._authenticationService
            .extendToken(this.organization)
            .subscribe();
        }
      }, Constants.extendTokenIntervalInSeconds * 1000);
    }
  }

  ngOnDestroy(): void {
    this._organizationSubscription$.unsubscribe();
    this._accountSubscription$.unsubscribe();
    this._activeRoleSubscription$.unsubscribe();
  }

  ngAfterContentInit(): void {
    this.loadingHidden = true;
  }

  public isOrgNameVisible(): boolean {
    const urlSplitted = this._router.url.split('/');
    return (
      (urlSplitted &&
        urlSplitted.length === 2 &&
        (urlSplitted[1] === 'login' ||
          urlSplitted[1].startsWith('report') ||
          urlSplitted[1] === 'account' ||
          urlSplitted[1] === 'organization-administration')) ||
      urlSplitted.length > 2
    );
  }

  public getHomeRedirectPath(): string {
    if (this.account && this.activeRole && this.activeRole === 'oa') {
      return '/organization-administration';
    }
    if (this.account) {
      return '/overview/reports';
    }
    return '/';
    // const urlSplitted = this._router.url.split('/');
    // if (!urlSplitted || urlSplitted.length === 0
    //   || (urlSplitted.length >= 2
    //     && (urlSplitted[1] === '' || urlSplitted[1] === 'login' || urlSplitted[1] === 'report' || urlSplitted[1] === 'inserted' || urlSplitted[1].length === 16))
    //   || urlSplitted.length >= 3 && urlSplitted[1] === 'reset-password'
    //   || urlSplitted.length >= 3 && urlSplitted[1] === 'preregister') {
    //   return '/';
    // }
    // if (urlSplitted.length >= 2 && urlSplitted[1] === 'organization-administration') {
    //   return '/organization-administration';
    // }
    // return '/overview/reports';
  }

  public showHelp(): void {
    this._translate
      .get('APP_PAGE.HELP_TEXT_HEADING')
      .subscribe((dialogTitle: string) => {
        this._translate
          .get('APP_PAGE.HELP_TEXT')
          .subscribe((dialogBody: string) => {
            this.dialog.open(OkDialogComponent, {
              width: '40rem',
              height: '80%',
              panelClass: 'dialog-container',
              data: {
                title: dialogTitle,
                body: dialogBody,
              },
            });
          });
      });
  }

  public showDemoInfo(): void {
    this._translate
      .get('GENERAL.DEMO_INFO_MORE_TITLE')
      .subscribe((dialogTitle: string) => {
        this._translate
          .get('GENERAL.DEMO_INFO_MORE_BODY')
          .subscribe((dialogBody: string) => {
            this.dialog.open(OkDialogComponent, {
              width: '40rem',
              maxHeight: '80vh',
              panelClass: 'dialog-container',
              data: {
                title: dialogTitle,
                body: dialogBody,
              },
            });
          });
      });
  }

  /**
   * Builds Notifs notification with given content.
   * Can be closed with clicking the canvas or close button.
   * @param content Text to be displayed.
   * @param lifespan Type of lifespan, default: 9 => is closed after 5 s and can be closed; 8 => has to be closed by user, ... (see notifs.js what each value means).
   */
  public buildNotification(content: string, lifespan = 9): void {
    new buildNotification(content, 0, 0, 2, 0, lifespan);
  }

  public hideLoading(): void {
    this.loadingHidden = true;
  }

  public showLoading(): void {
    this.loadingHidden = false;
  }
}
