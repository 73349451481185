<ol class="stepper">
  <li class="step step-active step-no-action">
    <div class="step-number">{{ state.number }}</div>
    <div class="step-label">{{ 'OVERVIEW.DETAIL.STATE_LABELS.' + reportStateHelper.getStateLabelTranslateId(state, true) | translate:{ tillDays:
      reportStateHelper.getStateTillDays(report) } }}
    </div>
  </li>
</ol>

<button type="button" class="inline-button go-back-in-states-button" (click)="goBack()" translate>
  <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>GENERAL.STEP_BACK
</button>

<section class="shrinked shrinked-more-width">
  <label class="label-primary" translate>OVERVIEW.DETAIL.JUSTIFY_NOT_JUST.JUSTIFY_LABEL</label>
  <div class="element-hint-below">
    <quill-editor class="editor" [(ngModel)]="state.justifyReportNotJustTextHtml" placeholder=""
      [styles]="{'min-height': '25rem'}" [maxLength]="100000" [required]="true" [sanitize]="true"
      (onContentChanged)="textChanged($event)"></quill-editor>
    <div class="hint-multi">
      <span></span>
      <span *ngIf="justifyReportNotJustTextLength > 85000">{{ justifyReportNotJustTextLength }} / 100 000</span>
    </div>
  </div>

  <button type="button" class="button-primary button-confirm" (click)="confirm()" translate>
    OVERVIEW.DETAIL.JUSTIFY_NOT_JUST.CONFIRM
  </button>
</section>