import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { Organization } from 'src/app/models/organization';
import { OrganizationService } from 'src/app/services/organization.service';
import { AppData } from 'src/app/singletons/app-data';

@Component({
  selector: 'app-app-page',
  templateUrl: './app-page.component.html',
  styleUrls: ['./app-page.component.css']
})
export class AppPageComponent implements OnInit, OnDestroy {

  public organization!: Organization;
  private _organizationSubscription$!: Subscription;

  constructor(
    private _appData: AppData,
    private _organizationService: OrganizationService,
    private _router: Router,
    public app: AppComponent,
  ) { }

  ngOnInit(): void {
    this._organizationSubscription$ = this._appData.organization.subscribe(organization => {
      this.organization = organization;
    });

    let orgIdentificationCode = '';
    const urlSplitted = this._router.url.split('/');

    // firstly, try to grab identificationCode from url
    if (urlSplitted.length === 2 && urlSplitted[urlSplitted.length - 1].length === 16) {
      // code from url should have 16 characters
      orgIdentificationCode = urlSplitted[urlSplitted.length - 1];
    }

    // if not successful, try to obtain it from the localStorage
    if (orgIdentificationCode.length === 0) {
      orgIdentificationCode = localStorage.getItem('identificationCode') ?? '';
    }

    // organization can be in the memory, do not request server for it in that case
    if (this._appData.organizationValue.identificationCode.length !== 0
      && this._appData.organizationValue.identificationCode === orgIdentificationCode) {
      // organization is already known
      // this.organization = this._appData.organizationValue;
      return;
    }

    // if anything fails, there is no identification code available
    if (!orgIdentificationCode || orgIdentificationCode.length === 0) {
      return;
    }

    // request server for the organization (do not show loading)
    this._organizationService
      .get(orgIdentificationCode)
      .subscribe((res) => {
        if (res) {
          this._appData.setOrganization(new Organization(res._id, res.name, res.language, orgIdentificationCode, res.state, res.categories));
          localStorage.setItem('identificationCode', orgIdentificationCode);
        }
      });
  }

  ngOnDestroy(): void {
    this._organizationSubscription$.unsubscribe();
  }

  public expandHelp(): void {
    this.app.showHelp();
  }

}
