import { ComponentPortal } from '@angular/cdk/portal';
import { ExecutionStateComponent } from '../components/overview/detail/execution-state/execution-state.component';
import { GtNoticeReportStateComponent } from '../components/overview/detail/gt-notice-report-state/gt-notice-report-state.component';
import { GtNotifyObligedEntityStateComponent } from '../components/overview/detail/gt-notify-obliged-entity-state/gt-notify-obliged-entity-state.component';
import { GtObligedEntityUnderstoodStateComponent } from '../components/overview/detail/gt-obliged-entity-understood-state/gt-obliged-entity-understood-state.component';
import { JustifyReportNotJustStateComponent } from '../components/overview/detail/justify-report-not-just-state/justify-report-not-just-state.component';
import { NotifyCompetentPersonAboutAcceptedMeasuresStateComponent } from '../components/overview/detail/notify-competent-person-about-accepted-measures-state/notify-competent-person-about-accepted-measures-state.component';
import { NotifyCompetentPersonAboutExecutionStateComponent } from '../components/overview/detail/notify-competent-person-about-execution-state/notify-competent-person-about-execution-state.component';
import { NotifyWhistleblowerAboutAcceptedMeasuresStateComponent } from '../components/overview/detail/notify-whistleblower-about-accepted-measures-state/notify-whistleblower-about-accepted-measures-state.component';
import { NotifyWhistleblowerAboutExecutionStateComponent } from '../components/overview/detail/notify-whistleblower-about-execution-state/notify-whistleblower-about-execution-state.component';
import { NotifyWhistleblowerAboutReportEvaluationStateComponent } from '../components/overview/detail/notify-whistleblower-about-report-evaluation-state/notify-whistleblower-about-report-evaluation-state.component';
import { NotifyWhistleblowerAboutReportNoticingStateComponent } from "../components/overview/detail/notify-whistleblower-about-report-noticing-state/notify-whistleblower-about-report-noticing-state.component";
import { ObligedEntityUnderstoodReportStateComponent } from '../components/overview/detail/obliged-entity-understood-report-state/obliged-entity-understood-report-state.component';
import { ReportEvaluationStateComponent } from '../components/overview/detail/report-evaluation-state/report-evaluation-state.component';
import { StatesOverviewComponent } from "../components/overview/detail/states-overview/states-overview.component";
import { SuggestionByObligedEntityStateComponent } from '../components/overview/detail/suggestion-by-obliged-entity-state/suggestion-by-obliged-entity-state.component';
import { SuggestionForObligedEntityStateComponent } from '../components/overview/detail/suggestion-for-obliged-entity-state/suggestion-for-obliged-entity-state.component';
import { UnderstoodAcceptedMeasuresStateComponent } from '../components/overview/detail/understood-accepted-measures-state/understood-accepted-measures-state.component';
import { UnderstoodExecutionStateComponent } from '../components/overview/detail/understood-execution-state/understood-execution-state.component';

export class ReportStateComponentPortalGenerator {
  static generate(reportStateId: string): ComponentPortal<
    StatesOverviewComponent
    | NotifyWhistleblowerAboutReportNoticingStateComponent
    | ReportEvaluationStateComponent
    | JustifyReportNotJustStateComponent
    | NotifyWhistleblowerAboutReportEvaluationStateComponent
    | SuggestionForObligedEntityStateComponent
    | UnderstoodAcceptedMeasuresStateComponent
    | NotifyWhistleblowerAboutAcceptedMeasuresStateComponent
    | UnderstoodExecutionStateComponent
    | NotifyWhistleblowerAboutExecutionStateComponent
    | ObligedEntityUnderstoodReportStateComponent
    | SuggestionByObligedEntityStateComponent
    | NotifyCompetentPersonAboutAcceptedMeasuresStateComponent
    | ExecutionStateComponent
    | NotifyCompetentPersonAboutExecutionStateComponent
    | GtNoticeReportStateComponent
    | GtNotifyObligedEntityStateComponent
    | GtObligedEntityUnderstoodStateComponent> {
    switch (reportStateId) {
      case 'NotifyWhistleblowerAboutReportNoticingState':
        return new ComponentPortal(NotifyWhistleblowerAboutReportNoticingStateComponent);
      case 'ReportEvaluationState':
        return new ComponentPortal(ReportEvaluationStateComponent);
      case 'JustifyReportNotJustState':
        return new ComponentPortal(JustifyReportNotJustStateComponent);
      case 'NotifyWhistleblowerAboutReportEvaluationState':
        return new ComponentPortal(NotifyWhistleblowerAboutReportEvaluationStateComponent);
      case 'SuggestionForObligedEntityState':
        return new ComponentPortal(SuggestionForObligedEntityStateComponent);
      case 'UnderstoodAcceptedMeasuresState':
        return new ComponentPortal(UnderstoodAcceptedMeasuresStateComponent);
      case 'NotifyWhistleblowerAboutAcceptedMeasuresState':
        return new ComponentPortal(NotifyWhistleblowerAboutAcceptedMeasuresStateComponent);
      case 'UnderstoodExecutionState':
        return new ComponentPortal(UnderstoodExecutionStateComponent);
      case 'NotifyWhistleblowerAboutExecutionState':
        return new ComponentPortal(NotifyWhistleblowerAboutExecutionStateComponent);
      case 'ObligedEntityUnderstoodReportState':
        return new ComponentPortal(ObligedEntityUnderstoodReportStateComponent);
      case 'SuggestionByObligedEntityState':
        return new ComponentPortal(SuggestionByObligedEntityStateComponent);
      case 'NotifyCompetentPersonAboutAcceptedMeasuresState':
        return new ComponentPortal(NotifyCompetentPersonAboutAcceptedMeasuresStateComponent);
      case 'ExecutionState':
        return new ComponentPortal(ExecutionStateComponent);
      case 'NotifyCompetentPersonAboutExecutionState':
        return new ComponentPortal(NotifyCompetentPersonAboutExecutionStateComponent);
      case 'GT_NoticeReportState':
        return new ComponentPortal(GtNoticeReportStateComponent);
      case 'GT_NotifyObligedEntityState':
        return new ComponentPortal(GtNotifyObligedEntityStateComponent);
      case 'GT_ObligedEntityUnderstoodState':
        return new ComponentPortal(GtObligedEntityUnderstoodStateComponent);
      default:
        // for 'WaitingForEvaluationByObligedEntityState', 'WaitingForExecutionByObligedEntityState', 'GT_WaitingForObligedEntityState' and for archive
        return new ComponentPortal(StatesOverviewComponent);
    }
  }
}