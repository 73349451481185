<div class="content-container">
  <div class="content-centered">
    <h1 class="custom-h1" translate [translateParams]="{number: report.detail.number}">OVERVIEW.DETAIL.HEADING</h1>

    <a [routerLink]="['/overview/reports']" class="a-btn a-primary top-left-action-button" translate>
      <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>OVERVIEW.DETAIL.BACK_TO_OVERVIEW
    </a>

    <div *ngIf="report" id="report-container">
      <!-- Report 'cp' -->
      <section *ngIf="activeRole === 'cp'" id="report-detail">
        <h2 class="custom-h2" translate>OVERVIEW.DETAIL.DETAIL</h2>
        <dl>
          <dt translate>OVERVIEW.DETAIL.NUMBER_LABEL</dt>
          <dd>{{ report.detail.number }}</dd>
          <ng-container *ngIf="appData.organizationValue.categories.length > 1">
            <dt translate>OVERVIEW.DETAIL.CATEGORY_NAME</dt>
            <dd>{{ reportCategory?.name }}</dd>
          </ng-container>
          <dt translate>OVERVIEW.DETAIL.REPORTED_AT_LABEL</dt>
          <dd>{{ utilities.dateFormat(report.detail.reportedAt) }}</dd>
          <ng-container *ngIf="report.detail.whistleblowerDetail.name">
            <dt translate>OVERVIEW.DETAIL.WB_NAME_LABEL</dt>
            <dd>{{ report.detail.whistleblowerDetail.name }}</dd>
          </ng-container>
          <ng-container *ngIf="report.detail.whistleblowerDetail.contactAddress">
            <dt translate>OVERVIEW.DETAIL.CONTACT_ADDRESS_LABEL</dt>
            <dd>{{ report.detail.whistleblowerDetail.contactAddress }}</dd>
          </ng-container>
          <ng-container *ngIf="report.detail.whistleblowerDetail.dateOfBirth">
            <dt translate>OVERVIEW.DETAIL.DATE_OF_BIRTH_LABEL</dt>
            <dd>{{ utilities.dateFormat(report.detail.whistleblowerDetail.dateOfBirth) }}</dd>
          </ng-container>
        </dl>

        <section id="report-text">
          <div *ngIf="report.detail.subject && report.detail.subject !== ''">
            <span><strong translate>OVERVIEW.DETAIL.SUBJECT_LABEL</strong></span>
            <p>{{ report.detail.subject }}</p>
          </div>
          <div>
            <span><strong translate>OVERVIEW.DETAIL.TEXT_LABEL</strong></span>
            <p>{{ report.detail.body }}</p>
          </div>
          <div *ngIf="report.detail.attachments && report.detail.attachments.length > 0" class="file-list-container">
            <span class="file-list-header"><strong translate>OVERVIEW.DETAIL.UPLOADED_ATTACHMENTS</strong></span>
            <button *ngFor="let attachment of report.detail.attachments" type="button" class="inline-button"
              (click)="downloadAttachment(attachment)">
              <mat-icon fontSet="material-icons-outlined">
                file_download</mat-icon>
              {{ attachment.name }}
            </button>
          </div>

          <section *ngIf="utilities.canAccountEdit(account) || report.detail.notes.length > 0"
            class="detail-notes-wrapper">
            <span><strong translate>OVERVIEW.DETAIL.NOTES_LABEL</strong></span>
            <div class="notes-container">
              <button *ngFor="let note of report.detail.notes" type="button" class="inline-button note-notice"
                aria-label="Open note" (click)="openNote(report, note)">
                <mat-icon fontSet="material-icons-outlined">{{ utilities.canAccountEdit(account) ? 'edit' : 'north_east'
                  }}</mat-icon><span>{{ utilities.shortText(note.body)
                  }}</span>
              </button>
              <button *ngIf="utilities.canAccountEdit(account) && report.detail.notes.length < 5" type="button"
                class="inline-button note-notice" aria-label="Add new note" (click)="addNote(report)">
                <mat-icon fontSet="material-icons-outlined">add_circle_outline</mat-icon><span
                  translate>OVERVIEW.DETAIL.ADD_NOTE</span>
              </button>
            </div>
          </section>
        </section>

        <!-- CHAT -->
        <section id="report-chat">
          <!-- loading symbol overlapping whole messages content -->
          <div id="loading-symbol-background" [hidden]="!messagesLoading">
            <div class="loading-symbol">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>

          <mat-tab-group (selectedTabChange)="setActiveChat()" mat-align-tabs="center" animationDuration="0ms">
            <mat-tab *ngFor="let chatRelatedTo of report.relevantToAllRoles ? ['wb', 'oe'] : ['wb']">
              <ng-template mat-tab-label>
                <span *ngIf="chatRelatedTo === 'wb'" translate>OVERVIEW.DETAIL.CHAT_WITH_WB_HEADING</span>
                <span *ngIf="chatRelatedTo === 'oe'" translate>OVERVIEW.DETAIL.CHAT_WITH_OE_HEADING</span>
              </ng-template>
              <div *ngIf="messages === undefined" class="messages-empty-notice" translate>
                REPORT.CHAT.MESSAGES_NOT_LOADED
              </div>
              <ng-container *ngIf="messages !== undefined">
                <section class="messages-container" #messagescontainer>
                  <div
                    *ngIf="chatRelatedTo === 'wb' ? (messagesRelatedToWb?.length === 0) : (messagesRelatedToOe?.length === 0)"
                    class="messages-empty-notice" translate>REPORT.CHAT.NO_MESSAGES
                  </div>
                  <div *ngFor="let message of (chatRelatedTo === 'wb' ? messagesRelatedToWb : messagesRelatedToOe)"
                    class="message-wrapper"
                    [class]="message.sentBy === 'cp' ? 'message-wrapper-my' : 'message-wrapper-other'">
                    <!-- TEXT MESSAGE -->
                    <ng-container *ngIf="message.text">
                      <span class="message-info">{{ 'REPORT.CHAT.TEXT_INFO_SENT_BY' | translate:{ date:
                        utilities.dateFormat(message.timestamp) } }}</span>
                      <div class="message-skeleton">
                        <quill-view-html [content]="message.text" theme="snow">
                        </quill-view-html>
                      </div>
                    </ng-container>
                    <!-- ATTACHMENT MESSAGE -->
                    <ng-container *ngIf="message.attachment">
                      <span class="message-info">{{ 'REPORT.CHAT.ATTACHMENT_INFO_SENT_BY' | translate:{ date:
                        utilities.dateFormat(message.timestamp) } }}</span>
                      <div class="message-skeleton">
                        <div class="file-list-container">
                          <button type="button" class="inline-button" (click)="downloadMessageAttachment(message)">
                            {{ message.attachment.name }}
                            <mat-icon fontSet="material-icons-outlined">
                              file_download</mat-icon>
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </section>

                <section class="shrinked shrinked-more-width">
                  <label class="label-primary" translate>REPORT.CHAT.NEW_MESSAGE</label>
                  <div class="element-hint-below">
                    <quill-editor class="editor" [(ngModel)]="newMessageTextHtml" placeholder=""
                      [styles]="{'min-height': '7.5rem'}" [maxLength]="100000" [required]="true" [sanitize]="true"
                      (onContentChanged)="newMessageTextChanged($event)"></quill-editor>
                    <div class="hint-multi">
                      <span></span>
                      <span *ngIf="newMessageTextLength > 85000">{{ newMessageTextLength }} / 100 000</span>
                    </div>
                  </div>

                  <button id="send-text-message-button" type="button" class="button-primary button-confirm"
                    (click)="sendTextMessage(chatRelatedTo)" translate>
                    REPORT.CHAT.SEND
                  </button>

                  <div class="file-input-container">
                    <input type="file" class="file-input" (change)="messageOnFileSelected($event, chatRelatedTo)"
                      #messagefileupload>
                    <button type="button" class="inline-button" (click)="messagefileupload.click()" translate>
                      REPORT.CHAT.SEND_ATTACHMENT<mat-icon fontSet="material-icons-outlined">
                        attach_file</mat-icon>
                    </button>
                  </div>
                </section>
              </ng-container>
            </mat-tab>
          </mat-tab-group>
        </section>
      </section>

      <!-- Report 'oe' -->
      <section *ngIf="activeRole === 'oe'" id="report-detail">
        <h2 class="custom-h2" translate>OVERVIEW.DETAIL.DETAIL</h2>
        <dl>
          <dt translate>OVERVIEW.DETAIL.NUMBER_LABEL</dt>
          <dd>{{ report.detail.number }}</dd>
          <ng-container *ngIf="appData.organizationValue.categories.length > 1">
            <dt translate>OVERVIEW.DETAIL.CATEGORY_NAME</dt>
            <dd>{{ reportCategory?.name }}</dd>
          </ng-container>
          <dt translate>OVERVIEW.DETAIL.OE_DATE_LABEL</dt>
          <dd>{{ utilities.dateFormat(report.detail.reportedAt) }}</dd>
          <dt translate>OVERVIEW.DETAIL.OE_CP_LABEL</dt>
          <dd>{{ report.competentPerson?.name ? report.competentPerson?.name : '-' }}</dd>
          <ng-container *ngIf="report.summaryByCompetentPerson">
            <dt translate>OVERVIEW.DETAIL.OE_SUBJECT_LABEL</dt>
            <dd>{{ report.summaryByCompetentPerson }}</dd>
          </ng-container>
        </dl>

        <section id="report-text">
          <div *ngIf="report.summaryLongByCompetentPersonHtml">
            <span><strong translate>OVERVIEW.DETAIL.SUMMARY_FROM_CP_LABEL</strong></span>
            <quill-view-html [content]="report.summaryLongByCompetentPersonHtml" theme="snow"></quill-view-html>
          </div>
          <div *ngIf="report.suggestionByCompetentPersonHtml">
            <span><strong translate>OVERVIEW.DETAIL.SUGGESTION_BY_CP_LABEL</strong></span>
            <quill-view-html [content]="report.suggestionByCompetentPersonHtml" theme="snow"></quill-view-html>
          </div>
          <div *ngIf="report.attachmentByCompetentPerson" class="file-list-container">
            <span class="file-list-header"><strong translate>OVERVIEW.DETAIL.UPLOADED_ATTACHMENTS</strong></span>
            <button type="button" class="inline-button"
              (click)="downloadAttachment(report.attachmentByCompetentPerson)">
              <mat-icon fontSet="material-icons-outlined">
                file_download</mat-icon>
              {{ report.attachmentByCompetentPerson.name }}
            </button>
          </div>

          <section *ngIf="utilities.canAccountEdit(account) || report.detail.notes.length > 0"
            class="detail-notes-wrapper">
            <span><strong translate>OVERVIEW.DETAIL.NOTES_LABEL</strong></span>
            <div class="notes-container">
              <button *ngFor="let note of report.detail.notes" type="button" class="inline-button note-notice"
                aria-label="Open note" (click)="openNote(report, note)">
                <mat-icon fontSet="material-icons-outlined">{{ utilities.canAccountEdit(account) ? 'edit' : 'north_east'
                  }}</mat-icon><span>{{ utilities.shortText(note.body)
                  }}</span>
              </button>
              <button *ngIf="utilities.canAccountEdit(account) && report.detail.notes.length < 5" type="button"
                class="inline-button note-notice" aria-label="Add new note" (click)="addNote(report)">
                <mat-icon fontSet="material-icons-outlined">add_circle_outline</mat-icon><span
                  translate>OVERVIEW.DETAIL.ADD_NOTE</span>
              </button>
            </div>
          </section>
        </section>

        <!-- CHAT -->
        <section id="report-chat">
          <!-- loading symbol overlapping whole messages content -->
          <div id="loading-symbol-background" [hidden]="!messagesLoading">
            <div class="loading-symbol">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>

          <h2 class="custom-h2" translate>OVERVIEW.DETAIL.CHAT_WITH_CP_HEADING</h2>

          <div *ngIf="messages === undefined" class="messages-empty-notice" translate>REPORT.CHAT.MESSAGES_NOT_LOADED
          </div>
          <ng-container *ngIf="messages !== undefined">
            <section class="messages-container" #messagescontainer>
              <div *ngIf="messages.length === 0" class="messages-empty-notice" translate>REPORT.CHAT.NO_MESSAGES</div>
              <div *ngFor="let message of messages" class="message-wrapper"
                [class]="message.sentBy === 'oe' ? 'message-wrapper-my' : 'message-wrapper-other'">
                <!-- TEXT MESSAGE -->
                <ng-container *ngIf="message.text">
                  <span class="message-info">{{ 'REPORT.CHAT.TEXT_INFO_SENT_BY' | translate:{ date:
                    utilities.dateFormat(message.timestamp) } }}</span>
                  <div class="message-skeleton">
                    <quill-view-html [content]="message.text" theme="snow">
                    </quill-view-html>
                  </div>
                </ng-container>
                <!-- ATTACHMENT MESSAGE -->
                <ng-container *ngIf="message.attachment">
                  <span class="message-info">{{ 'REPORT.CHAT.ATTACHMENT_INFO_SENT_BY' | translate:{ date:
                    utilities.dateFormat(message.timestamp) } }}</span>
                  <div class="message-skeleton">
                    <div class="file-list-container">
                      <button type="button" class="inline-button" (click)="downloadMessageAttachment(message)">
                        {{ message.attachment.name }}
                        <mat-icon fontSet="material-icons-outlined">
                          file_download</mat-icon>
                      </button>
                    </div>
                  </div>
                </ng-container>
              </div>
            </section>

            <section class="shrinked shrinked-more-width">
              <label class="label-primary" translate>REPORT.CHAT.NEW_MESSAGE</label>
              <div class="element-hint-below">
                <quill-editor class="editor" [(ngModel)]="newMessageTextHtml" placeholder=""
                  [styles]="{'min-height': '7.5rem'}" [maxLength]="100000" [required]="true" [sanitize]="true"
                  (onContentChanged)="newMessageTextChanged($event)"></quill-editor>
                <div class="hint-multi">
                  <span></span>
                  <span *ngIf="newMessageTextLength > 85000">{{ newMessageTextLength }} / 100 000</span>
                </div>
              </div>

              <button id="send-text-message-button" type="button" class="button-primary button-confirm"
                (click)="sendTextMessage('cp')" translate>
                REPORT.CHAT.SEND
              </button>

              <div class="file-input-container">
                <input type="file" class="file-input" (change)="messageOnFileSelected($event, 'cp')" #messagefileupload>
                <button type="button" class="inline-button" (click)="messagefileupload.click()" translate>
                  REPORT.CHAT.SEND_ATTACHMENT<mat-icon fontSet="material-icons-outlined">
                    attach_file</mat-icon>
                </button>
              </div>
            </section>
          </ng-container>
        </section>
      </section>

      <section id="report-states">
        <!-- only if there are more than 1 categories and only one state is opened (means it is new report) and activeRole === 'cp' => allow change category -->
        <button
          *ngIf="appData.organizationValue.categories.length > 1 && report.states.length <= 1 && activeRole === 'cp'"
          id="change-category-button" type="button" class="inline-button" (click)="changeCategory()">
          <span>
            {{'OVERVIEW.DETAIL.CHANGE_CATEGORY' | translate}}
            <mat-icon fontSet="material-icons-outlined">sync_alt</mat-icon>
          </span>
          {{'OVERVIEW.DETAIL.CHANGE_CATEGORY_HINT' | translate}}
        </button>
        <!-- dynamically choose component through the portal -->
        <ng-template [cdkPortalOutlet]="statesComponentPortal"></ng-template>
      </section>
    </div>
  </div>
</div>