import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ContentChange } from 'ngx-quill';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { ConfirmDialogComponent } from 'src/app/components/widgets/confirm-dialog/confirm-dialog.component';
import { Report } from 'src/app/models/report';
import { GT_NotifyObligedEntityState } from 'src/app/models/report-state';
import { ReportStateService } from 'src/app/services/report-state.service';
import { AppData } from 'src/app/singletons/app-data';
import { Constants } from 'src/app/utilities/constants';
import { ReportStateHelper } from 'src/app/utilities/report-state-helper';
import { Utilities } from 'src/app/utilities/utilities';

enum ComponentState {
  ActionChoice, NotifyOe
}

@Component({
  selector: 'app-gt-notify-obliged-entity-state',
  templateUrl: './gt-notify-obliged-entity-state.component.html',
  styleUrls: ['./gt-notify-obliged-entity-state.component.css']
})
export class GtNotifyObligedEntityStateComponent implements OnInit, OnDestroy {

  public componentStateEnum = ComponentState;
  public reportStateHelper = ReportStateHelper;

  public report!: Report;

  private _activeReportStateSubscription$!: Subscription;
  public state!: GT_NotifyObligedEntityState;

  public componentState = ComponentState.ActionChoice;

  public stateFormData = new FormData();

  public notifyOeTextLength = 0;

  private _stateApi = '';

  constructor(
    private _appData: AppData,
    private _reportStateService: ReportStateService,
    public dialog: MatDialog,
    public app: AppComponent,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    if (!this._appData.activeReportValue) {
      return;
    }

    this.report = this._appData.activeReportValue;
    this._activeReportStateSubscription$ = this._appData.activeReportState.subscribe((state) => {
      if (!state) {
        return;
      }

      this.state = state as GT_NotifyObligedEntityState;
      this._stateApi = Constants.stateApiMap.get(this.state.id) ?? '';

      if (!this.state.notifyObligedEntityTextHtml) {
        this.translate.get('OVERVIEW.DETAIL.GT_NOTIFY_OE.T_NOTIFY_OE_HTML',
          { date: Utilities.dateFormat(this.report.detail.reportedAt), text: this.report.detail.body }
        ).subscribe((res: string) => {
          this.state.notifyObligedEntityTextHtml = res;
        }).unsubscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this._activeReportStateSubscription$.unsubscribe();
  }

  public goBack(): void {
    if (this.componentState === ComponentState.ActionChoice) {
      this._appData.setActiveReportState(null);
      return;
    }

    this.componentState = ComponentState.ActionChoice;
  }

  public notifyOeTextChanged(event: ContentChange): void {
    this.notifyOeTextLength = event.text.length - 1;
  }

  public goToNotifyOe(): void {
    this.componentState = ComponentState.NotifyOe;
  }

  public archiveNow(): void {
    this.translate.get('OVERVIEW.DETAIL.GT_NOTIFY_OE.ARCHIVE_NOW_DIALOG_TEXT').subscribe((dialogTitle: string) => {
      this.translate.get('GENERAL.CANNOT_BE_UNDONE').subscribe((dialogBody: string) => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '40rem',
          panelClass: 'dialog-container',
          data: {
            title: dialogTitle,
            body: dialogBody,
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (!result) {
            return;
          }

          this.stateFormData = new FormData();
          this.stateFormData.set('state', JSON.stringify(this.state));

          this.app.showLoading();
          this._reportStateService.send(`${this._stateApi}/archive`, this.report._id, this.report.lastUpdated, this.stateFormData).subscribe(res => {
            if (res) {
              // replace old report with the new one
              this.report = res.report;
              this._appData.setActiveReport(res.report);
              this._appData.replaceSingleReport(res.report);
              this._appData.setNewMessage(res.message);
              // set active report to null (=> redirect to states overview)
              this._appData.setActiveReportState(null);
              this.translate.get('OVERVIEW.DETAIL.N_CHOICE_SAVED_REPORT_FINISHED').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            }
            this.app.hideLoading();
          }, (errorStatus) => {
            if (errorStatus === 409) {
              this.translate.get('GENERAL.N_DATA_CHANGED').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            } else {
              this.translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            }
            this.app.hideLoading();
          });
        });
      }).unsubscribe();
    }).unsubscribe();
  }

  public notifyOeConfirm(): void {
    if (!this.state.notifyObligedEntityTextHtml || this.state.notifyObligedEntityTextHtml.trim().length === 0) {
      this.translate.get('OVERVIEW.DETAIL.GT_NOTIFY_OE.FILL_TEXT').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    this.translate.get('OVERVIEW.DETAIL.EXECUTION.DIALOG_TEXT').subscribe((dialogTitle: string) => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '40rem',
        panelClass: 'dialog-container',
        data: {
          title: dialogTitle,
          body: '',
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (!result) {
          return;
        }

        this.stateFormData = new FormData();
        this.stateFormData.set('state', JSON.stringify(this.state));

        this.app.showLoading();
        this._reportStateService.send(`${this._stateApi}/notify_obliged_entity`, this.report._id, this.report.lastUpdated, this.stateFormData).subscribe(res => {
          if (res) {
            // replace old report with the new one
            this.report = res.report;
            this._appData.setActiveReport(res.report);
            this._appData.replaceSingleReport(res.report);
            this._appData.setNewMessage(res.message);
            // set active report to null (=> redirect to states overview)
            this._appData.setActiveReportState(null);
            this.translate.get('OVERVIEW.DETAIL.GT_NOTIFY_OE.N_OE_NOTIFIED').subscribe((res: string) => {
              this.app.buildNotification(res);
            }).unsubscribe();
          }
          this.app.hideLoading();
        }, (errorStatus) => {
          if (errorStatus === 409) {
            this.translate.get('GENERAL.N_DATA_CHANGED').subscribe((res: string) => {
              this.app.buildNotification(res);
            }).unsubscribe();
          } else {
            this.translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
              this.app.buildNotification(res);
            }).unsubscribe();
          }
          this.app.hideLoading();
        });
      });
    }).unsubscribe();
  }
}