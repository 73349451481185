<ng-template #template>
  <div id="menu-button" class="icon-button" aria-label="Open menu" [matMenuTriggerFor]="menu">
    <mat-icon fontSet="material-icons-outlined">more_horiz</mat-icon>
  </div>
  <mat-menu #menu="matMenu">
    <!-- class mat-elevation-z added to unset box-shadow (since it is already positioned in mat-menu) -->
    <mat-card id="menu-holder" class="mat-elevation-z">
      <section id="account-info-container">
        <div id="account-name-container">
          <div class="centered">
            <mat-icon *ngIf="activeRole === 'cp'" fontSet="material-icons-outlined">person_search</mat-icon>
            <mat-icon *ngIf="activeRole === 'oe'" fontSet="material-icons-outlined">supervised_user_circle</mat-icon>
            <mat-icon *ngIf="activeRole === 'oa'" fontSet="material-icons-outlined">manage_accounts</mat-icon>
          </div>
          <span><strong>{{ account.name }}</strong></span>
        </div>
        <span *ngIf="activeRole === 'cp'" class="smaller-span" translate>GENERAL.COMPETENT_PERSON</span>
        <span *ngIf="activeRole === 'oe'" class="smaller-span" translate>GENERAL.OBLIGED_ENTITY</span>
        <span *ngIf="activeRole === 'oa'" class="smaller-span" translate>GENERAL.ORGANIZATION_ADMINISTRATOR</span>
        <span *ngIf="account.state === 'lockedReadWrite'" class="smaller-span state-note"
          [translateParams]="{ date: utilities.dateFormat(account.lockedReadWriteAt) }"
          translate>APP_MENU.LOCKED_READ_WRITE</span>
        <span *ngIf="account.state === 'lockedReadOnly'" class="smaller-span state-note"
          [translateParams]="{ date: utilities.dateFormat(account.lockedReadOnlyAt) }"
          translate>APP_MENU.LOCKED_READ_ONLY</span>
      </section>
      <ng-container *ngFor="let role of constants.listOfRoles">
        <button *ngIf="displayChangeToRole(role)" type="button" class="inline-button" (click)="changeRole(role)">
          <mat-icon *ngIf="role === 'cp'" fontSet="material-icons-outlined">person_search</mat-icon>
          <mat-icon *ngIf="role === 'oe'" fontSet="material-icons-outlined">supervised_user_circle</mat-icon>
          <mat-icon *ngIf="role === 'oa'" fontSet="material-icons-outlined">manage_accounts</mat-icon>
          {{ 'APP_MENU.CHANGE_ROLE_TO_' + role.toUpperCase() | translate }}
        </button>
      </ng-container>
      <a [routerLink]="['/account']">
        <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
        <span translate>APP_MENU.GO_TO_ACCOUNT</span>
      </a>
      <button type="button" class="inline-button" (click)="showHelp()" translate>
        <mat-icon fontSet="material-icons-outlined">help_outline</mat-icon>
        APP_MENU.SHOW_HELP
      </button>
      <button type="button" class="inline-button" (click)="logout()" translate>
        <mat-icon fontSet="material-icons-outlined" class="rotate-180-deg">login</mat-icon>
        GENERAL.LOGOUT
      </button>
    </mat-card>
  </mat-menu>
</ng-template>