import { Component } from '@angular/core';
import { Organization } from 'src/app/models/organization';
import { AppData } from 'src/app/singletons/app-data';

@Component({
  selector: 'app-choose-category',
  templateUrl: './choose-category.component.html',
  styleUrls: ['./choose-category.component.css']
})
export class ChooseCategoryComponent {

  public organization: Organization;

  constructor(
    private _appData: AppData,
  ) {
    this.organization = this._appData.organizationValue;
    this.organization.categories.sort((c1, c2) => c1.order - c2.order);
  }
}
