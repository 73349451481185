<div class="content-container">
  <div class="content-centered">
    <h1 class="custom-h1" translate>REPORT.INSERTED.HEADING</h1>

    <div class="visual-container">
      <section *ngIf="identificationCode">
        <p [innerHTML]="'REPORT.INSERTED.CODE_NOTICE' | translate"></p>
        <button type="button" class="inline-button" [cdkCopyToClipboard]="identificationCode"
          (cdkCopyToClipboardCopied)="copyDone()"><strong>{{
            identificationCode }}</strong>
          <mat-icon fontSet="material-icons-outlined">content_copy
          </mat-icon>
        </button>

        <ng-container *ngIf="!subscribed">
          <p [innerHTML]="'REPORT.INSERTED.INSERT_EMAIL' | translate"></p>
          <div class="element-hint-below">
            <input id="wb-email" name="wb-email" type="email" class="input-primary"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" [placeholder]="wbEmailPlaceholder" [(ngModel)]="wbEmail"
              #wbemail="ngModel" autocomplete="off" />
            <div class="hint-single">
              <span [innerHTML]="'REPORT.INSERTED.INSERT_EMAIL_HINT' | translate"></span>
            </div>
          </div>
          <button type="button" class="button-primary button-confirm" (click)="sendWbEmail()"
            translate>REPORT.INSERTED.EMAIL_SEND
            <mat-icon fontSet="material-icons-outlined">forward_to_inbox
            </mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="subscribed">
          <p [innerHTML]="'REPORT.INSERTED.SUBSCRIBED_NOTICE' | translate"></p>
        </ng-container>
      </section>

      <a id="a-home" [routerLink]="['/']" class="a-btn a-primary" translate>
        REPORT.INSERTED.BACK_HOME<mat-icon fontSet="material-icons-outlined">home</mat-icon>
      </a>
    </div>
  </div>