import { Attachment } from "./report";

export abstract class ReportState {
  number!: number;
  createdAt!: Date;
  concludedAt: Date | undefined;
  id!: string;

  public get transformedId(): string {
    return this.id.startsWith('ArchivedForRoleState') ? 'ArchivedForRoleState' : this.id;
  }

  public isArchivedForRole(): boolean {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSpecificClass(): any {
    switch (this.transformedId) {
      case 'NotifyWhistleblowerAboutReportNoticingState':
        return NotifyWhistleblowerAboutReportNoticingState;
      case 'ReportEvaluationState':
        return ReportEvaluationState;
      case 'JustifyReportNotJustState':
        return JustifyReportNotJustState;
      case 'NotifyWhistleblowerAboutReportEvaluationState':
        return NotifyWhistleblowerAboutReportEvaluationState;
      case 'SuggestionForObligedEntityState':
        return SuggestionForObligedEntityState;
      case 'WaitingForEvaluationByObligedEntityState':
        return WaitingForEvaluationByObligedEntityState;
      case 'UnderstoodAcceptedMeasuresState':
        return UnderstoodAcceptedMeasuresState;
      case 'NotifyWhistleblowerAboutAcceptedMeasuresState':
        return NotifyWhistleblowerAboutAcceptedMeasuresState;
      case 'WaitingForExecutionByObligedEntityState':
        return WaitingForExecutionByObligedEntityState;
      case 'UnderstoodExecutionState':
        return UnderstoodExecutionState;
      case 'NotifyWhistleblowerAboutExecutionState':
        return NotifyWhistleblowerAboutExecutionState;
      case 'ObligedEntityUnderstoodReportState':
        return ObligedEntityUnderstoodReportState;
      case 'SuggestionByObligedEntityState':
        return SuggestionByObligedEntityState;
      case 'NotifyCompetentPersonAboutAcceptedMeasuresState':
        return NotifyCompetentPersonAboutAcceptedMeasuresState;
      case 'ExecutionState':
        return ExecutionState;
      case 'NotifyCompetentPersonAboutExecutionState':
        return NotifyCompetentPersonAboutExecutionState;
      case 'GT_NoticeReportState':
        return GT_NoticeReportState;
      case 'GT_NotifyObligedEntityState':
        return GT_NotifyObligedEntityState;
      case 'GT_WaitingForObligedEntityState':
        return GT_WaitingForObligedEntityState;
      case 'GT_ObligedEntityUnderstoodState':
        return GT_ObligedEntityUnderstoodState;
      case 'ArchivedForRoleState':
        return ArchivedForRoleState;
      default:
        return ReportState;
    }
  }
}

export class NotifyAbout {
  // want to notify now
  notifyNowTextHtml: string | undefined;
  dateNotified: Date | undefined;
}

export class NotifyWhistleblowerAboutReportNoticingState extends ReportState {
  notifyAboutReportNoticing!: NotifyAbout;

  public isArchivedForRole(): boolean {
    return false;
  }
}

export class ReportEvaluationState extends ReportState {
  isReportJust: boolean | undefined;

  extendedOnceDate: Date | undefined;
  extendedOnceDaysCount: number | undefined;
  notifiedAboutExtendingOnce!: NotifyAbout;

  extendedTwiceDate: Date | undefined;
  extendedTwiceDaysCount: number | undefined;
  notifiedAboutExtendingTwice!: NotifyAbout;

  public isArchivedForRole(): boolean {
    return false;
  }
}

export class JustifyReportNotJustState extends ReportState {
  justifyReportNotJustTextHtml: string | undefined;

  public isArchivedForRole(): boolean {
    return false;
  }
}

export class NotifyWhistleblowerAboutReportEvaluationState extends ReportState {
  notifyAboutReportEvaluation!: NotifyAbout;
  isReportJust!: boolean;

  public isArchivedForRole(): boolean {
    return false;
  }
}

export class SuggestionForObligedEntityState extends ReportState {
  public isArchivedForRole(): boolean {
    return false;
  }
}

export class WaitingForEvaluationByObligedEntityState extends ReportState {
  public isArchivedForRole(): boolean {
    return false;
  }
}

export class UnderstoodAcceptedMeasuresState extends ReportState {
  textFromObligedEntityHtml!: string;

  attachment: Attachment | undefined;

  public isArchivedForRole(): boolean {
    return false;
  }
}

export class NotifyWhistleblowerAboutAcceptedMeasuresState extends ReportState {
  hasAcceptedMeasuresByCompetentPerson: boolean | undefined;
  acceptedMeasuresTextHtml: string | undefined;

  notifyAboutAcceptedMeasures!: NotifyAbout;

  public isArchivedForRole(): boolean {
    return false;
  }
}

export class WaitingForExecutionByObligedEntityState extends ReportState {
  public isArchivedForRole(): boolean {
    return false;
  }
}

export class UnderstoodExecutionState extends ReportState {
  textFromObligedEntityHtml!: string;

  attachment: Attachment | undefined;

  public isArchivedForRole(): boolean {
    return false;
  }
}

export class NotifyWhistleblowerAboutExecutionState extends ReportState {
  executionTextHtml!: string;

  notifyAboutExecution!: NotifyAbout;

  public isArchivedForRole(): boolean {
    return false;
  }
}

export class ObligedEntityUnderstoodReportState extends ReportState {
  public isArchivedForRole(): boolean {
    return false;
  }
}

export class SuggestionByObligedEntityState extends ReportState {
  hasAcceptedMeasuresByCompetentPerson: boolean | undefined;
  suggestionByObligedEntityTextHtml: string | undefined;

  public isArchivedForRole(): boolean {
    return false;
  }
}

export class NotifyCompetentPersonAboutAcceptedMeasuresState extends ReportState {
  notifyCompetentPersonTextHtml: string | undefined;

  attachment: Attachment | undefined;

  public isArchivedForRole(): boolean {
    return false;
  }
}

export class ExecutionState extends ReportState {
  executionTextHtml: string | undefined;

  public isArchivedForRole(): boolean {
    return false;
  }
}

export class NotifyCompetentPersonAboutExecutionState extends ReportState {
  notifyCompetentPersonTextHtml: string | undefined;

  attachment: Attachment | undefined;

  public isArchivedForRole(): boolean {
    return false;
  }
}

export class GT_NoticeReportState extends ReportState {
  public isArchivedForRole(): boolean {
    return false;
  }
}

export class GT_NotifyObligedEntityState extends ReportState {
  notifyObligedEntityTextHtml: string | undefined;

  public isArchivedForRole(): boolean {
    return false;
  }
}

export class GT_WaitingForObligedEntityState extends ReportState {
  public isArchivedForRole(): boolean {
    return false;
  }
}

export class GT_ObligedEntityUnderstoodState extends ReportState {
  public isArchivedForRole(): boolean {
    return false;
  }
}

export class ArchivedForRoleState extends ReportState {
  public isArchivedForRole(): boolean {
    return true;
  }
}