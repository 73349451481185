/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { Organization } from 'src/app/models/organization';
import { Attachment, Detail, Report } from 'src/app/models/report';
import { ReportCategory } from 'src/app/models/report-category';
import { ReportService } from 'src/app/services/report.service';
import { AppData } from 'src/app/singletons/app-data';
import { Constants } from 'src/app/utilities/constants';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  public organization!: Organization;

  public reportSubject = '';
  public reportBody = '';
  public reportCategory!: ReportCategory;
  public whistleblowerName = '';
  public whistleblowerDateOfBirth: Date | null = null;

  public whistleblowerAddress = '';

  public termsAccepted = false;

  public optionalFieldsVisible = false;

  private _attachmentLastId = 0;
  public attachments: Attachment[] = [];
  private _attachmentSizeMap = new Map<string, number>();
  private _reportFormData = new FormData();

  constructor(
    private _appData: AppData,
    private _router: Router,
    private _route: ActivatedRoute,
    public app: AppComponent,
    private _reportService: ReportService,
    private _translate: TranslateService,
  ) { }

  ngOnInit(): void {
    if (this._appData.organizationValue.identificationCode.length === 0) {
      this._router.navigate(['/']);
    } else {
      this.organization = this._appData.organizationValue;
      const category = this.organization.categories.find(c => c.id === this._route.snapshot.queryParams['categoryId']);
      if (!category) {
        this._router.navigate(['/']);
      } else {
        this.reportCategory = category;
      }
    }
  }

  toggleOptionalFields(): void {
    this.optionalFieldsVisible = !this.optionalFieldsVisible;

    const collapsible = (document.getElementsByClassName('height-transition-container')[0]) as HTMLElement;
    if (this.optionalFieldsVisible) {
      let height = 0;
      for (let i = 0; i < collapsible.children.length; i++) {
        const innerNodeElem = collapsible.children[i] as HTMLElement;
        height += innerNodeElem.scrollHeight;
      }
      collapsible.style.maxHeight = `${collapsible.scrollHeight + height}px`;
    } else {
      collapsible.style.maxHeight = '0';
    }
  }

  sendReport(): void {
    if (!this.reportBody || this.reportBody === '') {
      this._translate.get('REPORT.REPORT.N_FILL_BODY').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    if (!this.termsAccepted) {
      this._translate.get('REPORT.REPORT.N_ACCEPT').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    // attachments are sent in the formData
    const reportDetail = new Detail(
      this.reportSubject,
      this.reportBody,
      this.reportCategory.id,
      [],
      this.whistleblowerName,
      this.whistleblowerDateOfBirth,
      this.whistleblowerAddress,
    );

    const report = new Report(this.organization._id, reportDetail);
    this._reportFormData.set('report', JSON.stringify(report));

    this.app.showLoading();
    this._reportService
      .send(this._reportFormData, this.organization.identificationCode)
      .subscribe(({ identificationCode }) => {
        this._appData.reportIdentificationCode = identificationCode;
        this._redirect();
        this._translate.get('REPORT.REPORT.N_SUCCESS').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
        this.app.hideLoading();
      }, (errorStatus) => {
        if (errorStatus < 500) {
          // TODO is it ok?
          this._translate.get('REPORT.REPORT.N_BAD_REQUEST').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
        } else {
          this._translate.get('REPORT.REPORT.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
        }
        this.app.hideLoading();
      });
  }

  private _redirect(): void {
    this._router.navigate([`/inserted`]);
  }

  cannotUploadMore(): void {
    this._translate.get('GENERAL.N_ATTACHMENT_CANNOT_UPLOAD_MORE').subscribe((res: string) => {
      this.app.buildNotification(res);
    }).unsubscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFileSelected(event: any): void {
    const attachment: File = event.target.files[0];

    if (!attachment) {
      return;
    }

    if (attachment.size > Constants.maxSizeOfInputFile) {
      this._translate.get('GENERAL.N_ATTACHMENT_SIZE_LIMIT').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    const attachmentsTotalSize = [...this._attachmentSizeMap.values()].reduce((acc, current) => acc + current, 0);

    if (attachment.size + attachmentsTotalSize > Constants.maxTotalSizeOfInputFiles) {
      this._translate.get('GENERAL.N_ATTACHMENT_TOTAL_SIZE_LIMIT').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    const newId = this._attachmentLastId.toString();
    this._attachmentLastId++;
    this.attachments.push(new Attachment(newId, attachment.name));
    this._reportFormData.append(`attachment${newId}`, attachment);
    this._attachmentSizeMap.set(newId, attachment.size);
  }

  removeAttachment(attachmentId: string): void {
    const deletedIndex = this.attachments.findIndex((attachment) => attachment.id === attachmentId);
    const deleted = this.attachments.splice(deletedIndex, 1);
    this._reportFormData.delete(`attachment${attachmentId}`);
    this._attachmentSizeMap.delete(attachmentId);
    this._translate.get('GENERAL.N_ATTACHMENT_REMOVED', { name: deleted[0].name }).subscribe((res: string) => {
      this.app.buildNotification(res);
    }).unsubscribe();
  }

  onTextareaInput(event: Event): void {
    const element = event.target as HTMLElement;
    element.style.height = 'auto';
    element.style.height = `${element.scrollHeight}px`;
  }
}
