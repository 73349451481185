import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { ReportService } from 'src/app/services/report.service';
import { AppData } from 'src/app/singletons/app-data';

@Component({
  selector: 'app-chat-request',
  templateUrl: './chat-request.component.html',
  styleUrls: ['./chat-request.component.css']
})
export class ChatRequestComponent {

  public identificationCode = '';

  constructor(
    private _appData: AppData,
    private _reportService: ReportService,
    private _router: Router,
    public app: AppComponent,
    private _translate: TranslateService,
  ) { }

  public identificationCodeChanged(): void {
    if (this.identificationCode.length === 16) {
      this.app.showLoading();
      this._reportService
        .getByIdentificationCode(this.identificationCode)
        .subscribe((res) => {
          if (res) {
            this._appData.reportForWhistleblower = res;
            this._appData.reportIdentificationCode = this.identificationCode;
            this._redirect();
            this._translate.get('REPORT.CHAT_REQUEST.N_CODE_OK').subscribe((res: string) => {
              this.app.buildNotification(res);
            }).unsubscribe();
          }
          this.app.hideLoading();
        }, (errorStatus) => {
          if (errorStatus < 500) {
            this._translate.get('REPORT.CHAT_REQUEST.N_BAD_REQUEST').subscribe((res: string) => {
              this.app.buildNotification(res);
            }).unsubscribe();
          } else {
            this._translate.get('REPORT.CHAT_REQUEST.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
              this.app.buildNotification(res);
            }).unsubscribe();
          }
          this.app.hideLoading();
        });
    }
  }

  private _redirect(): void {
    this._router.navigate(['/report/chat']);
  }
}
