/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { Account, AccountSimplified } from 'src/app/models/account';
import { Organization } from 'src/app/models/organization';
import { Attachment } from 'src/app/models/report';
import { AccountService } from 'src/app/services/account.service';
import { AppData } from 'src/app/singletons/app-data';
import { Constants } from 'src/app/utilities/constants';
import { Utilities } from 'src/app/utilities/utilities';
import { AccountDialogComponent, AccountDialogMode } from '../../widgets/account-dialog/account-dialog.component';
import { ConfirmDialogComponent } from '../../widgets/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-accounts-overview',
  templateUrl: './accounts-overview.component.html',
  styleUrls: ['./accounts-overview.component.css']
})
export class AccountsOverviewComponent implements OnInit {

  public organization!: Organization;
  public account!: Account | null;
  public accountsCp: AccountSimplified[] = [];
  public accountsOe: AccountSimplified[] = [];

  public utilities = Utilities;

  public accountGroupsToDisplay = [
    {
      headingLabelId: 'OBLIGED_ENTITIES',
      addLabelId: 'OE',
      accounts: this.accountsOe,
      role: 'oe',
    },
    {
      headingLabelId: 'COMPETENT_PERSONS',
      addLabelId: 'CP',
      accounts: this.accountsCp,
      role: 'cp',
    }
  ];

  constructor(
    private _appData: AppData,
    public app: AppComponent,
    private _translate: TranslateService,
    private _accountService: AccountService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.organization = this._appData.organizationValue;
    this.account = this._appData.accountValue;
    if (Utilities.canAccountEdit(this.account)) {
      this._loadAccounts();
    }
  }

  private _loadAccounts(): void {
    this.accountsCp = [];
    this.accountsOe = [];

    this._accountService
      .getOrganizationAccounts(this.organization)
      .subscribe((accounts) => {
        if (accounts) {
          this.accountsCp = accounts.filter(account => account.roles.indexOf('cp') !== -1);
          this.accountsOe = accounts.filter(account => account.roles.indexOf('oe') !== -1);

          this.accountGroupsToDisplay[0].accounts = this.accountsOe;
          this.accountGroupsToDisplay[1].accounts = this.accountsCp;
        }
      }, () => {
        this._translate.get('ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.N_LOAD_UNSUCCESSFUL').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      });
  }

  public editAccount(account: AccountSimplified): void {
    const accountCopy = new AccountSimplified(
      account._id,
      account.email,
      account.name,
      account.state,
      account.roles,
      account.language,
      account.lockedReadWriteAt,
      account.lockedReadOnlyAt,
      account.notifyAboutNewReports,
      account.notifyAboutReportStateChanges,
      account.notifyAboutDeadlines,
      account.notifyAboutNewMessages,
      account.attachments,
      account.categories,
    );
    // need to deep copy categories
    const previousCategories = Utilities.copy(account.categories);

    const dialogRef = this.dialog.open(AccountDialogComponent, {
      width: '40rem',
      panelClass: 'dialog-container',
      data: {
        organization: this.organization,
        account: accountCopy,
        accountDialogMode: AccountDialogMode.editByOa,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!this._handleDialogValidation(result) || !account) {
        account.categories = previousCategories;
        return;
      }

      account.roles = accountCopy.roles;
      account.categories = accountCopy.categories;

      this._translate.get('GENERAL.N_CHANGES_SUCCESSFUL').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
    });
  }

  public addAccount(role: string): void {
    const newAccount = new AccountSimplified('', '', '', '', [role], this.organization.language ?? 'cz', undefined, undefined, true, true, true, true, [], []);
    this._addAccount(newAccount);
  }

  private _addAccount(newAccount: AccountSimplified): void {
    const dialogRef = this.dialog.open(AccountDialogComponent, {
      width: '40rem',
      panelClass: 'dialog-container',
      data: {
        organization: this.organization,
        account: newAccount,
        accountDialogMode: AccountDialogMode.create
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!this._handleDialogValidation(result)) {
        return;
      }

      this._loadAccounts();

      this._translate.get('GENERAL.N_CHANGES_SUCCESSFUL').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _handleDialogValidation(result: any): boolean {
    if (!result) {
      return false;
    }

    if (result.conflict) {
      this._translate.get('ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.N_ERROR_ACCOUNT_EXISTS').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return false;
    }

    if (result.err) {
      this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return false;
    }

    if (result.email === false) {
      this._translate.get('ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.N_EMAIL_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return false;
    }

    if (result.name === false) {
      this._translate.get('ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.N_NAME_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return false;
    }

    return true;
  }

  public lockAccountReadWrite(account: AccountSimplified): void {
    this._translate.get('ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.DIALOG_LOCK_RW_TITLE').subscribe((dialogTitle: string) => {
      this._translate.get('GENERAL.CANNOT_BE_UNDONE').subscribe((dialogBody: string) => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '40rem',
          panelClass: 'dialog-container',
          data: { title: dialogTitle, body: dialogBody }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (!result) {
            return;
          }

          this._accountService
            .lockReadWrite(account, this.organization)
            .subscribe((account) => {
              if (!account) {
                return;
              }

              this._loadAccounts();

              this._translate.get('GENERAL.N_CHANGES_SUCCESSFUL').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            }, () => {
              this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            });
        });
      }).unsubscribe();
    }).unsubscribe();
  }

  public lockAccountReadOnly(account: AccountSimplified): void {
    this._translate.get('ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.DIALOG_LOCK_RO_TITLE').subscribe((dialogTitle: string) => {
      this._translate.get('GENERAL.CANNOT_BE_UNDONE').subscribe((dialogBody: string) => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '40rem',
          panelClass: 'dialog-container',
          data: { title: dialogTitle, body: dialogBody }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (!result) {
            return;
          }

          this._accountService
            .lockReadOnly(account, this.organization)
            .subscribe((account) => {
              if (!account) {
                return;
              }

              this._loadAccounts();

              this._translate.get('GENERAL.N_CHANGES_SUCCESSFUL').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            }, () => {
              this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
            });
        });
      }).unsubscribe();
    }).unsubscribe();
  }

  cannotUploadMore(): void {
    this._translate.get('GENERAL.N_ATTACHMENT_CANNOT_UPLOAD_MORE').subscribe((res: string) => {
      this.app.buildNotification(res);
    }).unsubscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onFileSelected(event: any, account: AccountSimplified): void {
    const attachment: File = event.target.files[0];

    if (!attachment) {
      return;
    }

    if (attachment.size > Constants.maxSizeOfInputFile) {
      this._translate.get('GENERAL.N_ATTACHMENT_SIZE_LIMIT').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    const attachmentFormData = new FormData();
    attachmentFormData.set('attachment', attachment);

    this.app.showLoading();
    this._accountService.uploadAttachment(account, attachmentFormData).subscribe((res) => {
      if (res) {
        account.attachments.push(res);

        this._translate.get('GENERAL.N_FILE_UPLOADED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      }
      this.app.hideLoading();
    }, () => {
      this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      this.app.hideLoading();
    });
  }

  removeAttachment(account: AccountSimplified, attachment: Attachment): void {
    this._translate.get('ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.DIALOG_REMOVE_ATTACHMENT').subscribe((dialogText: string) => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '40rem',
        panelClass: 'dialog-container',
        data: { title: dialogText, body: '' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (!result) {
          return;
        }

        this._accountService
          .removeAttachment(account, attachment)
          .subscribe(() => {
            const deletedIndex = account.attachments.findIndex((att) => att.id === attachment.id);
            const deleted = account.attachments.splice(deletedIndex, 1);

            this._translate.get('GENERAL.N_ATTACHMENT_REMOVED', { name: deleted[0].name }).subscribe((res: string) => {
              this.app.buildNotification(res);
            }).unsubscribe();
          }, () => {
            this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
              this.app.buildNotification(res);
            }).unsubscribe();
          });
      });
    }).unsubscribe();
  }

  public downloadAttachment(account: AccountSimplified, attachment: Attachment): void {
    this.app.showLoading();
    this._accountService.downloadAttachment(account, attachment).subscribe((res) => {
      if (res) {
        Utilities.downloadFile(res, attachment.name);
        this._translate.get('GENERAL.N_FILE_DOWNLOADED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      }
      this.app.hideLoading();
    }, () => {
      this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      this.app.hideLoading();
    });
  }
}
