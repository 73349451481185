import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class ResetPasswordRequestComponent {

  public email = '';

  public emailPlaceholder = '';

  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router,
    public app: AppComponent,
    private _translate: TranslateService,
  ) {
    this._translate.get('AUTH.LOGIN.EMAIL_PLACEHOLDER').subscribe((res: string) => {
      this.emailPlaceholder = res;
    }).unsubscribe();
  }

  public sendResetPasswordRequest(): void {
    if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(this.email)) {
      this._translate.get('AUTH.LOGIN.N_EMAIL_FORMAT').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    this.app.showLoading();
    this._authenticationService
      .resetPasswordRequest(this.email)
      .subscribe(() => {
        this._router.navigate(['reset-password/done'], { replaceUrl: true });
        this._translate.get('AUTH.RESET_PASSWORD.REQUEST.N_SUCCESSFUL').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
        this.app.hideLoading();
      }, (errorStatus) => {
        if (errorStatus < 500) {
          this._translate.get('AUTH.RESET_PASSWORD.REQUEST.N_ERROR').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
        } else {
          this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
        }
        this.app.hideLoading();
      });
  }
}
