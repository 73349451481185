import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';

// https://stackoverflow.com/questions/47593692/how-to-translate-mat-paginator-in-angular-4

const _czRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) return '';

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} z ${length}`;
}

// const _czRangeLabel = () => {
//   return '';
// }

export function getCzPaginatorIntl(): MatPaginatorIntl {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Záznamů na stránku:';
  paginatorIntl.nextPageLabel = 'Další stránka';
  paginatorIntl.previousPageLabel = 'Předchozí stránka';
  paginatorIntl.firstPageLabel = 'První stránka';
  paginatorIntl.lastPageLabel = 'Poslední stránka';
  paginatorIntl.getRangeLabel = _czRangeLabel;

  return paginatorIntl;
}