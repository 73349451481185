/* eslint-disable @typescript-eslint/ban-types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AccountFromServer, AccountSimplified } from '../models/account';
import { Organization } from '../models/organization';
import { Attachment } from '../models/report';
import { Utilities } from '../utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private _accountApiUrl = 'api/account';

  constructor(
    private _http: HttpClient,
  ) { }

  changePassword(accountId: string, oldPassword: string, newPassword: string, newPasswordAgain: string): Observable<{}> {
    return this._http.post<{}>(`${this._accountApiUrl}/change_password/${accountId}`, {
      oldPassword: oldPassword,
      newPassword: newPassword,
      newPasswordAgain: newPasswordAgain
    })
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  getOrganizationAccounts(organization: Organization): Observable<AccountSimplified[]> {
    return this._http.get<AccountFromServer[]>(`${this._accountApiUrl}/organization/${organization._id}`)
      .pipe(
        map(res => res.map(accountFromServer => Utilities.transformToClassInstance(AccountFromServer, accountFromServer).toAccountSimplified(organization))),
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  updateByOa(accountSimplified: AccountSimplified, organization: Organization): Observable<AccountSimplified> {
    return this._http.patch<AccountFromServer>(`${this._accountApiUrl}/${accountSimplified._id}`, accountSimplified.toAccountFromServer())
      .pipe(
        map(res => Utilities.transformToClassInstance(AccountFromServer, res).toAccount(organization)),
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  updateSelf(accountSimplified: AccountSimplified, organization: Organization): Observable<AccountSimplified> {
    return this.updateByOa(accountSimplified, organization);
  }

  create(accountSimplified: AccountSimplified, organization: Organization): Observable<AccountSimplified> {
    return this._http.post<AccountFromServer>(this._accountApiUrl, accountSimplified.toAccountFromServer())
      .pipe(
        map(res => Utilities.transformToClassInstance(AccountFromServer, res).toAccount(organization)),
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  lockReadWrite(accountSimplified: AccountSimplified, organization: Organization): Observable<AccountSimplified> {
    return this._http.patch<AccountFromServer>(`${this._accountApiUrl}/${accountSimplified._id}/lock_read_write`, accountSimplified.toAccountFromServer())
      .pipe(
        map(res => Utilities.transformToClassInstance(AccountFromServer, res).toAccount(organization)),
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  lockReadOnly(accountSimplified: AccountSimplified, organization: Organization): Observable<AccountSimplified> {
    return this._http.patch<AccountFromServer>(`${this._accountApiUrl}/${accountSimplified._id}/lock_read_only`, accountSimplified.toAccountFromServer())
      .pipe(
        map(res => Utilities.transformToClassInstance(AccountFromServer, res).toAccount(organization)),
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  uploadAttachment(accountSimplified: AccountSimplified, attachmentFormData: FormData): Observable<Attachment> {
    return this._http.post<Attachment>(`${this._accountApiUrl}/${accountSimplified._id}/attachment`, attachmentFormData)
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  removeAttachment(accountSimplified: AccountSimplified, attachment: Attachment): Observable<{}> {
    return this._http.delete<{}>(`${this._accountApiUrl}/${accountSimplified._id}/attachment/${attachment.id}`)
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  downloadAttachment(accountSimplified: AccountSimplified, attachment: Attachment): Observable<Blob> {
    return this._http.get<Blob>(`${this._accountApiUrl}/${accountSimplified._id}/attachment/${attachment.id}`, {
      responseType: 'blob' as 'json'
    })
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }
}
