import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { Account } from 'src/app/models/account';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppData } from 'src/app/singletons/app-data';
import { Constants } from 'src/app/utilities/constants';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.css']
})
export class AppMenuComponent implements OnInit, OnDestroy {

  @ViewChild('template', { static: true }) template!: never;

  public account!: Account;

  private _activeRoleSubscription$!: Subscription;
  public activeRole!: string;

  public utilities = Utilities;
  public constants = Constants;

  constructor(
    private _appData: AppData,
    private _router: Router,
    public app: AppComponent,
    private _viewContainerRef: ViewContainerRef,
    private _translate: TranslateService,
    private _authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this._viewContainerRef.createEmbeddedView(this.template);

    if (!this._appData.accountValue) {
      return;
    }

    this._activeRoleSubscription$ = this._appData.activeRole.subscribe(activeRole => {
      if (!activeRole) {
        return;
      }

      this.activeRole = activeRole;
    });

    this.account = this._appData.accountValue;
  }

  ngOnDestroy(): void {
    this._activeRoleSubscription$.unsubscribe();
  }

  displayChangeToRole(role: string): boolean {
    return this.account.roles.indexOf(role) !== -1 && this.activeRole !== role;
  }

  changeRole(newRole: string): void {
    const indexOfNewRole = this.account.roles.indexOf(newRole);
    if (indexOfNewRole === -1) {
      return;
    }

    this._router.navigate([newRole === 'oa' ? '/organization-administration' : '/overview/reports'], { replaceUrl: true });
    this._appData.setActiveRole(newRole);
    this._translate.get('APP_MENU.N_ROLE_CHANGED').subscribe((res: string) => {
      this.app.buildNotification(res);
    }).unsubscribe();
  }

  showHelp(): void {
    this.app.showHelp();
  }

  logout(): void {
    this._authenticationService
      .logout()
      .subscribe(() => {
        this._appData.wipe();
        this._router.navigate(['/'], { replaceUrl: true });
        this._translate.get('GENERAL.N_SUCCESSFUL_LOGOUT').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
        this.app.hideLoading();
      });
  }

}
