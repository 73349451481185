export class Constants {
  static maxSizeOfInputFile = 10 * 1024 * 1024; // 10 MB max file(s) size
  static maxTotalSizeOfInputFiles = Constants.maxSizeOfInputFile;

  static tillDaysDeadlineWarning = 3;

  static extendTokenIntervalInSeconds = 3000; // 50 minutes

  static listOfRoles = ['cp', 'oe', 'oa'];

  static stateApiMap = new Map<string, string>(
    [
      ['ExecutionState', 'execution_state'],
      ['GT_NoticeReportState', 'gt_notice_report_state'],
      ['GT_NotifyObligedEntityState', 'gt_notify_obliged_entity_state'],
      ['GT_ObligedEntityUnderstoodState', 'gt_obliged_entity_understood_state'],
      ['JustifyReportNotJustState', 'justify_report_not_just_state'],
      ['NotifyCompetentPersonAboutAcceptedMeasuresState', 'notify_competent_person_about_accepted_measures_state'],
      ['NotifyCompetentPersonAboutExecutionState', 'notify_competent_person_about_execution_state'],
      ['NotifyWhistleblowerAboutAcceptedMeasuresState', 'notify_whistleblower_about_accepted_measures_state'],
      ['NotifyWhistleblowerAboutExecutionState', 'notify_whistleblower_about_execution_state'],
      ['NotifyWhistleblowerAboutReportEvaluationState', 'notify_whistleblower_about_report_evaluation_state'],
      ['NotifyWhistleblowerAboutReportNoticingState', 'notify_whistleblower_about_report_noticing_state'],
      ['ObligedEntityUnderstoodReportState', 'obliged_entity_understood_report_state'],
      ['ReportEvaluationState', 'report_evaluation_state'],
      ['SuggestionByObligedEntityState', 'suggestion_by_obliged_entity_state'],
      ['SuggestionForObligedEntityState', 'suggestion_for_obliged_entity_state'],
      ['UnderstoodAcceptedMeasuresState', 'understood_accepted_measures_state'],
      ['UnderstoodExecutionState', 'understood_execution_state'],
    ]
  );

  /**
   * Used for advanced filter in reports overview.
   * Map of role-maps, where maps is map of categoryType-stateLabels
   */
  static stateLabelsForRolesMap = new Map<string, Map<string, string[]>>(
    [
      [
        'cp',
        new Map<string, string[]>(
          [
            [
              'serious', [
                'NOTIFY_WB_REPORT_NOTICING',
                'REPORT_EVALUATION',
                'REPORT_EVALUATION_EXTEND',
                'JUSTIFY_NOT_JUST',
                'NOTIFY_WB_EVALUATION',
                'SUGGESTION_FOR_OE',
                'WAITING_FOR_EVALUATION',
                'UNDERSTOOD_ACCEPTED_MEASURES',
                'NOTIFY_WB_ACCEPTED_MEASURES',
                'WAITING_FOR_EXECUTION',
                'UNDERSTOOD_EXECUTION',
                'NOTIFY_WB_EXECUTION',
                'ARCHIVED',
              ],
            ],
            [
              'general', [
                'GT_NOTICE_REPORT',
                'GT_NOTIFY_OE',
                'GT_WAITING_FOR_OE',
                'GT_OE_UNDERSTOOD',
                'ARCHIVED',
              ],
            ],
          ],
        ),
      ],
      [
        'oe',
        new Map<string, string[]>(
          [
            [
              'serious', [
                'OE_UNDERSTOOD_REPORT',
                'SUGGESTION_BY_OE',
                'NOTIFY_CP_ACCEPTED_MEASURES',
                'EXECUTION',
                'NOTIFY_CP_EXECUTION',
                'ARCHIVED',
              ],
            ],
            [
              'general', [
                'GT_NOTICE_REPORT',
                'ARCHIVED',
              ],
            ],
          ],
        ),
      ]
    ]
  );
}