import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from './material/material.module';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppData } from './singletons/app-data';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { HttpHeaderInterceptor } from './interceptors/http-header.interceptor';
import { LoginComponent } from './components/auth/login/login.component';
import { ReportComponent } from './components/report/report/report.component';
import { AuthenticationService } from './services/authentication.service';
import { InsertedComponent } from './components/report/inserted/inserted.component';
import { ReportsComponent } from './components/overview/reports/reports.component';
import { DetailComponent } from './components/overview/detail/detail.component';
import { getCzPaginatorIntl } from './material/cz-paginator-intl';
import { StatesOverviewComponent } from './components/overview/detail/states-overview/states-overview.component';
import { PortalModule } from '@angular/cdk/portal';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QuillModule } from 'ngx-quill';
import { NotifyWhistleblowerAboutReportNoticingStateComponent } from './components/overview/detail/notify-whistleblower-about-report-noticing-state/notify-whistleblower-about-report-noticing-state.component';
import { ReportEvaluationStateComponent } from './components/overview/detail/report-evaluation-state/report-evaluation-state.component';
import { ConfirmDialogComponent } from './components/widgets/confirm-dialog/confirm-dialog.component';
import { NotifyAboutComponent } from './components/overview/detail/_notify-about/notify-about.component';
import { ReportService } from './services/report.service';
import { ReportStateService } from './services/report-state.service';
import { JustifyReportNotJustStateComponent } from './components/overview/detail/justify-report-not-just-state/justify-report-not-just-state.component';
import { NotifyWhistleblowerAboutReportEvaluationStateComponent } from './components/overview/detail/notify-whistleblower-about-report-evaluation-state/notify-whistleblower-about-report-evaluation-state.component';
import { SuggestionForObligedEntityStateComponent } from './components/overview/detail/suggestion-for-obliged-entity-state/suggestion-for-obliged-entity-state.component';
import { NotifyWhistleblowerAboutAcceptedMeasuresStateComponent } from './components/overview/detail/notify-whistleblower-about-accepted-measures-state/notify-whistleblower-about-accepted-measures-state.component';
import { SuggestionByObligedEntityStateComponent } from './components/overview/detail/suggestion-by-obliged-entity-state/suggestion-by-obliged-entity-state.component';
import { NotifyCompetentPersonAboutAcceptedMeasuresStateComponent } from './components/overview/detail/notify-competent-person-about-accepted-measures-state/notify-competent-person-about-accepted-measures-state.component';
import { AppMenuComponent } from './components/app-menu/app-menu.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { AccountComponent } from './components/account/account.component';
import { AccountService } from './services/account.service';
import { NoteDialogComponent } from './components/widgets/note-dialog/note-dialog.component';
import { AppPageComponent } from './components/app-page/app-page.component';
import { OrganizationService } from './services/organization.service';
import { OkDialogComponent } from './components/widgets/ok-dialog/ok-dialog.component';
import { UnderstoodAcceptedMeasuresStateComponent } from './components/overview/detail/understood-accepted-measures-state/understood-accepted-measures-state.component';
import { ArchiveComponent } from './components/overview/detail/states-overview/archive/archive.component';
import { AccountDialogComponent } from './components/widgets/account-dialog/account-dialog.component';
import { UnderstoodExecutionStateComponent } from './components/overview/detail/understood-execution-state/understood-execution-state.component';
import { NotifyWhistleblowerAboutExecutionStateComponent } from './components/overview/detail/notify-whistleblower-about-execution-state/notify-whistleblower-about-execution-state.component';
import { ObligedEntityUnderstoodReportStateComponent } from './components/overview/detail/obliged-entity-understood-report-state/obliged-entity-understood-report-state.component';
import { ExecutionStateComponent } from './components/overview/detail/execution-state/execution-state.component';
import { NotifyCompetentPersonAboutExecutionStateComponent } from './components/overview/detail/notify-competent-person-about-execution-state/notify-competent-person-about-execution-state.component';
import { ResetPasswordRequestComponent } from './components/auth/reset-password/request/request.component';
import { ResetPasswordDoneComponent } from './components/auth/reset-password/done/done.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { ChooseOrganizationDialogComponent } from './components/widgets/choose-organization-dialog/choose-organization-dialog.component';
import { PreregisterComponent } from './components/auth/preregister/preregister.component';
import { ChatComponent } from './components/report/chat/chat.component';
import { ChatRequestComponent } from './components/report/chat-request/chat-request.component';
import { MessageService } from './services/message.service';
import { OrganizationAdministrationComponent } from './components/organization-administration/organization-administration.component';
import { AccountsOverviewComponent } from './components/organization-administration/accounts-overview/accounts-overview.component';
import { FilesComponent } from './components/organization-administration/files/files.component';
import { AssignCategoriesComponent } from './components/widgets/assign-categories/assign-categories.component';
import { ChooseCategoryComponent } from './components/report/choose-category/choose-category.component';
import { GtNoticeReportStateComponent } from './components/overview/detail/gt-notice-report-state/gt-notice-report-state.component';
import { GtNotifyObligedEntityStateComponent } from './components/overview/detail/gt-notify-obliged-entity-state/gt-notify-obliged-entity-state.component';
import { GtObligedEntityUnderstoodStateComponent } from './components/overview/detail/gt-obliged-entity-understood-state/gt-obliged-entity-understood-state.component';
import { ChangeCategoryDialogComponent } from './components/widgets/change-category-dialog/change-category-dialog.component';
import { MissingOrganizationComponent } from './components/report/missing-organization/missing-organization.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChooseOrganizationDialogComponent,
    ReportComponent,
    InsertedComponent,
    ReportsComponent,
    DetailComponent,
    StatesOverviewComponent,
    NotifyWhistleblowerAboutReportNoticingStateComponent,
    ReportEvaluationStateComponent,
    ConfirmDialogComponent,
    OkDialogComponent,
    NotifyAboutComponent,
    JustifyReportNotJustStateComponent,
    NotifyWhistleblowerAboutReportEvaluationStateComponent,
    SuggestionForObligedEntityStateComponent,
    NotifyWhistleblowerAboutAcceptedMeasuresStateComponent,
    SuggestionByObligedEntityStateComponent,
    NotifyCompetentPersonAboutAcceptedMeasuresStateComponent,
    AppMenuComponent,
    ChangePasswordComponent,
    AccountComponent,
    NoteDialogComponent,
    AppPageComponent,
    UnderstoodAcceptedMeasuresStateComponent,
    ArchiveComponent,
    AccountsOverviewComponent,
    AccountDialogComponent,
    UnderstoodExecutionStateComponent,
    NotifyWhistleblowerAboutExecutionStateComponent,
    ObligedEntityUnderstoodReportStateComponent,
    ExecutionStateComponent,
    NotifyCompetentPersonAboutExecutionStateComponent,
    ResetPasswordComponent,
    ResetPasswordRequestComponent,
    ResetPasswordDoneComponent,
    PreregisterComponent,
    ChatComponent,
    ChatRequestComponent,
    OrganizationAdministrationComponent,
    FilesComponent,
    AssignCategoriesComponent,
    ChooseCategoryComponent,
    GtNoticeReportStateComponent,
    GtNotifyObligedEntityStateComponent,
    GtObligedEntityUnderstoodStateComponent,
    ChangeCategoryDialogComponent,
    MissingOrganizationComponent,
  ],
  imports: [
    BrowserModule,
    PortalModule,
    AppRoutingModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'cz',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'size': ['small', false, 'large'] }],
        ]
      }
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: MatPaginatorIntl, useValue: getCzPaginatorIntl() },
    AppData,
    AuthenticationService,
    AccountService,
    OrganizationService,
    ReportService,
    ReportStateService,
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
