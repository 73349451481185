import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public resetPasswordToken = '';

  public newPassword = '';
  public newPasswordAgain = '';

  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router,
    public app: AppComponent,
    private _translate: TranslateService,
  ) { }

  ngOnInit(): void {
    const urlSplitted = this._router.url.split('/');

    // firstly, try to grab identificationCode from url
    if (urlSplitted.length === 3 && urlSplitted[urlSplitted.length - 1].length === 24) {
      // code from url should have 16 characters
      this.resetPasswordToken = urlSplitted[urlSplitted.length - 1];
    }

    // should be filled
    if (this.resetPasswordToken === '') {
      this._router.navigate(['/'], { replaceUrl: true });
    }
  }

  public resetPassword(): void {
    if (!this.newPassword
      || !/(?=.*\d)(?=.*[a-z,A-Z]).{8,}/.test(this.newPassword)) {
      this._translate.get('AUTH.CHANGE_PASSWORD.N_FILL_NEW_PASSWORD').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    if (!this.newPasswordAgain
      || !/(?=.*\d)(?=.*[a-z,A-Z]).{8,}/.test(this.newPasswordAgain)) {
      this._translate.get('AUTH.CHANGE_PASSWORD.N_FILL_NEW_PASSWORD_AGAIN').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    if (this.newPassword !== this.newPasswordAgain) {
      this._translate.get('AUTH.CHANGE_PASSWORD.N_PASSWORDS_SAME').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    this.app.showLoading();
    this._authenticationService
      .resetPassword(this.resetPasswordToken, this.newPassword, this.newPasswordAgain)
      .subscribe(() => {
        this._translate.get('AUTH.RESET_PASSWORD.N_CHANGE_SUCCESSFUL').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
        this._router.navigate(['login'], { replaceUrl: true });
        this.app.hideLoading();
      }, (errorStatus) => {
        if (errorStatus < 500) {
          this._translate.get('GENERAL.N_BAD_REQUEST').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
        } else {
          this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
        }
        this.app.hideLoading();
      });
  }
}
