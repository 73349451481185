<div class="content-container">
  <div class="content-centered">
    <h1 class="custom-h1" translate>AUTH.LOGIN.HEADING</h1>
    <div class="visual-container">
      <form>
        <label for="email" class="label-primary" translate>AUTH.LOGIN.EMAIL_LABEL</label>
        <input id="email" name="email" type="email" class="input-primary"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" [placeholder]="emailPlaceholder"
          [(ngModel)]="accountAuthentication.email" required #email="ngModel" />
        <!-- password at least 8 characters, at least one number -->
        <label for="password" class="label-primary" translate>AUTH.LOGIN.PASSWORD_LABEL</label>
        <div class="element-hint-below">
          <input id="password" name="password" type="password" class="input-primary"
            [(ngModel)]="accountAuthentication.password" required #password="ngModel"
            pattern="(?=.*\d)(?=.*[a-z,A-Z]).{8,}">
          <div class="hint-multi">
            <span translate>AUTH.LOGIN.PASSWORD_HINT</span>
            <span *ngIf="accountAuthentication.password.length < 8">{{ accountAuthentication.password.length }}/8</span>
            <span *ngIf="accountAuthentication.password.length >= 8">{{ accountAuthentication.password.length }}</span>
          </div>
        </div>
        <mat-checkbox id="remember-credentials" name="remember-credentials" type="checkbox" color="primary"
          [(ngModel)]="rememberCredentials" (ngModelChange)="changeRememberCredentials()">
          {{ 'AUTH.LOGIN.REMEMBER_CREDENTIALS' | translate }}
        </mat-checkbox>
        <button type="submit" class="button-primary button-confirm" (click)="authenticate()" translate>
          AUTH.LOGIN.CONFIRM<mat-icon fontSet="material-icons-outlined">login</mat-icon>
        </button>
        <a id="reset-password-a" [routerLink]="['/reset-password/request']" class="a-primary inline-button" translate>
          AUTH.LOGIN.RESET_PASSWORD<mat-icon fontSet="material-icons-outlined">lock_open</mat-icon>
        </a>
      </form>
    </div>
  </div>
</div>