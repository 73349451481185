import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppData } from '../singletons/app-data';

@Injectable({ providedIn: 'root' })
export class ReportChatGuard  {
  constructor(
    private router: Router,
    private _appData: AppData
  ) { }

  canActivate(): boolean {
    if (this._appData.reportForWhistleblower && this._appData.reportIdentificationCode) {
      return true;
    }
    this.router.navigate(['/'], { replaceUrl: true });
    return false;
  }
}