/* eslint-disable @typescript-eslint/ban-types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Message } from '../models/message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private _messageApiUrl = 'api/report/messages';

  constructor(
    private _http: HttpClient,
  ) { }

  /**
   * If identificationCode provided, then message is sent as wb, otherwise, auth cookie has to be set.
   * @param text 
   * @param textLength 
   * @param identificationCode 
   * @returns 
   */
  sendTextMessage(reportId: string, text: string, identificationCode: string | undefined, sentBy: string, sentTo: string): Observable<Message> {
    return this._http.post<Message>(`${this._messageApiUrl}/send/${reportId}`,
      identificationCode
        ? { text: text, identificationCode: identificationCode, sentBy: 'wb', sentTo: 'cp' }
        : { text: text, sentBy: sentBy, sentTo: sentTo },
    ).pipe(
      catchError((error) => {
        console.error(error);
        // rethrow status back to the component
        return throwError(error);
      })
    );
  }

  sendAttachmentMessage(reportId: string, attachmentFormData: FormData, identificationCode: string | undefined, sentBy: string, sentTo: string): Observable<Message> {
    if (identificationCode) {
      attachmentFormData.set('identificationCode', identificationCode);
      attachmentFormData.set('sentBy', 'wb');
      attachmentFormData.set('sentTo', 'cp');
    } else {
      attachmentFormData.set('sentBy', sentBy);
      attachmentFormData.set('sentTo', sentTo);
    }
    return this._http.post<Message>(`${this._messageApiUrl}/send/${reportId}`,
      attachmentFormData
    ).pipe(
      catchError((error) => {
        console.error(error);
        // rethrow status back to the component
        return throwError(error);
      })
    );
  }

  getAll(reportId: string, identificationCode: string | undefined, sentBy: string): Observable<Message[]> {
    return this._http.post<Message[]>(`${this._messageApiUrl}/${reportId}`,
      identificationCode
        ? { identificationCode: identificationCode }
        : { sentBy: sentBy }
    ).pipe(
      catchError((error) => {
        console.error(error);
        // rethrow status back to the component
        return throwError(error);
      })
    );
  }

  downloadAttachment(messageId: string, attachmentId: string, identificationCode: string | undefined, sentBy: string): Observable<Blob> {
    return this._http.post<Blob>(`${this._messageApiUrl}/${messageId}/attachment/${attachmentId}`,
      identificationCode
        ? { identificationCode: identificationCode }
        : { sentBy: sentBy },
      {
        responseType: 'blob' as 'json'
      }
    ).pipe(
      catchError((error) => {
        console.error(error);
        // rethrow status back to the component
        return throwError(error);
      })
    );
  }
}
