import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { Organization } from 'src/app/models/organization';
import { Attachment, Report } from 'src/app/models/report';
import { NotifyAbout } from 'src/app/models/report-state';
import { ReportStateService } from 'src/app/services/report-state.service';
import { ReportService } from 'src/app/services/report.service';
import { AppData } from 'src/app/singletons/app-data';
import { Constants } from 'src/app/utilities/constants';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'state-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.css']
})
export class ArchiveComponent implements OnInit {

  utilities = Utilities;

  @ViewChild('template', { static: true }) template!: TemplateRef<unknown>;

  @Input() report!: Report;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() state!: any;

  public organization!: Organization;

  public notifyAbout!: NotifyAbout;
  public notifyAboutTranslateId = '';

  public activeRole: string | null = null;

  constructor(
    private viewContainerRef: ViewContainerRef,
    public app: AppComponent,
    private _translate: TranslateService,
    private _reportService: ReportService,
    private _reportStateService: ReportStateService,
    private _appData: AppData,
  ) { }

  ngOnInit(): void {
    //this and ViewChild (and ng-template in html file) are here to create embedded view (without additional html tag)
    this.viewContainerRef.createEmbeddedView(this.template);

    this.organization = this._appData.organizationValue;

    // this.state = Utilities.transformToSpecificClassInstance(ReportState, this.state);

    if (this.state.id === 'NotifyWhistleblowerAboutReportNoticingState') {
      this.notifyAbout = this.state.notifyAboutReportNoticing;
      this.notifyAboutTranslateId = 'NOTIFY_WB_REPORT_NOTICING';
    } else if (this.state.id === 'NotifyWhistleblowerAboutReportEvaluationState') {
      this.notifyAbout = this.state.notifyAboutReportEvaluation;
      this.notifyAboutTranslateId = 'NOTIFY_WB_EVALUATION';
    } else if (this.state.id === 'NotifyWhistleblowerAboutAcceptedMeasuresState') {
      this.notifyAbout = this.state.notifyAboutAcceptedMeasures;
      this.notifyAboutTranslateId = 'NOTIFY_WB_ACCEPTED';
    } else if (this.state.id === 'NotifyWhistleblowerAboutExecutionState') {
      this.notifyAbout = this.state.notifyAboutExecution;
      this.notifyAboutTranslateId = 'NOTIFY_WB_EXECUTION';
    }
    
    this.activeRole = this._appData.activeRoleValue;
  }

  public downloadAttachment(attachment: Attachment, suggestionForOeState = false): void {
    this.app.showLoading();

    if (suggestionForOeState) {
      // although this is called by cp, disguise as a 'oe' to download correct attachment (report.attachmentByCompetentPerson)
      this._reportService.downloadAttachment(this.report._id, attachment.id, 'oe').subscribe((res) => {
        if (res) {
          Utilities.downloadFile(res, attachment.name);
          this._translate.get('GENERAL.N_FILE_DOWNLOADED').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
        }
        this.app.hideLoading();
      }, () => {
        this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
        this.app.hideLoading();
      });
    } else {
      // otherwise it should be specific state's attachment
      this._reportStateService.downloadAttachment(Constants.stateApiMap.get(this.state.id) ?? '_', this.report._id, attachment.id).subscribe((res) => {
        if (res) {
          Utilities.downloadFile(res, attachment.name);
          this._translate.get('GENERAL.N_FILE_DOWNLOADED').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
        }
        this.app.hideLoading();
      }, () => {
        this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
        this.app.hideLoading();
      });
    }
  }
}
