<div class="content-container">
  <div class="content-centered">
    <h1 class="custom-h1" translate>REPORT.CHOOSE_CATEGORY.HEADING</h1>
    <div class="visual-container">
      <a *ngFor="let category of organization.categories" [routerLink]="['/report']"
        [queryParams]="{categoryId: category.id}" class="category-card a-btn" translate>
        <h2 class="category-name">{{ category.name }}</h2>
        <span class="category-description">{{ category.description }}</span>
      </a>

      <p class="categories-hint" [innerHTML]="'REPORT.CHOOSE_CATEGORY.HINT' | translate"></p>
    </div>
  </div>
</div>