<div class="content-container">
  <div class="content-centered">
    <h1 class="custom-h1" translate>REPORT.CHAT.HEADING</h1>

    <div *ngIf="report" id="report-container">
      <!-- Report 'cp' -->
      <section id="report-detail">
        <h2 class="custom-h2" translate>REPORT.CHAT.DETAIL_HEADING</h2>
        <dl>
          <ng-container *ngIf="reportCategory && organization.categories.length > 1">
            <dt translate>OVERVIEW.DETAIL.CATEGORY_NAME</dt>
            <dd>{{ reportCategory.name }}</dd>
          </ng-container>
          <dt translate>REPORT.CHAT.REPORTED_AT_LABEL</dt>
          <dd>{{ utilities.dateFormat(report.detail.reportedAt) }}</dd>
          <ng-container *ngIf="report.detail.whistleblowerDetail.name">
            <dt translate>OVERVIEW.DETAIL.WB_NAME_LABEL</dt>
            <dd>{{ report.detail.whistleblowerDetail.name }}</dd>
          </ng-container>
          <ng-container *ngIf="report.detail.whistleblowerDetail.contactAddress">
            <dt translate>OVERVIEW.DETAIL.CONTACT_ADDRESS_LABEL</dt>
            <dd>{{ report.detail.whistleblowerDetail.contactAddress }}</dd>
          </ng-container>
          <ng-container *ngIf="report.detail.whistleblowerDetail.dateOfBirth">
            <dt translate>OVERVIEW.DETAIL.DATE_OF_BIRTH_LABEL</dt>
            <dd>{{ utilities.dateFormat(report.detail.whistleblowerDetail.dateOfBirth) }}</dd>
          </ng-container>
        </dl>

        <section id="report-text">
          <div *ngIf="report.detail.subject && report.detail.subject !== ''">
            <span><strong translate>OVERVIEW.DETAIL.SUBJECT_LABEL</strong></span>
            <p>{{ report.detail.subject }}</p>
          </div>
          <div>
            <span><strong translate>OVERVIEW.DETAIL.TEXT_LABEL</strong></span>
            <p>{{ report.detail.body }}</p>
          </div>
          <div *ngIf="report.detail.attachments && report.detail.attachments.length > 0" class="file-list-container">
            <span class="file-list-header"><strong translate>OVERVIEW.DETAIL.UPLOADED_ATTACHMENTS</strong></span>
            <button *ngFor="let attachment of report.detail.attachments" type="button" class="inline-button"
              (click)="downloadAttachment(attachment)">
              <mat-icon fontSet="material-icons-outlined">
                file_download</mat-icon>
              {{ attachment.name }}
            </button>
          </div>
        </section>
      </section>

      <section id="report-chat">
        <h2 class="custom-h2" translate>REPORT.CHAT.CHAT_HEADING</h2>
        <section class="messages-container" #messagescontainer>
          <div *ngIf="messages.length === 0" class="messages-empty-notice" translate>REPORT.CHAT.NO_MESSAGES</div>
          <div *ngFor="let message of messages" class="message-wrapper"
            [class]="message.sentBy === 'wb' ? 'message-wrapper-my' : 'message-wrapper-other'">
            <!-- TEXT MESSAGE -->
            <ng-container *ngIf="message.text">
              <span class="message-info">{{ 'REPORT.CHAT.TEXT_INFO_SENT_BY' | translate:{ date:
                utilities.dateFormat(message.timestamp) } }}</span>
              <div class="message-skeleton">
                <quill-view-html [content]="message.text" theme="snow">
                </quill-view-html>
              </div>
            </ng-container>
            <!-- ATTACHMENT MESSAGE -->
            <ng-container *ngIf="message.attachment">
              <span class="message-info">{{ 'REPORT.CHAT.ATTACHMENT_INFO_SENT_BY' | translate:{ date:
                utilities.dateFormat(message.timestamp) } }}</span>
              <div class="message-skeleton">
                <div class="file-list-container">
                  <button type="button" class="inline-button" (click)="downloadMessageAttachment(message)">
                    {{ message.attachment.name }}
                    <mat-icon fontSet="material-icons-outlined">
                      file_download</mat-icon>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </section>

        <section class="shrinked shrinked-more-width">
          <label class="label-primary" translate>REPORT.CHAT.NEW_MESSAGE</label>
          <div class="element-hint-below">
            <quill-editor class="editor" [(ngModel)]="newMessageTextHtml" placeholder=""
              [styles]="{'min-height': '7.5rem'}" [maxLength]="100000" [required]="true" [sanitize]="true"
              (onContentChanged)="newMessageTextChanged($event)"></quill-editor>
            <div class="hint-multi">
              <span></span>
              <span *ngIf="newMessageTextLength > 85000">{{ newMessageTextLength }} / 100 000</span>
            </div>
          </div>

          <button id="send-text-message-button" type="button" class="button-primary button-confirm"
            (click)="sendTextMessage()" translate>
            REPORT.CHAT.SEND
          </button>

          <div class="file-input-container">
            <input type="file" class="file-input" (change)="onFileSelected($event)" #fileupload>
            <button type="button" class="inline-button" (click)="fileupload.click()" translate>
              REPORT.CHAT.SEND_ATTACHMENT<mat-icon fontSet="material-icons-outlined">
                attach_file</mat-icon>
            </button>
          </div>
        </section>
      </section>
    </div>
  </div>
</div>