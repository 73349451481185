<ol class="stepper">
  <li class="step step-active step-no-action">
    <div class="step-number">{{ state.number }}</div>
    <div class="step-label">{{ 'OVERVIEW.DETAIL.STATE_LABELS.' + reportStateHelper.getStateLabelTranslateId(state, true) | translate:{ tillDays: reportStateHelper.getStateTillDays(report) } }}
    </div>
  </li>
</ol>

<button type="button" class="inline-button go-back-in-states-button" (click)="goBack()" translate>
  <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>GENERAL.STEP_BACK
</button>

<section class="shrinked shrinked-more-width">
  <section id="text-from-oe-container">
    <h3 class="custom-h3" translate>OVERVIEW.DETAIL.UNDERSTOOD_ACCEPTED_MEASURES.MESSAGE_FROM_OE</h3>
    <quill-view-html [content]="state.textFromObligedEntityHtml" theme="snow"></quill-view-html>
    <div *ngIf="state.attachment" class="file-list-container">
      <span class="file-list-header"><strong translate>OVERVIEW.DETAIL.UPLOADED_ATTACHMENTS</strong></span>
      <button type="button" class="inline-button" (click)="downloadAttachment(state.attachment)">
        <mat-icon fontSet="material-icons-outlined">
          file_download</mat-icon>
        {{ state.attachment.name }}
      </button>
    </div>                                                        

    <button type="button" class="button-primary button-confirm" (click)="understood()" translate>
      OVERVIEW.DETAIL.UNDERSTOOD_ACCEPTED_MEASURES.UNDERSTOOD
    </button>
  </section>
</section>