<div class="content-container">
  <div class="content-centered">
    <h1 class="custom-h1" translate>ORGANIZATION_ADMINISTRATION.HEADING</h1>

    <div class="visual-container">
      <form>
        <p translate>ORGANIZATION_ADMINISTRATION.HELP_TEXT</p>
        <a *ngIf="utilities.canAccountEdit(account)" [routerLink]="['/organization-administration/accounts-overview']"
          class="a-btn" translate>
          ORGANIZATION_ADMINISTRATION.GO_TO_ACCOUNTS_OVERVIEW<mat-icon fontSet="material-icons-outlined">group
          </mat-icon>
        </a>
        <a [routerLink]="['/organization-administration/files']" class="a-btn" translate>
          ORGANIZATION_ADMINISTRATION.GO_TO_FILES<mat-icon fontSet="material-icons-outlined">file_download
          </mat-icon>
        </a>
      </form>
    </div>
  </div>
</div>