<mat-dialog-content>
  <label for="choose-category-select" class="label-primary"
    translate>WIDGETS.CHANGE_CATEGORY_DIALOG.SELECT_LABEL</label>
  <div class="element-hint-below">
    <select id="choose-category-select" name="choose-category-select" class="select-primary"
      [(ngModel)]="data.chosenCategory">
      <option *ngFor="let category of data.categories" [ngValue]="category">{{ category.name }}
      </option>
    </select>
    <div class="hint-single">
      <span translate>WIDGETS.CHANGE_CATEGORY_DIALOG.SELECT_HINT</span>
    </div>
  </div>
</mat-dialog-content>

<div class="dialog-actions">
  <button type="button" class="button-primary" (click)="dialogRef.close(false)" translate>GENERAL.CANCEL</button>
  <button type="button" class="button-primary button-confirm" (click)="dialogRef.close(data.chosenCategory)"
    [disabled]="data.chosenCategory.id === ''" translate>WIDGETS.CHANGE_CATEGORY_DIALOG.CHANGE</button>
</div>