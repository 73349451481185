import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { AccountService } from 'src/app/services/account.service';
import { AppData } from 'src/app/singletons/app-data';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  public oldPassword = '';
  public newPassword = '';
  public newPasswordAgain = '';

  constructor(
    private _appData: AppData,
    private _accountService: AccountService,
    private _router: Router,
    public app: AppComponent,
    private _translate: TranslateService,
  ) { }

  ngOnInit(): void {
    //
  }

  public changePassword(): void {
    if (!this.oldPassword
      || !/(?=.*\d)(?=.*[a-z,A-Z]).{8,}/.test(this.oldPassword)) {
      this._translate.get('AUTH.CHANGE_PASSWORD.N_FILL_OLD_PASSWORD').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    if (!this.newPassword
      || !/(?=.*\d)(?=.*[a-z,A-Z]).{8,}/.test(this.newPassword)) {
      this._translate.get('AUTH.CHANGE_PASSWORD.N_FILL_NEW_PASSWORD').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    if (!this.newPasswordAgain
      || !/(?=.*\d)(?=.*[a-z,A-Z]).{8,}/.test(this.newPasswordAgain)) {
      this._translate.get('AUTH.CHANGE_PASSWORD.N_FILL_NEW_PASSWORD_AGAIN').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    if (this.newPassword !== this.newPasswordAgain) {
      this._translate.get('AUTH.CHANGE_PASSWORD.N_PASSWORDS_SAME').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    this.app.showLoading();
    this._accountService
      .changePassword(this._appData.accountValue?._id ?? '', this.oldPassword, this.newPassword, this.newPasswordAgain)
      .subscribe(() => {
        this._translate.get('AUTH.CHANGE_PASSWORD.N_CHANGE_SUCCESSFUL').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
        this._router.navigate(['account']);
        this.app.hideLoading();
      }, (errorStatus) => {
        if (errorStatus < 500) {
          this._translate.get('GENERAL.N_BAD_REQUEST').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
        } else {
          this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
        }
        this.app.hideLoading();
      });
  }

}
