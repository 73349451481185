import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './components/account/account.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { LoginComponent } from './components/auth/login/login.component';
import { AppPageComponent } from './components/app-page/app-page.component';
import { DetailComponent } from './components/overview/detail/detail.component';
import { ReportsComponent } from './components/overview/reports/reports.component';
import { InsertedComponent } from './components/report/inserted/inserted.component';
import { ReportComponent } from './components/report/report/report.component';
import { ActiveReportGuard } from './guards/active-report.guard';
import { AuthGuard } from './guards/auth.guard';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { ResetPasswordRequestComponent } from './components/auth/reset-password/request/request.component';
import { ResetPasswordDoneComponent } from './components/auth/reset-password/done/done.component';
import { RoleOaGuard } from './guards/role-oa.guard';
import { PreregisterComponent } from './components/auth/preregister/preregister.component';
import { ChatRequestComponent } from './components/report/chat-request/chat-request.component';
import { ChatComponent } from './components/report/chat/chat.component';
import { ReportChatGuard } from './guards/report-chat.guard';
import { AccountsOverviewComponent } from './components/organization-administration/accounts-overview/accounts-overview.component';
import { OrganizationAdministrationComponent } from './components/organization-administration/organization-administration.component';
import { RoleCpOrOeGuard } from './guards/role-cp-or-oe.guard';
import { FilesComponent } from './components/organization-administration/files/files.component';
import { ChooseCategoryComponent } from './components/report/choose-category/choose-category.component';
import { OrganizationSetGuard } from './guards/organization-set.guard';
import { MissingOrganizationComponent } from './components/report/missing-organization/missing-organization.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'reset-password/request', component: ResetPasswordRequestComponent },
  { path: 'reset-password/done', component: ResetPasswordDoneComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'preregister/:token', component: PreregisterComponent },
  { path: 'report/chat-request', component: ChatRequestComponent },
  { path: 'report/chat', component: ChatComponent, canActivate: [ReportChatGuard] },
  { path: 'report/choose-category', component: ChooseCategoryComponent, canActivate: [OrganizationSetGuard] },
  { path: 'report/missing-organization', component: MissingOrganizationComponent },
  { path: 'report', component: ReportComponent, canActivate: [OrganizationSetGuard] },
  { path: 'inserted', component: InsertedComponent },

  { path: 'organization-administration/accounts-overview', component: AccountsOverviewComponent, canActivate: [AuthGuard, RoleOaGuard] },
  { path: 'organization-administration/files', component: FilesComponent, canActivate: [AuthGuard, RoleOaGuard] },
  { path: 'organization-administration', component: OrganizationAdministrationComponent, canActivate: [AuthGuard, RoleOaGuard] },

  { path: 'account/change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },

  { path: 'overview/reports', component: ReportsComponent, canActivate: [AuthGuard, RoleCpOrOeGuard] },
  { path: 'overview/detail/:number', component: DetailComponent, canActivate: [AuthGuard, RoleCpOrOeGuard, ActiveReportGuard] },

  { path: ':identificationCode', component: AppPageComponent },

  { path: '**', component: AppPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
