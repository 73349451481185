<div class="dialog-actions delete-container">
  <button *ngIf="isEditing && canEdit" type="button" class="icon-button icon-action-button" aria-label="Delete note"
    (click)="deleteNote()">
    <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
  </button>
</div>

<label for="note-body" class="label-primary" translate>WIDGETS.NOTE_DIALOG.TEXTAREA_LABEL</label>
<div id="note-body-holder" class="element-hint-below">
  <textarea id="note-body" name="note-body" class="textarea-primary" [(ngModel)]="note.body" #notebody="ngModel"
    maxlength="500" rows="10" cols="50" wrap="hard" (input)="onBodyInput()" [disabled]="!canEdit"></textarea>
  <div class="hint-multi">
    <span *ngIf="canEdit" translate>WIDGETS.NOTE_DIALOG.TEXTAREA_HINT</span>
    <span *ngIf="note.body.length > 250">{{ note.body.length }}/500</span>
  </div>
</div>

<div class="dialog-actions">
  <button *ngIf="inputChanged" type="button" class="button-primary button-confirm" (click)="save()"
    translate>GENERAL.SAVE</button>
  <button *ngIf="inputChanged" type="button" class="button-primary" (click)="dialogRef.close(false)"
    translate>GENERAL.CANCEL</button>
  <button *ngIf="!inputChanged" type="button" class="button-primary" (click)="dialogRef.close(false)"
    translate>GENERAL.BACK</button>
</div>