import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { Account, AccountSimplified } from 'src/app/models/account';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppData } from 'src/app/singletons/app-data';
import { Utilities } from 'src/app/utilities/utilities';
import { AccountDialogComponent, AccountDialogMode } from '../widgets/account-dialog/account-dialog.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  public account!: Account | null;
  public activeRole!: string | null;

  public utilities = Utilities;

  constructor(
    private _appData: AppData,
    private _router: Router,
    public app: AppComponent,
    private _translate: TranslateService,
    private _authenticationService: AuthenticationService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.account = this._appData.accountValue;
    this.activeRole = this._appData.activeRoleValue;
  }

  public editAccountSelf(): void {
    if (!this.account) {
      return;
    }
    const accountCopy = new AccountSimplified(
      this.account._id,
      this.account.email,
      this.account.name,
      this.account.state,
      this.account.roles,
      this.account.language,
      this.account.lockedReadWriteAt,
      this.account.lockedReadOnlyAt,
      this.account.notifyAboutNewReports,
      this.account.notifyAboutReportStateChanges,
      this.account.notifyAboutDeadlines,
      this.account.notifyAboutNewMessages,
      this.account.attachments,
      this.account.categories,
    );

    const dialogRef = this.dialog.open(AccountDialogComponent, {
      width: '40rem',
      panelClass: 'dialog-container',
      data: {
        organization: this._appData.organizationValue,
        account: accountCopy,
        accountDialogMode: AccountDialogMode.editSelf
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!this._handleDialogValidation(result) || !this.account) {
        return;
      }

      this.account.email = accountCopy.email;
      this.account.name = accountCopy.name;
      this.account.notifyAboutNewReports = accountCopy.notifyAboutNewReports;
      this.account.notifyAboutReportStateChanges = accountCopy.notifyAboutReportStateChanges;
      this.account.notifyAboutDeadlines = accountCopy.notifyAboutDeadlines;
      this.account.notifyAboutNewMessages = accountCopy.notifyAboutNewMessages;
      this.account.categories = accountCopy.categories;

      this._appData.setAccount(this.account);

      this._translate.get('GENERAL.N_CHANGES_SUCCESSFUL').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _handleDialogValidation(result: any): boolean {
    if (!result) {
      return false;
    }

    if (result.err) {
      this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return false;
    }

    if (result.email === false) {
      this._translate.get('ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.N_EMAIL_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return false;
    }

    if (result.name === false) {
      this._translate.get('ORGANIZATION_ADMINISTRATION.ACCOUNTS_OVERVIEW.N_NAME_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return false;
    }

    return true;
  }

  logout(): void {
    this._authenticationService
      .logout()
      .subscribe(() => {
        this._appData.wipe();
        this._router.navigate(['/'], { replaceUrl: true });
        this._translate.get('GENERAL.N_SUCCESSFUL_LOGOUT').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
        this.app.hideLoading();
      });
  }

}
