import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { Account } from 'src/app/models/account';
import { Report } from 'src/app/models/report';
import { ReportState } from 'src/app/models/report-state';
import { AppData } from 'src/app/singletons/app-data';
import { ReportStateHelper } from 'src/app/utilities/report-state-helper';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-states-overview',
  templateUrl: './states-overview.component.html',
  styleUrls: ['./states-overview.component.css']
})
export class StatesOverviewComponent implements OnInit, OnDestroy {

  utilities = Utilities;
  reportStateHelper = ReportStateHelper;

  public activeRole!: string;

  public account!: Account | null;

  private _activeReportSubscription$!: Subscription;
  public report!: Report;

  public lastState!: ReportState;
  public isStateArchivedForRole!: boolean;

  public stateArchiveHiddenMap = new Map<string, boolean>();

  constructor(
    private _appData: AppData,
    public app: AppComponent,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.activeRole = this._appData.activeRoleValue ?? '';
    this.account = this._appData.accountValue;

    this._activeReportSubscription$ = this._appData.activeReport.subscribe((activeReport) => {
      if (!activeReport) {
        return;
      }

      this.report = activeReport;
      this.lastState = Utilities.transformToSpecificClassInstance(ReportState, this.report.states[this.report.states.length - 1]);
      this.isStateArchivedForRole = this.lastState.isArchivedForRole();

      this.stateArchiveHiddenMap.clear();
      this.report.states.forEach(state => this.stateArchiveHiddenMap.set(state.id, false));
    });
  }

  ngOnDestroy(): void {
    this._activeReportSubscription$.unsubscribe();
  }

  public goToState(state: ReportState, isLast: boolean): void {
    if (isLast
      && state.id !== 'WaitingForEvaluationByObligedEntityState'
      && state.id !== 'WaitingForExecutionByObligedEntityState'
      && state.id !== 'GT_WaitingForObligedEntityState'
      && !state.id.startsWith('ArchivedForRoleState')
      && Utilities.canAccountEdit(this.account)) {
      this._appData.setActiveReportState(state);
      return;
    }

    if (isLast
      && !state.id.startsWith('ArchivedForRoleState')
      && !Utilities.canAccountEdit(this.account)) {
      this.translate.get('OVERVIEW.DETAIL.STATES_OVERVIEW.N_LOCKED_READ_ONLY').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    // when clicked on archived state, notify user
    if (state.id.startsWith('ArchivedForRoleState')) {
      this.translate.get('OVERVIEW.DETAIL.STATES_OVERVIEW.N_REPORT_ARCHIVED_CLICK_TO_EXPAND').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    // when clicked on waiting state, just notify user
    if (isLast
      && (state.id === 'WaitingForEvaluationByObligedEntityState'
        || state.id === 'WaitingForExecutionByObligedEntityState'
        || state.id === 'GT_WaitingForObligedEntityState')
      && Utilities.canAccountEdit(this.account)) {
      this.translate.get('OVERVIEW.DETAIL.STATES_OVERVIEW.N_WAITING_STATE_CLICKED').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    // otherwise, expand/collapse state archive text
    if (!isLast) {
      this._toggleStateArchive(state.id);
    }
  }

  private _toggleStateArchive(stateId: string): void {
    this.stateArchiveHiddenMap.set(stateId, !this.stateArchiveHiddenMap.get(stateId));

    const collapsible = document.getElementById(`${stateId}-archive-container`) as HTMLElement;
    const lineElement = collapsible.previousElementSibling?.previousElementSibling?.firstChild as HTMLElement;
    let quillViewHtmlElementCount = 0;
    if (this.stateArchiveHiddenMap.get(stateId)) {
      let height = collapsible.scrollHeight;
      for (let i = 0; i < collapsible.children.length; i++) {
        const innerNodeElem = collapsible.children[i] as HTMLElement;
        height += innerNodeElem.scrollHeight;
        if (innerNodeElem.tagName === 'QUILL-VIEW-HTML') {
          quillViewHtmlElementCount++;
        }
      }
      if (stateId === 'JustifyReportNotJustState') {
        height *= 0.8;
      } else if ((stateId === 'SuggestionByObligedEntityState' && quillViewHtmlElementCount > 0) || stateId === 'ExecutionState') {
        height *= 0.85;
      } else if (quillViewHtmlElementCount > 0) {
        height *= 0.7;
      } else {
        height *= 0.95;
      }
      collapsible.style.maxHeight = `${height}px`;
      lineElement.style.height = `${height - 10}px`;
    } else {
      collapsible.style.maxHeight = '0';
      lineElement.style.height = '1.2rem';
    }
  }
}
