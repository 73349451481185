<mat-dialog-content>
  <label for="choose-org-select" class="label-primary" translate>WIDGETS.CHOOSE_ORGANIZATION_DIALOG.SELECT_LABEL</label>
  <div class="element-hint-below">
    <select id="choose-org-select" name="choose-org-select" class="select-primary"
      [(ngModel)]="data.chosenOrganization">
      <option *ngFor="let org of data.organizations" [ngValue]="org">{{ org.name }}
      </option>
    </select>
    <div class="hint-single">
      <span translate>WIDGETS.CHOOSE_ORGANIZATION_DIALOG.SELECT_HINT</span>
    </div>
  </div>
</mat-dialog-content>

<div class="dialog-actions">
  <button type="button" class="button-primary button-confirm" (click)="dialogRef.close(data.chosenOrganization)"
    [disabled]="data.chosenOrganization._id === ''" translate>WIDGETS.CHOOSE_ORGANIZATION_DIALOG.CONTINUE</button>
</div>