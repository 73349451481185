<div class="content-container">
  <div class="content-centered">
    <h1 class="custom-h1" translate>REPORT.CHAT_REQUEST.HEADING</h1>
    <div class="visual-container">
      <form>
        <label for="identification-code" class="label-primary" translate>REPORT.CHAT_REQUEST.CODE_LABEL</label>
        <div class="element-hint-below">
          <input id="identification-code" name="identification-code" type="input" class="input-primary"
            [(ngModel)]="identificationCode" required #identificationcode="ngModel" pattern="(?=.*\d)(?=.*[a-z,A-Z]).{16,}"
            (ngModelChange)="identificationCodeChanged()" maxlength="16" autocomplete="off">
          <div class="hint-multi">
            <span translate>REPORT.CHAT_REQUEST.CODE_HINT</span>
            <span>{{ identificationCode.length }}/16</span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>