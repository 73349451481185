import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ReportCategory } from 'src/app/models/report-category';

@Component({
  selector: 'assign-categories',
  templateUrl: './assign-categories.component.html',
  styleUrls: ['./assign-categories.component.css']
})
export class AssignCategoriesComponent implements OnInit {

  @ViewChild('template', { static: true }) template!: TemplateRef<unknown>;

  @Input() organizationCategories!: ReportCategory[];
  @Input() accountCategories!: ReportCategory[];
  @Output() categoriesChanged = new EventEmitter<number>();

  constructor(
    private viewContainerRef: ViewContainerRef,
  ) { }

  ngOnInit(): void {
    //this and ViewChild (and ng-template in html file) are here to create embedded view (without additional html tag)
    this.viewContainerRef.createEmbeddedView(this.template);
  }

  public indexOfCategory(categories: ReportCategory[], categoryToFind: ReportCategory): number {
    return categories.findIndex(c => c.id === categoryToFind.id);
  }

  public categoryClick(category: ReportCategory): void {
    const accountCategoriesIndex = this.indexOfCategory(this.accountCategories, category);
    if (accountCategoriesIndex !== -1) {
      this.accountCategories.splice(accountCategoriesIndex, 1);
    } else {
      this.accountCategories.push(category);
    }

    this.categoriesChanged.emit();
  }

}
