<ng-template #template>
  <ng-container [ngSwitch]="state.id">
    <!-- ReportEvaluationState -->
    <div *ngSwitchCase="'ReportEvaluationState'">
      <p *ngIf="state.extendedOnceDate"
        [innerHTML]="'OVERVIEW.DETAIL.REPORT_EVALUATION.ARCHIVED_EXTENDED_ONCE' | translate:{ date: utilities.dateFormat(state.extendedOnceDate), daysCount: state.extendedOnceDaysCount }">
      </p>
      <p *ngIf="state.extendedTwiceDate"
        [innerHTML]="'OVERVIEW.DETAIL.REPORT_EVALUATION.ARCHIVED_EXTENDED_TWICE' | translate:{ date: utilities.dateFormat(state.extendedTwiceDate), daysCount: state.extendedTwiceDaysCount }">
      </p>
      <p *ngIf="state.isReportJust"
        [innerHTML]="'OVERVIEW.DETAIL.REPORT_EVALUATION.ARCHIVED_IS_JUST' | translate:{ date: utilities.dateFormat(state.concludedAt) }">
      </p>
      <p *ngIf="!state.isReportJust"
        [innerHTML]="'OVERVIEW.DETAIL.REPORT_EVALUATION.ARCHIVED_IS_NOT_JUST' | translate:{ date: utilities.dateFormat(state.concludedAt) }">
      </p>
    </div>

    <!-- JustifyReportNotJustState -->
    <ng-container *ngSwitchCase="'JustifyReportNotJustState'">
      <p *ngIf="!state.isReportJust"
        [innerHTML]="'OVERVIEW.DETAIL.JUSTIFY_NOT_JUST.ARCHIVED_REASON' | translate:{ date: utilities.dateFormat(state.concludedAt) }">
      </p>
      <quill-view-html [content]="state.justifyReportNotJustTextHtml" theme="snow">
      </quill-view-html>
    </ng-container>

    <!-- SuggestionForObligedEntityState -->
    <ng-container *ngSwitchCase="'SuggestionForObligedEntityState'">
      <p
        [innerHTML]="'OVERVIEW.DETAIL.SUGGESTION_FOR_OE.ARCHIVED_TEXT' | translate:{ date: utilities.dateFormat(state.concludedAt), summary: report.summaryByCompetentPerson }">
      </p>
      <span><strong translate>OVERVIEW.DETAIL.SUGGESTION_FOR_OE.ARCHIVED_SUMMARY_LONG_LABEL</strong></span>
      <quill-view-html
        [content]="report.summaryLongByCompetentPersonHtml ? report.summaryLongByCompetentPersonHtml : ''" theme="snow">
      </quill-view-html>
      <span><strong translate>OVERVIEW.DETAIL.SUGGESTION_FOR_OE.ARCHIVED_SUGGESTION_LABEL</strong></span>
      <quill-view-html [content]="report.suggestionByCompetentPersonHtml ? report.suggestionByCompetentPersonHtml : ''"
        theme="snow">
      </quill-view-html>
      <div *ngIf="report.attachmentByCompetentPerson" class="file-list-container">
        <span class="file-list-header" translate>OVERVIEW.DETAIL.UPLOADED_ATTACHMENTS</span>
        <button type="button" class="inline-button"
          (click)="downloadAttachment(report.attachmentByCompetentPerson, true)">
          <mat-icon fontSet="material-icons-outlined">
            file_download</mat-icon>
          {{ report.attachmentByCompetentPerson.name }}
        </button>
      </div>
    </ng-container>

    <!-- WaitingForEvaluationByObligedEntityState -->
    <div *ngSwitchCase="'WaitingForEvaluationByObligedEntityState'">
      <p translate>OVERVIEW.DETAIL.WAITING_FOR_EVALUATION.ARCHIVED</p>
    </div>

    <!-- UnderstoodAcceptedMeasuresState -->
    <ng-container *ngSwitchCase="'UnderstoodAcceptedMeasuresState'">
      <p translate [translateParams]="{ date: utilities.dateFormat(state.concludedAt) }">OVERVIEW.DETAIL.UNDERSTOOD_ACCEPTED_MEASURES.ARCHIVED</p>
      <quill-view-html [content]="state.textFromObligedEntityHtml ? state.textFromObligedEntityHtml : ''" theme="snow">
      </quill-view-html>
      <div *ngIf="state.attachment" class="file-list-container">
        <span class="file-list-header" translate>OVERVIEW.DETAIL.UPLOADED_ATTACHMENTS</span>
        <button type="button" class="inline-button" (click)="downloadAttachment(state.attachment)">
          <mat-icon fontSet="material-icons-outlined">
            file_download</mat-icon>
          {{ state.attachment.name }}
        </button>
      </div>
    </ng-container>

    <!-- WaitingForExecutionByObligedEntityState -->
    <div *ngSwitchCase="'WaitingForExecutionByObligedEntityState'">
      <p translate>OVERVIEW.DETAIL.WAITING_FOR_EXECUTION.ARCHIVED</p>
    </div>

    <!-- UnderstoodExecutionState -->
    <ng-container *ngSwitchCase="'UnderstoodExecutionState'">
      <p translate [translateParams]="{ date: utilities.dateFormat(state.concludedAt) }">OVERVIEW.DETAIL.UNDERSTOOD_EXECUTION.ARCHIVED</p>
      <quill-view-html [content]="state.textFromObligedEntityHtml ? state.textFromObligedEntityHtml : ''" theme="snow">
      </quill-view-html>
      <div *ngIf="state.attachment" class="file-list-container">
        <span class="file-list-header" translate>OVERVIEW.DETAIL.UPLOADED_ATTACHMENTS</span>
        <button type="button" class="inline-button" (click)="downloadAttachment(state.attachment)">
          <mat-icon fontSet="material-icons-outlined">
            file_download</mat-icon>
          {{ state.attachment.name }}
        </button>
      </div>
    </ng-container>

    <!-- ObligedEntityUnderstoodReportState -->
    <div *ngSwitchCase="'ObligedEntityUnderstoodReportState'">
      <p translate [translateParams]="{ date: utilities.dateFormat(state.concludedAt), cpName: report?.competentPerson?.name }">OVERVIEW.DETAIL.OE_UNDERSTOOD_REPORT.ARCHIVED</p>
    </div>

    <!-- SuggestionByObligedEntityState -->
    <ng-container *ngSwitchCase="'SuggestionByObligedEntityState'">
      <p *ngIf="state.hasAcceptedMeasuresByCompetentPerson"
        [innerHTML]="'OVERVIEW.DETAIL.SUGGESTION_BY_OE.ARCHIVED_ACCEPTED' | translate:{ orgName: organization.name }">
      </p>
      <ng-container *ngIf="!state.hasAcceptedMeasuresByCompetentPerson">
        <p
          [innerHTML]="'OVERVIEW.DETAIL.SUGGESTION_BY_OE.ARCHIVED_SUGGESTED' | translate:{ orgName: organization.name }">
        </p>
        <quill-view-html [content]="state.suggestionByObligedEntityTextHtml" theme="snow">
        </quill-view-html>
      </ng-container>
    </ng-container>

    <!-- NotifyCompetentPersonAboutAcceptedMeasuresState -->
    <ng-container *ngSwitchCase="'NotifyCompetentPersonAboutAcceptedMeasuresState'">
      <div
        [innerHTML]="'OVERVIEW.DETAIL.NOTIFY_CP_ACCEPTED.ARCHIVED' | translate:{ date: utilities.dateFormat(state.concludedAt) }">
      </div>
      <quill-view-html [content]="state.notifyCompetentPersonTextHtml" theme="snow">
      </quill-view-html>
      <div *ngIf="state.attachment" class="file-list-container">
        <span class="file-list-header" translate>OVERVIEW.DETAIL.UPLOADED_ATTACHMENTS</span>
        <button type="button" class="inline-button" (click)="downloadAttachment(state.attachment)">
          <mat-icon fontSet="material-icons-outlined">
            file_download</mat-icon>
          {{ state.attachment.name }}
        </button>
      </div>
    </ng-container>

    <!-- ExecutionState -->
    <ng-container *ngSwitchCase="'ExecutionState'">
      <p [innerHTML]="'OVERVIEW.DETAIL.EXECUTION.ARCHIVED' | translate"></p>
      <quill-view-html [content]="state.executionTextHtml" theme="snow">
      </quill-view-html>
    </ng-container>

    <!-- NotifyCompetentPersonAboutExecutionState -->
    <ng-container *ngSwitchCase="'NotifyCompetentPersonAboutExecutionState'">
      <div
        [innerHTML]="'OVERVIEW.DETAIL.NOTIFY_CP_EXECUTION.ARCHIVED' | translate:{ date: utilities.dateFormat(state.concludedAt) }">
      </div>
      <quill-view-html [content]="state.notifyCompetentPersonTextHtml" theme="snow">
      </quill-view-html>
      <div *ngIf="state.attachment" class="file-list-container">
        <span class="file-list-header" translate>OVERVIEW.DETAIL.UPLOADED_ATTACHMENTS</span>
        <button type="button" class="inline-button" (click)="downloadAttachment(state.attachment)">
          <mat-icon fontSet="material-icons-outlined">
            file_download</mat-icon>
          {{ state.attachment.name }}
        </button>
      </div>
    </ng-container>

    <!-- GT_NoticeReportState -->
    <div *ngSwitchCase="'GT_NoticeReportState'">
      <p>{{ 'OVERVIEW.DETAIL.GT_NOTICE_REPORT.ARCHIVED_' + activeRole | uppercase | translate:{ date: utilities.dateFormat(state.concludedAt) } }}</p>
    </div>

    <!-- GT_NotifyObligedEntityState -->
    <ng-container *ngSwitchCase="'GT_NotifyObligedEntityState'">
      <ng-container *ngIf="state.notifyObligedEntityTextHtml">
        <p translate [translateParams]="{ date: utilities.dateFormat(state.concludedAt) }">OVERVIEW.DETAIL.GT_NOTIFY_OE.ARCHIVED_NOTIFIED_OE</p>
        <quill-view-html [content]="state.notifyObligedEntityTextHtml" theme="snow">
        </quill-view-html>
      </ng-container>
      <div *ngIf="!state.notifyObligedEntityTextHtml">
        <p translate [translateParams]="{ date: utilities.dateFormat(state.concludedAt) }">OVERVIEW.DETAIL.GT_NOTIFY_OE.ARCHIVED_ARCHIVED_NOW</p>
      </div>
    </ng-container>

    <!-- GT_WaitingForObligedEntityState -->
    <div *ngSwitchCase="'GT_WaitingForObligedEntityState'">
      <p translate>OVERVIEW.DETAIL.GT_WAITING_FOR_OE.ARCHIVED</p>
    </div>

    <!-- GT_ObligedEntityUnderstoodState -->
    <div *ngSwitchCase="'GT_ObligedEntityUnderstoodState'">
      <p translate [translateParams]="{ date: utilities.dateFormat(state.concludedAt) }">OVERVIEW.DETAIL.GT_OE_UNDERSTOOD.ARCHIVED</p>
    </div>

    <!-- NotifyWhistleblowerAboutReportNoticingState -->
    <!-- NotifyWhistleblowerAboutReportEvaluationState -->
    <!-- NotifyWhistleblowerAboutAcceptedMeasuresState -->
    <!-- NotifyWhistleblowerAboutExecutionState -->
    <ng-container
      *ngSwitchCase="['NotifyWhistleblowerAboutReportNoticingState', 'NotifyWhistleblowerAboutReportEvaluationState', 'NotifyWhistleblowerAboutAcceptedMeasuresState', 'NotifyWhistleblowerAboutExecutionState'].includes(state.id) ? state.id : ''">
      <ng-container *ngIf="notifyAbout.dateNotified">
        <div
          [innerHTML]="'OVERVIEW.DETAIL.' + notifyAboutTranslateId + '.ARCHIVED_NOTIFY_NOW' | translate:{ date: utilities.dateFormat(notifyAbout.dateNotified) }">
        </div>
        <quill-view-html [content]="notifyAbout.notifyNowTextHtml ? notifyAbout.notifyNowTextHtml : ''" theme="snow">
        </quill-view-html>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>