import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Account, AccountAuthentication, AccountFromServer } from '../models/account';
import { Organization } from '../models/organization';
import { Utilities } from '../utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private _authenticationApiUrl = 'api/authentication';

  constructor(
    private _http: HttpClient,
  ) { }

  /**
   * If AccountFromServer is not null, it needs to be then mapped to Account (with categories), organization is needed for this
   * This method is not responsible for mapping
   * 
   * if account is null, then pretoken and organizations are not
   * if account is not null, then pretoken and organizations are
   */
  authenticate(accountAuthentication: AccountAuthentication)
    : Observable<{ account: AccountFromServer | null, pretoken: string | null, organizations: Organization[] | null }> {
    return this._http.post<{ account: AccountFromServer | null, pretoken: string | null, organizations: Organization[] | null }>(this._authenticationApiUrl, accountAuthentication)
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  chooseOrganization(organization: Organization): Observable<Account> {
    return this._http.get<AccountFromServer>(`${this._authenticationApiUrl}/${organization._id}`)
      .pipe(
        map(res => Utilities.transformToClassInstance(AccountFromServer, res).toAccount(organization)),
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  revalidateToken(organization?: Organization): Observable<Account | AccountFromServer> {
    return this._http.get<AccountFromServer>(`${this._authenticationApiUrl}/revalidate`)
      .pipe(
        map(res => organization && organization._id !== '' ? Utilities.transformToClassInstance(AccountFromServer, res).toAccount(organization) : res),
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  extendToken(organization: Organization): Observable<Account | AccountFromServer> {
    return this.revalidateToken(organization);
  }

  resetPasswordRequest(email: string): Observable<Record<string, unknown>> {
    return this._http.post<Record<string, unknown>>(`${this._authenticationApiUrl}/reset`, { email: email })
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  resetPassword(resetPasswordToken: string, newPassword: string, newPasswordAgain: string): Observable<Record<string, unknown>> {
    return this._http.post<Record<string, unknown>>(`${this._authenticationApiUrl}/reset/${resetPasswordToken}`,
      { newPassword: newPassword, newPasswordAgain: newPasswordAgain })
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  checkPreregisterToken(preregisterToken: string): Observable<Record<string, unknown>> {
    return this._http.get<Record<string, unknown>>(`${this._authenticationApiUrl}/preregister/${preregisterToken}`)
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  preregister(preregisterToken: string, password: string, passwordAgain: string, name: string): Observable<Record<string, unknown>> {
    return this._http.post<Record<string, unknown>>(`${this._authenticationApiUrl}/preregister/${preregisterToken}`,
      { password: password, passwordAgain: passwordAgain, name: name })
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  logout(): Observable<void> {
    return this._http.get<void>(`${this._authenticationApiUrl}/logout`)
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }
}
