<ol class="stepper">
  <li class="step step-active step-no-action">
    <div class="step-number">{{ state.number }}</div>
    <div class="step-label">{{ 'OVERVIEW.DETAIL.STATE_LABELS.' + reportStateHelper.getStateLabelTranslateId(state, true) | translate:{ tillDays: reportStateHelper.getStateTillDays(report) } }}
    </div>
  </li>
</ol>

<button id="go-back" type="button" class="inline-button go-back-in-states-button" (click)="goBack()" translate>
  <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>GENERAL.STEP_BACK
</button>

<ng-container [ngSwitch]="componentState">
  <!-- Action choice -->
  <section *ngSwitchDefault class="shrinked shrinked-more-width">
    <section class="buttons-choice">
      <button type="button" class="button-primary" (click)="goToNotifyOe()" translate>
        OVERVIEW.DETAIL.GT_NOTIFY_OE.NOTIFY_OE
      </button>
      <button type="button" class="button-primary" (click)="archiveNow()" translate>
        OVERVIEW.DETAIL.GT_NOTIFY_OE.ARCHIVE_NOW
      </button>
    </section>
  </section>

  <!-- Notify OE -->
  <section *ngSwitchCase="componentStateEnum.NotifyOe" class="shrinked shrinked-more-width">
    <label class="label-primary" translate>OVERVIEW.DETAIL.GT_NOTIFY_OE.TEXT_LABEL</label>
    <div class="element-hint-below">
      <quill-editor class="editor" [(ngModel)]="state.notifyObligedEntityTextHtml" placeholder=""
        [styles]="{'min-height': '25rem'}" [maxLength]="100000" [required]="true" [sanitize]="true"
        (onContentChanged)="notifyOeTextChanged($event)"></quill-editor>
      <div class="hint-multi">
        <span translate>OVERVIEW.DETAIL.GT_NOTIFY_OE.TEXT_HINT</span>
        <span *ngIf="notifyOeTextLength > 85000">{{ notifyOeTextLength }} / 100 000</span>
      </div>
    </div>

    <button type="button" class="button-primary button-confirm" (click)="notifyOeConfirm()" translate>
      OVERVIEW.DETAIL._NOTIFY_ABOUT.NOTIFY_NOW.CONFIRM
    </button>
  </section>
</ng-container>