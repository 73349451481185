import { Organization } from "./organization";
import { Attachment } from "./report";
import { ReportCategory } from "./report-category";

/**
 * Same as Account below, but from server categories are sent as categoriesIds
 * Need to be mapped to Account in the Services
 */
export class AccountFromServer {
  _id!: string;
  organizationId!: string;
  email!: string;
  name!: string;
  roles!: string[];
  state!: string;
  language!: string;
  lockedReadWriteAt!: Date | undefined;
  lockedReadOnlyAt!: Date | undefined;
  notifyAboutNewReports!: boolean;
  notifyAboutReportStateChanges!: boolean;
  notifyAboutDeadlines!: boolean;
  notifyAboutNewMessages!: boolean;
  attachments!: Attachment[];
  categoriesIds!: string[];
  lastLogin: Date | undefined;

  token!: string;

  public toAccount(organization: Organization): Account {
    const account = new Account();
    account._id = this._id;
    account.organizationId = this.organizationId;
    account.email = this.email;
    account.name = this.name;
    account.roles = this.roles;
    account.state = this.state;
    account.language = this.language;
    account.lockedReadWriteAt = this.lockedReadWriteAt;
    account.lockedReadOnlyAt = this.lockedReadOnlyAt;
    account.notifyAboutNewReports = this.notifyAboutNewReports;
    account.notifyAboutReportStateChanges = this.notifyAboutReportStateChanges;
    account.notifyAboutDeadlines = this.notifyAboutDeadlines;
    account.notifyAboutNewMessages = this.notifyAboutNewMessages;
    account.attachments = this.attachments;
    account.categories = this.categoriesIds.map(categoryId => organization.categories.find(c => c.id === categoryId) ?? new ReportCategory());
    account.lastLogin = this.lastLogin;
    account.token = this.token;
    return account;
  }

  public toAccountSimplified(organization: Organization): AccountSimplified {
    return new AccountSimplified(
      this._id,
      this.email,
      this.name,
      this.state,
      this.roles,
      this.language,
      this.lockedReadWriteAt,
      this.lockedReadOnlyAt,
      this.notifyAboutNewReports,
      this.notifyAboutReportStateChanges,
      this.notifyAboutDeadlines,
      this.notifyAboutNewMessages,
      this.attachments,
      this.categoriesIds.map(categoryId => organization.categories.find(c => c.id === categoryId) ?? new ReportCategory()),
    );
  }
}

export class Account {
  _id!: string;
  organizationId!: string;
  email!: string;
  name!: string;
  roles!: string[];
  state!: string;
  language!: string;
  lockedReadWriteAt!: Date | undefined;
  lockedReadOnlyAt!: Date | undefined;
  notifyAboutNewReports!: boolean;
  notifyAboutReportStateChanges!: boolean;
  notifyAboutDeadlines!: boolean;
  notifyAboutNewMessages!: boolean;
  attachments!: Attachment[];
  categories!: ReportCategory[];
  lastLogin: Date | undefined;

  token!: string;

  public toAccountFromServer(): AccountFromServer {
    const account = new AccountFromServer();
    account._id = this._id;
    account.organizationId = this.organizationId;
    account.email = this.email;
    account.name = this.name;
    account.roles = this.roles;
    account.state = this.state;
    account.language = this.language;
    account.lockedReadWriteAt = this.lockedReadWriteAt;
    account.lockedReadOnlyAt = this.lockedReadOnlyAt;
    account.notifyAboutNewReports = this.notifyAboutNewReports;
    account.notifyAboutReportStateChanges = this.notifyAboutReportStateChanges;
    account.notifyAboutDeadlines = this.notifyAboutDeadlines;
    account.notifyAboutNewMessages = this.notifyAboutNewMessages;
    account.attachments = this.attachments;
    account.categoriesIds = this.categories.map(c => c.id);
    account.lastLogin = this.lastLogin;
    account.token = this.token;
    return account;
  }
}

export class AccountAuthentication {
  email: string;
  password: string;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }
}

export class AccountSimplified {
  _id: string;
  email: string;
  name: string;
  state: string;
  roles: string[];
  language: string;
  lockedReadWriteAt: Date | undefined;
  lockedReadOnlyAt: Date | undefined;
  notifyAboutNewReports: boolean;
  notifyAboutReportStateChanges!: boolean;
  notifyAboutDeadlines: boolean;
  notifyAboutNewMessages: boolean;
  attachments: Attachment[];
  categories: ReportCategory[];

  constructor(
    id: string,
    email: string,
    name: string,
    state: string,
    roles: string[],
    language: string,
    lockedReadWriteAt: Date | undefined,
    lockedReadOnlyAt: Date | undefined,
    notifyAboutNewReports: boolean,
    notifyAboutReportStateChanges: boolean,
    notifyAboutDeadlines: boolean,
    notifyAboutNewMessages: boolean,
    attachments: Attachment[],
    categories: ReportCategory[],
  ) {
    this._id = id;
    this.email = email;
    this.name = name;
    this.state = state;
    this.roles = roles;
    this.language = language;
    this.lockedReadWriteAt = lockedReadWriteAt;
    this.lockedReadOnlyAt = lockedReadOnlyAt;
    this.notifyAboutNewReports = notifyAboutNewReports;
    this.notifyAboutReportStateChanges = notifyAboutReportStateChanges;
    this.notifyAboutDeadlines = notifyAboutDeadlines;
    this.notifyAboutNewMessages = notifyAboutNewMessages;
    this.attachments = attachments;
    this.categories = categories;
  }

  public toAccountFromServer(): AccountFromServer {
    const account = new AccountFromServer();
    account._id = this._id;
    account.email = this.email;
    account.name = this.name;
    account.roles = this.roles;
    account.state = this.state;
    account.language = this.language;
    account.lockedReadWriteAt = this.lockedReadWriteAt;
    account.lockedReadOnlyAt = this.lockedReadOnlyAt;
    account.notifyAboutNewReports = this.notifyAboutNewReports;
    account.notifyAboutReportStateChanges = this.notifyAboutReportStateChanges;
    account.notifyAboutDeadlines = this.notifyAboutDeadlines;
    account.notifyAboutNewMessages = this.notifyAboutNewMessages;
    account.attachments = this.attachments;
    account.categoriesIds = this.categories.map(c => c.id);
    return account;
  }
}