<ng-template #template>
  <label for="email" class="label-primary" translate>WIDGETS.ASSIGN_CATEGORIES.LABEL</label>
  <div class="element-hint-below">
    <div class="chips-container">
      <div *ngFor="let orgCategory of organizationCategories"
        class="chip-wrapper {{indexOfCategory(accountCategories, orgCategory) !== -1 ? 'chip-wrapper-selected' : ''}}">
        <button type="button" class="inline-button" (click)="categoryClick(orgCategory)">
          {{ orgCategory.name }}
          <mat-icon *ngIf="indexOfCategory(accountCategories, orgCategory) !== -1" fontSet="material-icons-outlined">
            check
          </mat-icon>
        </button>
      </div>
    </div>
    <div class="hint-single {{accountCategories.length === 0 ? 'hint-alert' : ''}}">
      <span *ngIf="accountCategories.length > 0" translate>WIDGETS.ASSIGN_CATEGORIES.HINT</span>
      <span *ngIf="accountCategories.length === 0" translate>WIDGETS.ASSIGN_CATEGORIES.NO_EMPTY_CATEGORIES</span>
    </div>
  </div>
</ng-template>