<div class="content-container">
  <div class="content-centered">
    <h1 translate>APP_PAGE.HEADING</h1>

    <div class="visual-container">
      <h2>{{ organization ? organization?.name : '' }}</h2>

      <p *ngIf="!organization" id="p-not-org" translate>APP_PAGE.NO_ORG_TEXT</p>

      <!-- if organization has more than 1 category, redirect to category choose; otherwise straight to report with that one category selected -->
      <a *ngIf="organization" id="a-report"
        [routerLink]="[organization.categories.length === 1 ? '/report' : '/report/choose-category']"
        [queryParams]="organization.categories.length === 1 ? {categoryId: organization.categories[0].id} : {}"
        class="a-btn a-primary button-confirm" translate>
        APP_PAGE.REPORT<mat-icon fontSet="material-icons-outlined">feedback</mat-icon>
      </a>
      <a *ngIf="organization" id="a-chat" [routerLink]="['/report/chat-request']" class="inline-button a-primary"
        translate>APP_PAGE.REPORT_CHAT<mat-icon fontSet="material-icons-outlined">question_answer
        </mat-icon></a>

      <section class="visual-container-inner">
        <div id="help-text-section" translate [innerHtml]="'APP_PAGE.HELP_TEXT_SHORT' | translate"></div>
        <button type="button" class="inline-button" (click)="expandHelp()" translate>GENERAL.READ_MORE
          <mat-icon fontSet="material-icons-outlined">north_east</mat-icon>
        </button>
      </section>
    </div>
  </div>
</div>