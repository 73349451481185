/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Note, Report } from 'src/app/models/report';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-note-dialog',
  templateUrl: './note-dialog.component.html',
  styleUrls: ['./note-dialog.component.css']
})
export class NoteDialogComponent {

  public note: Note;
  public isEditing: boolean;
  public inputChanged = false;
  private _report: Report;
  private _activeRole: string;
  public canEdit: boolean;

  constructor(
    public dialogRef: MatDialogRef<NoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _reportService: ReportService,
    public dialog: MatDialog,
  ) {
    this.note = data.note;
    this.isEditing = data.isEditing;
    this._report = data.report;
    this._activeRole = data.activeRole;
    this.canEdit = data.canEdit;
  }

  public onBodyInput(): void {
    this.inputChanged = true;
  }

  public save(): void {
    if (this.isEditing) {
      this._updateNote();
    } else {
      this._createNote();
    }
  }

  private _createNote(): void {
    this._reportService.createNote(this.note, this._report._id, this._activeRole).subscribe(res => {
      if (res) {
        this.note.id = res.id;
        this._report.detail.notes.push(this.note);
        this._report.lastUpdated = res.reportLastUpdated;
        this.dialogRef.close(true);
      }
    });
  }

  private _updateNote(): void {
    this._reportService.updateNote(this.note, this._report._id, this._activeRole).subscribe(res => {
      if (res) {
        const oldNoteIndex = this._report.detail.notes.findIndex(note => note.id === this.note.id);
        if (oldNoteIndex !== -1) {
          this._report.detail.notes.splice(oldNoteIndex, 1, this.note);
        }
        this._report.lastUpdated = res.reportLastUpdated;
        this.dialogRef.close(true);
      }
    });
  }

  public deleteNote(): void {
    this._reportService.deleteNote(this.note, this._report._id, this._activeRole).subscribe(res => {
      if (res) {
        const oldNoteIndex = this._report.detail.notes.findIndex(note => note.id === this.note.id);
        if (oldNoteIndex !== -1) {
          this._report.detail.notes.splice(oldNoteIndex, 1);
        }
        this._report.lastUpdated = res.reportLastUpdated;
        this.dialogRef.close(true);
      }
    });
  }

}
