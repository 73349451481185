import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { Attachment, Report } from 'src/app/models/report';
import { UnderstoodAcceptedMeasuresState } from 'src/app/models/report-state';
import { ReportStateService } from 'src/app/services/report-state.service';
import { AppData } from 'src/app/singletons/app-data';
import { Constants } from 'src/app/utilities/constants';
import { ReportStateHelper } from 'src/app/utilities/report-state-helper';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-understood-accepted-measures-state',
  templateUrl: './understood-accepted-measures-state.component.html',
  styleUrls: ['./understood-accepted-measures-state.component.css']
})
export class UnderstoodAcceptedMeasuresStateComponent implements OnInit {
  public reportStateHelper = ReportStateHelper;

  public report!: Report;

  private _activeReportStateSubscription$!: Subscription;
  public state!: UnderstoodAcceptedMeasuresState;

  public stateFormData = new FormData();

  private _stateApi = '';

  constructor(
    private _appData: AppData,
    private _reportStateService: ReportStateService,
    public app: AppComponent,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    if (!this._appData.activeReportValue) {
      return;
    }

    this.report = this._appData.activeReportValue;
    this._activeReportStateSubscription$ = this._appData.activeReportState.subscribe((state) => {
      if (!state) {
        return;
      }

      this.state = state as UnderstoodAcceptedMeasuresState;
      this._stateApi = Constants.stateApiMap.get(this.state.id) ?? '';
    });
  }

  ngOnDestroy(): void {
    this._activeReportStateSubscription$.unsubscribe();
  }

  public goBack(): void {
    this._appData.setActiveReportState(null);
  }

  public understood(): void {
    this.stateFormData = new FormData();
    this.stateFormData.set('state', JSON.stringify(this.state));

    this.app.showLoading();
    this._reportStateService.send(`${this._stateApi}/understood`, this.report._id, this.report.lastUpdated, this.stateFormData).subscribe(res => {
      if (res) {
        // replace old report with the new one
        this.report = res.report;
        this._appData.setActiveReport(res.report);
        this._appData.replaceSingleReport(res.report);
        this._appData.setNewMessage(res.message);
        // set active report to null (=> redirect to states overview)
        this._appData.setActiveReportState(null);
        this.translate.get('OVERVIEW.DETAIL.N_CONTINUE').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      }
      this.app.hideLoading();
    }, (errorStatus) => {
      if (errorStatus === 409) {
        this.translate.get('GENERAL.N_DATA_CHANGED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      } else {
        this.translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      }
      this.app.hideLoading();
    });
  }

  public downloadAttachment(attachment: Attachment): void {
    this.app.showLoading();
    this._reportStateService.downloadAttachment(this._stateApi, this.report._id, attachment.id).subscribe((res) => {
      if (res) {
        Utilities.downloadFile(res, attachment.name);
        this.translate.get('GENERAL.N_FILE_DOWNLOADED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      }
      this.app.hideLoading();
    }, () => {
      this.translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      this.app.hideLoading();
    });
  }
}
