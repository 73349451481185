/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { plainToClass } from 'class-transformer';
import { Account, AccountSimplified } from '../models/account';

export class Utilities {
  static getOnlyDateString(date: Date): string {
    return date.toISOString().substring(0, 10);
  }

  /**
   * Returns negative if date1 is before date2, positive if date1 is after date2, zero if date1 === date2 (exact match)
   * @param date1 
   * @param date2 
   */
  static compare(date1: Date, date2: Date): number {
    return date1.getTime() - date2.getTime();
  }

  /**
   * Returns difference between two given dates (note: does not handle time zones)
   * @param date1 
   * @param date2 
   */
  static daysBetweenDates(date1: Date, date2: Date): number {
    return Math.round((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24));
  }

  /**
   * Compares only date parts (Y,M,d): returns negative if date1 is before date2, positive if date1 is after date2, zero if date1 === date2 (exact match)
   * @param date1 
   * @param date2 
   */
  static compareOnlyDate(date1: Date, date2: Date): number {
    const year1 = date1.getFullYear(), year2 = date2.getFullYear();
    const month1 = date1.getMonth(), month2 = date2.getMonth();
    const day1 = date1.getDate(), day2 = date2.getDate();

    return year1 !== year2
      ? year1 - year2
      : (month1 !== month2
        ? month1 - month2
        : (day1 - day2));
  }

  /**
   * Compares only date parts (Y,M,d): returns true if date is > date1 && < date2
   * @param date to compare
   * @param date1 start, can be undefined
   * @param date2 end, can be undefined
   */
  static isBetweenDatesOnlyDate(date: Date, date1: Date | undefined, date2: Date | undefined): boolean {
    if (!date1 && !date2) {
      return true;
    }
    if (!date1 && date2) {
      return Utilities.compareOnlyDate(date, date2) <= 0;
    }
    if (date1 && !date2) {
      return Utilities.compareOnlyDate(date, date1) >= 0;
    }
    if (!date1 || !date2) {
      // should not happen
      return false;
    }
    const compare1 = Utilities.compareOnlyDate(date, date1);
    const compare2 = Utilities.compareOnlyDate(date, date2);
    return compare1 >= 0 && compare2 <= 0;
  }

  static dateFormat(date: Date | number | undefined, onlyDate = true): string {
    if (!date) {
      return '-';
    }

    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    date = new Date(date);

    if (onlyDate) {
      // if (this.compareOnlyDate(today, date) === 0) {
      //   return 'Dnes';
      // } else if (this.compareOnlyDate(yesterday, date) === 0) {
      //   return 'Včera';
      // }
      return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
    }

    const timeFormatted = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    // if (this.compareOnlyDate(today, date) === 0) {
    //   return `Dnes ${timeFormatted}`;
    // } else if (this.compareOnlyDate(yesterday, date) === 0) {
    //   return `Včera ${timeFormatted}`;
    // }
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()} ${timeFormatted}`;
  }

  static shortText(text: string | undefined, length = 50): string {
    if (!text) return '-';
    return text.length > length ? `${text.substr(0, length)}...` : text;
  }

  static copy<T>(objectToCopy: T): T {
    return JSON.parse(JSON.stringify(objectToCopy));
  }

  /**
   * Transforms [object] to instance of [givenClass]
   * Example: const account = Utilities.transformToClassInstance(Account, accountObjectFromResponse)
   * @param givenClass 
   * @param object 
   * @returns 
   */
  static transformToClassInstance(givenClass: any, object: any): any {
    return plainToClass(givenClass, object);
  }

  /**
   * Transforms [object] to specific class of given [abstractClass]
   * Example: const specificState = Utilities.transformToClassInstance(ReportState, state)
   * @param abstractClass needs to have getSpecificClass() method 
   * @param object 
   * @returns 
   */
  static transformToSpecificClassInstance(abstractClass: any, object: any): any {
    const tmp: any = plainToClass(abstractClass as any, object);
    return plainToClass(tmp.getSpecificClass(), tmp);
  }

  static downloadFile(data: any, fileName: string, type = 'application/octet-stream'): void {
    const blob = new Blob([data], { type: type });
    const downloadURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName;
    link.click();
  }

  static canAccountEdit(account: Account | AccountSimplified | null): boolean {
    return account ? account.state !== 'lockedReadOnly' : false;
  }

  static getRoleTextId(role: string): string {
    switch (role) {
      case 'cp':
        return 'GENERAL.COMPETENT_PERSON';
      case 'oe':
        return 'GENERAL.OBLIGED_ENTITY';
      case 'oa':
        return 'GENERAL.ORGANIZATION_ADMINISTRATOR';
      default:
        return '';
    }
  }
}