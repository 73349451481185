<ol class="stepper">
  <li class="step step-active step-no-action">
    <div class="step-number">{{ state.number }}</div>
    <div class="step-label">{{ 'OVERVIEW.DETAIL.STATE_LABELS.' + reportStateHelper.getStateLabelTranslateId(state, true) | translate:{ tillDays: reportStateHelper.getStateTillDays(report) } }}
    </div>
  </li>
</ol>

<button id="go-back" type="button" class="inline-button go-back-in-states-button" (click)="goBack()" translate>
  <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>GENERAL.STEP_BACK
</button>

<ng-container [ngSwitch]="componentState">
  <!-- Action choice -->
  <section *ngSwitchCase="componentStateEnum.ActionChoice" class="shrinked shrinked-more-width">
    <section class="buttons-choice">
      <button type="button" class="button-primary" (click)="reportIsJust()" translate>
        OVERVIEW.DETAIL.REPORT_EVALUATION.IS_JUST
      </button>
      <button type="button" class="button-primary" (click)="reportIsNotJust()" translate>
        OVERVIEW.DETAIL.REPORT_EVALUATION.IS_NOT_JUST
      </button>

      <button *ngIf="extendDeadlineCountRemaining > 1" type="button" class="inline-button" (click)="goExtendDeadline()"
        translate [translateParams]="{count: extendDeadlineCountRemaining}">
        OVERVIEW.DETAIL.REPORT_EVALUATION.EXTEND_DEADLINE_ONCE
        <mat-icon fontSet="material-icons-outlined">chevron_right</mat-icon>
      </button>
      <button *ngIf="extendDeadlineCountRemaining === 1" type="button" class="inline-button"
        (click)="goExtendDeadline()" translate [translateParams]="{count: extendDeadlineCountRemaining}">
        OVERVIEW.DETAIL.REPORT_EVALUATION.EXTEND_DEADLINE_TWICE
        <mat-icon fontSet="material-icons-outlined">chevron_right</mat-icon>
      </button>
      <button *ngIf="extendDeadlineCountRemaining <= 0" type="button" class="inline-button" (click)="goExtendDeadline()"
        translate>
        OVERVIEW.DETAIL.REPORT_EVALUATION.CANNOT_EXTEND
      </button>
    </section>
  </section>

  <!-- Extend deadline -->
  <section *ngSwitchCase="componentStateEnum.ExtendDeadline" class="shrinked">
    <label for="extend-deadline-select" class="label-primary"
      translate>OVERVIEW.DETAIL.REPORT_EVALUATION.EXTEND_DAYS_LABEL</label>
    <div class="element-hint-below">
      <select *ngIf="extendDeadlineCountRemaining === 2" id="extend-deadline-select" name="extend-deadline-select"
        class="select-primary" [(ngModel)]="state.extendedOnceDaysCount">
        <option *ngFor="let i of arrayFromZeroToTwentynine" [ngValue]="i + 1">{{ i + 1 }}
        </option>
      </select>
      <select *ngIf="extendDeadlineCountRemaining === 1" id="extend-deadline-select" name="extend-deadline-select"
        class="select-primary" [(ngModel)]="state.extendedTwiceDaysCount">
        <option *ngFor="let i of arrayFromZeroToTwentynine" [ngValue]="i + 1">{{ i + 1 }}
        </option>
      </select>
      <div class="hint-single">
        <span translate>OVERVIEW.DETAIL.REPORT_EVALUATION.EXTEND_DAYS_HINT</span>
      </div>
    </div>

    <button type="button" class="button-primary button-confirm" (click)="confirmExtendedDaysCount()" translate>
      OVERVIEW.DETAIL.REPORT_EVALUATION.EXTEND_CONFIRM
    </button>
  </section>

  <!-- Notify about -->
  <ng-container *ngSwitchDefault>
    <!-- Extend deadline once -->
    <ng-container *ngIf="extendDeadlineCountRemaining === 1">
      <h3 translate>OVERVIEW.DETAIL.REPORT_EVALUATION.NOTIFY_ABOUT_EXTEND_ONCE</h3>
      <app-notify-about [notifyAboutObject]="state.notifiedAboutExtendingOnce"
        (goToNextStateNotifyNow)="goToNextStateNotifyNowExtendOnce()">
      </app-notify-about>
    </ng-container>

    <!-- Extend deadline twice -->
    <ng-container *ngIf="extendDeadlineCountRemaining === 0">
      <h3 translate>OVERVIEW.DETAIL.REPORT_EVALUATION.NOTIFY_ABOUT_EXTEND_TWICE</h3>
      <app-notify-about [notifyAboutObject]="state.notifiedAboutExtendingTwice"
        (goToNextStateNotifyNow)="goToNextStateNotifyNowExtendTwice()">
      </app-notify-about>
    </ng-container>
  </ng-container>
</ng-container>