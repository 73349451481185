/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, ElementRef, OnInit, AfterViewChecked, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ContentChange } from 'ngx-quill';
import { AppComponent } from 'src/app/app.component';
import { Message } from 'src/app/models/message';
import { Organization } from 'src/app/models/organization';
import { Attachment, ReportForWhistleblower } from 'src/app/models/report';
import { ReportCategory } from 'src/app/models/report-category';
import { MessageService } from 'src/app/services/message.service';
import { ReportService } from 'src/app/services/report.service';
import { AppData } from 'src/app/singletons/app-data';
import { Constants } from 'src/app/utilities/constants';
import { Utilities } from 'src/app/utilities/utilities';
import { ConfirmDialogComponent } from '../../widgets/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, AfterViewChecked {

  public report: ReportForWhistleblower | null = null;
  public organization!: Organization;
  public reportCategory: ReportCategory | undefined = undefined;
  private _reportIdentificationCode: string | null = null;

  public messages: Message[] = [];

  public newMessageTextHtml = '';
  public newMessageTextLength = 0;

  private _attachmentLastId = 0;
  public attachment: Attachment | undefined;
  private _messageFormData = new FormData();

  public utilities = Utilities;

  private _previousMessagesContainerScrollHeight = 0;
  @ViewChild('messagescontainer') private _messagesContainer!: ElementRef;

  constructor(
    private _appData: AppData,
    private _reportService: ReportService,
    private _messageService: MessageService,
    public app: AppComponent,
    public dialog: MatDialog,
    private _translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.report = this._appData.reportForWhistleblower;
    this.organization = this._appData.organizationValue;
    this.reportCategory = this.organization.categories.find(c => c.id === this.report?.detail.categoryId);
    this._reportIdentificationCode = this._appData.reportIdentificationCode;

    if (!this.report || !this.reportCategory || !this._reportIdentificationCode) {
      return;
    }
    this.app.showLoading();
    this._messageService.getAll(this.report._id, this._reportIdentificationCode, 'wb').subscribe((res) => {
      if (res) {
        this.messages = res;
      }
      this.app.hideLoading();
    }, () => {
      this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      this.app.hideLoading();
    });
  }

  ngAfterViewChecked(): void {
    // scroll to bottom of messages container everytime it gets updated
    if (this._previousMessagesContainerScrollHeight < this._messagesContainer.nativeElement.scrollHeight) {
      this._messagesContainer.nativeElement.scrollTop = this._messagesContainer.nativeElement.scrollHeight;
      this._previousMessagesContainerScrollHeight = this._messagesContainer.nativeElement.scrollHeight;
    }
  }

  public newMessageTextChanged(event: ContentChange): void {
    this.newMessageTextLength = event.text.length - 1;
  }

  public sendTextMessage(): void {
    if (this.newMessageTextLength === 0) {
      this._translate.get('REPORT.CHAT.N_MESSAGE_EMPTY').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    this._confirmSendMessage('text');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFileSelected(event: any): void {
    const attachment: File = event.target.files[0];

    if (!attachment) {
      return;
    }

    if (attachment.size > Constants.maxSizeOfInputFile) {
      this._translate.get('GENERAL.N_ATTACHMENT_SIZE_LIMIT').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    const newId = this._attachmentLastId.toString();
    this._attachmentLastId++;
    this.attachment = new Attachment(newId, attachment.name);
    this._messageFormData.set('attachment', attachment);

    this._confirmSendMessage('attachment');
  }

  private _confirmSendMessage(messageType: string): void {
    this._translate.get(messageType === 'attachment'
      ? 'REPORT.CHAT.DIALOG_CONFIRM_ATTACHMENT_MESSAGE'
      : 'REPORT.CHAT.DIALOG_CONFIRM_TEXT_MESSAGE'
    ).subscribe((dialogTitle) => {
      this._translate.get('GENERAL.CANNOT_BE_UNDONE').subscribe((dialogBody) => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '40rem',
          panelClass: 'dialog-container',
          data: {
            title: dialogTitle,
            body: dialogBody,
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this._sendMessageToServer(messageType);
          }
        });
      });
    });
  }

  private _sendMessageToServer(messageType: string): void {
    if (!this._reportIdentificationCode || !this.report) {
      return;
    }

    this.app.showLoading();

    const resultObservable = messageType === 'attachment'
      ? this._messageService.sendAttachmentMessage(this.report._id, this._messageFormData, this._reportIdentificationCode, 'wb', 'cp')
      : this._messageService.sendTextMessage(this.report._id, this.newMessageTextHtml, this._reportIdentificationCode, 'wb', 'cp');

    resultObservable.subscribe((res) => {
      if (res) {
        if (messageType === 'attachment') {
          this.attachment = undefined;
          this._messageFormData = new FormData();
        } else {
          this.newMessageTextHtml = '';
          this.newMessageTextLength = 0;
        }
        this.messages.push(res);
      }
      this.app.hideLoading();
    }, () => {
      this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      this.app.hideLoading();
    });
  }

  public downloadMessageAttachment(message: Message): void {
    if (!message.attachment || !this._reportIdentificationCode) {
      return;
    }

    this.app.showLoading();
    this._messageService.downloadAttachment(
      message._id,
      message.attachment.id,
      this._reportIdentificationCode,
      'wb',
    ).subscribe((res) => {
      if (res && message.attachment) {
        Utilities.downloadFile(res, message.attachment.name);
        this._translate.get('GENERAL.N_FILE_DOWNLOADED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      }
      this.app.hideLoading();
    }, () => {
      this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      this.app.hideLoading();
    });
  }

  // download Report attachment
  public downloadAttachment(attachment: Attachment): void {
    if (!this.report) {
      return;
    }
    this.app.showLoading();
    this._reportService.downloadAttachment(
      this.report._id,
      attachment.id,
      'wb',
      this._reportIdentificationCode,
      this.report.organizationId,
    ).subscribe((res) => {
      if (res) {
        Utilities.downloadFile(res, attachment.name);
        this._translate.get('GENERAL.N_FILE_DOWNLOADED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      }
      this.app.hideLoading();
    }, () => {
      this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      this.app.hideLoading();
    });
  }
}
