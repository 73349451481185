/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { AccountSimplified } from 'src/app/models/account';
import { Organization } from 'src/app/models/organization';
import { AccountService } from 'src/app/services/account.service';
import { AppData } from 'src/app/singletons/app-data';

export enum AccountDialogMode {
  create,
  editByOa,
  editSelf
}

@Component({
  selector: 'app-account-dialog',
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.css']
})
export class AccountDialogComponent {

  public AccountDialogMode = AccountDialogMode;

  public organization: Organization;
  public accountDialogMode: AccountDialogMode;
  public account: AccountSimplified;
  public inputChanged = false;
  public willBeOa = false;

  public emailPlaceholder = '';

  constructor(
    public dialogRef: MatDialogRef<AccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      organization: Organization,
      account: AccountSimplified,
      accountDialogMode: AccountDialogMode,
    },
    private _accountService: AccountService,
    public dialog: MatDialog,
    private _translate: TranslateService,
    private _appData: AppData,
  ) {
    this.organization = data.organization;
    this.account = data.account;
    this.accountDialogMode = data.accountDialogMode;
    this.willBeOa = this.account.roles.indexOf('oa') !== -1;
    this._translate.get('WIDGETS.ACCOUNT_DIALOG.EMAIL_PLACEHOLDER').subscribe((res: string) => {
      this.emailPlaceholder = res;
    }).unsubscribe();

    if (this.accountDialogMode === AccountDialogMode.create) {
      this.account.categories = this.organization.categories.length === 1
        ? this.organization.categories
        : this.account.categories;
    }
  }

  public saveDisabled(): boolean {
    return this.account.name.length === 0 || !this.account.email
      || !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(this.account.email)
      || this.account.categories.length === 0
  }

  public onFieldInput(): void {
    this.inputChanged = true;
  }

  public categoriesChanged(): void {
    this.inputChanged = true;
  }

  public save(): void {
    if (this.account.name.length === 0) {
      this.dialogRef.close({ name: false });
      return;
    }
    if (!this.account.email
      || !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(this.account.email)) {
      this.dialogRef.close({ email: false });
      return;
    }
    if (this.account.categories.length === 0) {
      return;
    }

    // add 'oa' role if requested
    if (this.willBeOa && this.account.roles.length === 1) {
      this.account.roles.push('oa');
    } else if (!this.willBeOa && this.account.roles.length === 2) {
      this.account.roles = this.account.roles.filter(r => r !== 'oa');
    }

    switch (this.accountDialogMode) {
      case AccountDialogMode.editByOa:
        this._updateAccountByOa();
        break;
      case AccountDialogMode.editSelf:
        this._updateAccountSelf();
        break;
      default:
        this._createAccount();
        break;
    }
  }

  private _updateAccountByOa(): void {
    this._accountService.updateByOa(this.account, this.organization).subscribe(res => {
      if (res) {
        this.dialogRef.close(true);
      }
    }, () => {
      this.dialogRef.close({ err: true });
    });
  }

  private _updateAccountSelf(): void {
    this._accountService.updateSelf(this.account, this.organization).subscribe(res => {
      if (res) {
        this.dialogRef.close(true);
      }
    }, () => {
      this.dialogRef.close({ err: true });
    });
  }

  private _createAccount(): void {
    this._accountService.create(this.account, this.organization).subscribe(res => {
      if (res) {
        this.dialogRef.close(true);
      }
    }, (errorStatus) => {
      if (errorStatus === 409) {
        this.dialogRef.close({ err: true, conflict: true });
      } else {
        this.dialogRef.close({ err: true });
      }
    });
  }

  public isNotOaOnly(): boolean {
    return this.account.roles.length >= 2 || this.account.roles[0] !== 'oa';
  }

  public canAssignCategories(): boolean {
    return this.accountDialogMode === AccountDialogMode.create
      || this.accountDialogMode === AccountDialogMode.editByOa
      || (this.accountDialogMode === AccountDialogMode.editSelf && this.isNotOaOnly());
  }

  public canModifyWillBeOa(): boolean {
    return this.accountDialogMode === AccountDialogMode.create
      || (this.accountDialogMode === AccountDialogMode.editByOa && this.account._id !== this._appData.accountValue?._id);
  }
}
