import { AccountSimplified } from "./account";
import { ReportState } from "./report-state";

export class Report {
  _id!: string;
  organizationId!: string;
  detail!: Detail;
  states!: ReportState[];
  lastUpdated!: Date;
  relevantToAllRoles: boolean | undefined;
  seen!: boolean;

  summaryByCompetentPerson: string | undefined;

  summaryLongByCompetentPersonHtml: string | undefined;

  suggestionByCompetentPersonHtml: string | undefined;

  attachmentByCompetentPerson: Attachment | undefined;

  // populated only for 'oe' (for now)
  competentPerson: AccountSimplified | undefined;

  constructor(organizationId: string, detail: Detail) {
    this.organizationId = organizationId;
    this.detail = detail;
    this.states = [];
  }
}

export class Detail {
  number!: number;
  reportedAt!: Date;
  subject!: string;
  body!: string;
  categoryId: string;
  attachments!: Attachment[];
  whistleblowerDetail!: WhistleblowerDetail;
  notes!: Note[];

  constructor(subject: string, body: string, categoryId: string, attachments: Attachment[], whistleblowerName: string,
    whistleblowerDateOfBirth: Date | null, whistleblowerContactAddress: string) {
    this.reportedAt = new Date();
    this.subject = subject;
    this.body = body;
    this.categoryId = categoryId;
    this.attachments = attachments;
    this.whistleblowerDetail = new WhistleblowerDetail(whistleblowerName, whistleblowerDateOfBirth, whistleblowerContactAddress);
  }
}

export class Attachment {
  id!: string;
  name!: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class WhistleblowerDetail {
  name: string | null;
  dateOfBirth: Date | null;
  contactAddress: string | null;

  constructor(name: string | null, dateOfBirth: Date | null, contactAddress: string | null) {
    this.name = name;
    this.dateOfBirth = dateOfBirth;
    this.contactAddress = contactAddress;
  }
}

export class Note {
  id: number;
  order: number;
  body: string;

  constructor(id: number, body = '') {
    this.id = id;
    this.order = id;
    this.body = body;
  }
}

export class ReportForWhistleblower {
  _id!: string;
  organizationId!: string;
  detail!: Detail;
  identificationCode: string | undefined;
}