<div class="content-container">
  <div class="content-centered">
    <h1 class="custom-h1" translate>ORGANIZATION_ADMINISTRATION.FILES.HEADING</h1>

    <a [routerLink]="['/organization-administration']" class="a-btn a-primary top-left-action-button" translate>
      <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>
      ORGANIZATION_ADMINISTRATION.FILES.BACK
    </a>

    <div class="visual-container">
      <div class="file-list-container">
        <a href="assets/files/Pouceni-prislusne-osoby.docx" class="a-primary inline-button" download translate>
          ORGANIZATION_ADMINISTRATION.FILES.DOCUMENT_1
          <mat-icon fontSet="material-icons-outlined">file_download</mat-icon>
        </a>
        <a href="assets/files/Informacni-material-Reportys.docx" class="a-primary inline-button" download translate>
          ORGANIZATION_ADMINISTRATION.FILES.DOCUMENT_2
          <mat-icon fontSet="material-icons-outlined">file_download</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>