<ol class="stepper">
  <li class="step step-active step-no-action">
    <div class="step-number">{{ state.number }}</div>
    <div class="step-label">{{ 'OVERVIEW.DETAIL.STATE_LABELS.' + reportStateHelper.getStateLabelTranslateId(state, true) | translate:{ tillDays: reportStateHelper.getStateTillDays(report) } }}</div>
  </li>
</ol>

<button id="go-back" type="button" class="inline-button go-back-in-states-button" (click)="goBack()" translate>
  <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>GENERAL.STEP_BACK
</button>

<!-- Accept choice -->
<section *ngIf="componentState === componentStateEnum.AcceptChoice" class="shrinked shrinked-more-width">
  <section class="buttons-choice">
    <button type="button" class="button-primary" (click)="acceptMeasuresByCompetentPerson()" translate>
      OVERVIEW.DETAIL.SUGGESTION_BY_OE.ACCEPT_SUGGESTION_BY_CP
    </button>
    <button type="button" class="button-primary" (click)="goToSuggestMeasures()" translate>
      OVERVIEW.DETAIL.SUGGESTION_BY_OE.SUGGEST_DIFFERENT
    </button>

    <span translate>OVERVIEW.DETAIL.SUGGESTION_BY_OE.ACCEPT_SUGGESTION_BY_CP_HINT</span>
  </section>
</section>

<!-- Suggest measures -->
<section *ngIf="componentState === componentStateEnum.SuggestMeasures" class="shrinked shrinked-more-width">
  <label class="label-primary" translate>OVERVIEW.DETAIL.SUGGESTION_BY_OE.SUGGEST_DIFFERENT_LABEL</label>
  <div class="element-hint-below">
    <quill-editor class="editor" [(ngModel)]="state.suggestionByObligedEntityTextHtml" placeholder=""
      [styles]="{'min-height': '25rem'}" [maxLength]="100000" [required]="true" [sanitize]="true"
      (onContentChanged)="suggestionTextChanged($event)"></quill-editor>
    <div class="hint-multi">
      <span></span>
      <span
        *ngIf="suggestionByObligedEntityTextLength > 85000">{{ suggestionByObligedEntityTextLength }} / 100 000</span>
    </div>
  </div>

  <button type="button" class="button-primary button-confirm" (click)="suggestMeasures()" translate>
    OVERVIEW.DETAIL.SUGGESTION_BY_OE.CONFIRM_NEW_SUGGESTION
  </button>
</section>