/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Report } from '../models/report';
import { ReportEvaluationState, ReportState } from '../models/report-state';
import { Utilities } from './utilities';

export class ReportStateHelper {
  /**
   * This could be done as class method for each state, 
   * BUT states are just plain objects, not instances of their right classes (because of AJAX call)
   * So in order to make it function all states should be transformed using class-transformer library
   * which is costly so it is made where it truly does make a sense
   * @param state 
   * @param isLastTillNow 
   * @param showTillDays
   * @returns 
   */
  static getStateLabelTranslateId(state: ReportState, isLastTillNow: boolean, showTillDays = true): string {
    let specificState;
    switch (state.id) {
      case 'NotifyWhistleblowerAboutReportNoticingState':
        return isLastTillNow && showTillDays ? 'NOTIFY_WB_REPORT_NOTICING_TD' : 'NOTIFY_WB_REPORT_NOTICING';
      case 'ReportEvaluationState':
        specificState = state as ReportEvaluationState;
        if (specificState.extendedTwiceDate && !specificState.notifiedAboutExtendingTwice.dateNotified) {
          return showTillDays ? 'REPORT_EVALUATION_EXTEND_TD' : 'REPORT_EVALUATION_EXTEND';
        }
        if (specificState.extendedOnceDate && !specificState.notifiedAboutExtendingOnce.dateNotified) {
          return showTillDays ? 'REPORT_EVALUATION_EXTEND_TD' : 'REPORT_EVALUATION_EXTEND';
        }
        return isLastTillNow && showTillDays ? 'REPORT_EVALUATION_TD' : 'REPORT_EVALUATION';
      case 'JustifyReportNotJustState':
        return isLastTillNow && showTillDays ? 'JUSTIFY_NOT_JUST_TD' : 'JUSTIFY_NOT_JUST';
      case 'NotifyWhistleblowerAboutReportEvaluationState':
        return isLastTillNow && showTillDays ? 'NOTIFY_WB_EVALUATION_TD' : 'NOTIFY_WB_EVALUATION';
      case 'SuggestionForObligedEntityState':
        return isLastTillNow && showTillDays ? 'SUGGESTION_FOR_OE_TD' : 'SUGGESTION_FOR_OE';
      case 'WaitingForEvaluationByObligedEntityState':
        return 'WAITING_FOR_EVALUATION';
      case 'UnderstoodAcceptedMeasuresState':
        return isLastTillNow && showTillDays ? 'UNDERSTOOD_ACCEPTED_MEASURES_TD' : 'UNDERSTOOD_ACCEPTED_MEASURES';
      case 'NotifyWhistleblowerAboutAcceptedMeasuresState':
        return isLastTillNow && showTillDays ? 'NOTIFY_WB_ACCEPTED_MEASURES_TD' : 'NOTIFY_WB_ACCEPTED_MEASURES';
      case 'WaitingForExecutionByObligedEntityState':
        return 'WAITING_FOR_EXECUTION';
      case 'UnderstoodExecutionState':
        return isLastTillNow && showTillDays ? 'UNDERSTOOD_EXECUTION_TD' : 'UNDERSTOOD_EXECUTION';
      case 'NotifyWhistleblowerAboutExecutionState':
        return isLastTillNow && showTillDays ? 'NOTIFY_WB_EXECUTION_TD' : 'NOTIFY_WB_EXECUTION';
      case 'ObligedEntityUnderstoodReportState':
        return isLastTillNow && showTillDays ? 'OE_UNDERSTOOD_REPORT_TD' : 'OE_UNDERSTOOD_REPORT';
      case 'SuggestionByObligedEntityState':
        return isLastTillNow && showTillDays ? 'SUGGESTION_BY_OE_TD' : 'SUGGESTION_BY_OE';
      case 'NotifyCompetentPersonAboutAcceptedMeasuresState':
        return isLastTillNow && showTillDays ? 'NOTIFY_CP_ACCEPTED_MEASURES_TD' : 'NOTIFY_CP_ACCEPTED_MEASURES';
      case 'ExecutionState':
        return isLastTillNow && showTillDays ? 'EXECUTION_TD' : 'EXECUTION';
      case 'NotifyCompetentPersonAboutExecutionState':
        return isLastTillNow && showTillDays ? 'NOTIFY_CP_EXECUTION_TD' : 'NOTIFY_CP_EXECUTION';
      case 'GT_NoticeReportState':
        return 'GT_NOTICE_REPORT';
      case 'GT_NotifyObligedEntityState':
        return 'GT_NOTIFY_OE';
      case 'GT_WaitingForObligedEntityState':
        return 'GT_WAITING_FOR_OE';
      case 'GT_ObligedEntityUnderstoodState':
        return 'GT_OE_UNDERSTOOD';
      default:
        return 'ARCHIVED';
    }
  }

  /**
   * Gets number of days for last state of the report till deadline is over
   * @param report 
   * @returns number or boolean (means no tillDays, but is in progress, GT states), if waiting => null, if state is archive => undefined
   */
  static getStateTillDays(report: Report): number | boolean | undefined | null {
    const state = report.states[report.states.length - 1];
    let createdMinusTodayDays = Utilities.daysBetweenDates(new Date(state.createdAt), new Date());
    let specificState;
    let result = 0;
    switch (state.id) {
      case 'NotifyWhistleblowerAboutReportNoticingState':
        result = 7 - createdMinusTodayDays;
        break;
      case 'ReportEvaluationState':
      case 'JustifyReportNotJustState':
      case 'NotifyWhistleblowerAboutReportEvaluationState':
        // this state needs to be calculated from reportedAt date, not state creation date
        createdMinusTodayDays = Utilities.daysBetweenDates(new Date(report.detail.reportedAt), new Date());
        specificState = report.states.find(state => state.id === 'ReportEvaluationState') as ReportEvaluationState;
        if (specificState.extendedTwiceDate && !specificState.notifiedAboutExtendingTwice.dateNotified) {
          // notify about extend twice deadline
          // 30 days from reportedAt + extendedOnceDaysCount, but min 5 days from extending
          result = Math.min(
            5 - Utilities.daysBetweenDates(new Date(specificState.extendedTwiceDate), new Date()),
            30 - createdMinusTodayDays + (specificState.extendedOnceDaysCount ?? 0),
          );
        } else if (specificState.extendedOnceDate && !specificState.notifiedAboutExtendingOnce.dateNotified) {
          // notify about extend once deadline
          // 30 days from reportedAt, but min 5 days from extending
          result = Math.min(
            5 - Utilities.daysBetweenDates(new Date(specificState.extendedOnceDate), new Date()),
            30 - createdMinusTodayDays,
          );
        } else {
          // not notify about extend deadline
          result = 30 - createdMinusTodayDays
            + (specificState.extendedOnceDate && specificState.extendedOnceDaysCount ? specificState.extendedOnceDaysCount : 0)
            + (specificState.extendedTwiceDate && specificState.extendedTwiceDaysCount ? specificState.extendedTwiceDaysCount : 0);
        }
        break;
      case 'SuggestionForObligedEntityState':
        result = 14 - createdMinusTodayDays;
        break;
      case 'WaitingForEvaluationByObligedEntityState':
        return null;
      case 'UnderstoodAcceptedMeasuresState':
        result = 5 - createdMinusTodayDays;
        break;
      case 'NotifyWhistleblowerAboutAcceptedMeasuresState':
        result = 5 - createdMinusTodayDays;
        break;
      case 'WaitingForExecutionByObligedEntityState':
        return null;
      case 'UnderstoodExecutionState':
        result = 5 - createdMinusTodayDays;
        break;
      case 'NotifyWhistleblowerAboutExecutionState':
        result = 5 - createdMinusTodayDays;
        break;
      case 'ObligedEntityUnderstoodReportState':
        result = 5 - createdMinusTodayDays;
        break;
      case 'SuggestionByObligedEntityState':
        // this state needs to be calculated from reportedAt date, not state creation date
        createdMinusTodayDays = Utilities.daysBetweenDates(new Date(report.detail.reportedAt), new Date());
        result = 30 - createdMinusTodayDays;
        break;
      case 'NotifyCompetentPersonAboutAcceptedMeasuresState':
        result = 5 - createdMinusTodayDays;
        break;
      case 'ExecutionState':
        return null;
      case 'NotifyCompetentPersonAboutExecutionState':
        result = 5 - createdMinusTodayDays;
        break;
      case 'GT_NoticeReportState':
        return false;
      case 'GT_NotifyObligedEntityState':
        return false;
      case 'GT_WaitingForObligedEntityState':
        return null;
      case 'GT_ObligedEntityUnderstoodState':
        return false;
      default:
        // archive
        return undefined;
    }
    return result < 0 ? 0 : result;
  }
}