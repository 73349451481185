<div class="content-container">
  <div class="content-centered">
    <h1 class="custom-h1" translate>AUTH.RESET_PASSWORD.REQUEST.HEADING</h1>
    <div class="visual-container">
      <form>
        <label for="email" class="label-primary" translate>AUTH.RESET_PASSWORD.REQUEST.EMAIL_LABEL</label>
        <div class="element-hint-below">
          <input id="email" name="email" type="email" class="input-primary"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" [placeholder]="emailPlaceholder" [(ngModel)]="email"
            required #_email="ngModel" />
          <div class="hint-single">
            <span translate>AUTH.RESET_PASSWORD.REQUEST.EMAIL_HINT</span>
          </div>
        </div>
        <button type="submit" class="button-primary button-confirm" (click)="sendResetPasswordRequest()" translate>
          AUTH.RESET_PASSWORD.REQUEST.CONFIRM
        </button>
      </form>
    </div>
  </div>
</div>