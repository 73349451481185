import { Injectable } from '@angular/core';

import { AppData } from '../singletons/app-data';

@Injectable({ providedIn: 'root' })
export class RoleCpOrOeGuard  {
  constructor(
    private _appData: AppData
  ) { }

  canActivate(): boolean {
    if (this._appData.accountValue?.roles.indexOf('cp') !== -1
      || this._appData.accountValue?.roles.indexOf('oe') !== -1) {
      return true; // has 'oa' role
    }
    return false;
  }
}