import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppData } from '../singletons/app-data';

@Injectable({ providedIn: 'root' })
export class ActiveReportGuard  {
    constructor(
        private router: Router,
        private _appData: AppData
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this._appData.activeReportValue) {
            return true;
        }
        this.router.navigate(['/overview/reports'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}