import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Message } from '../models/message';
import { Report } from '../models/report';

@Injectable({
  providedIn: 'root'
})
export class ReportStateService {
  private _reportStateApiUrl = 'api/report/state';

  constructor(
    private _http: HttpClient,
  ) { }

  send(routeIdentifier: string, reportId: string, lastUpdated: Date, reportStateFormData: FormData)
    : Observable<{ report: Report, message: Message | undefined }> {
    reportStateFormData.set('reportId', reportId);
    reportStateFormData.set('lastUpdated', new Date(lastUpdated).valueOf().toString());

    return this._http.post<{ report: Report, message: Message | undefined }>(`${this._reportStateApiUrl}/${routeIdentifier}`, reportStateFormData)
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  downloadAttachment(routeIdentifier: string, reportId: string, attachmentId: string): Observable<Blob> {
    return this._http.get<Blob>(`${this._reportStateApiUrl}/${routeIdentifier}/${reportId}/attachment/${attachmentId}`, {
      responseType: 'blob' as 'json'
    })
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  convertHtmlToDocx(html: string): Observable<Blob> {
    return this._http.post<Blob>(`${this._reportStateApiUrl}/html_to_docx_convert`,
      { html: html },
      {
        responseType: 'blob' as 'json'
      }
    )
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }
}
