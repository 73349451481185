import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { Report } from 'src/app/models/report';
import { JustifyReportNotJustState, NotifyWhistleblowerAboutReportEvaluationState } from 'src/app/models/report-state';
import { ReportStateService } from 'src/app/services/report-state.service';
import { AppData } from 'src/app/singletons/app-data';
import { Constants } from 'src/app/utilities/constants';
import { ReportStateHelper } from 'src/app/utilities/report-state-helper';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-notify-whistleblower-about-report-evaluation-state',
  templateUrl: './notify-whistleblower-about-report-evaluation-state.component.html',
  styleUrls: ['./notify-whistleblower-about-report-evaluation-state.component.css']
})
export class NotifyWhistleblowerAboutReportEvaluationStateComponent implements OnInit, OnDestroy {

  public reportStateHelper = ReportStateHelper;

  public report!: Report;

  private _activeReportStateSubscription$!: Subscription;
  public state!: NotifyWhistleblowerAboutReportEvaluationState;

  public stateFormData = new FormData();

  private _stateApi = '';

  constructor(
    private _appData: AppData,
    private _reportStateService: ReportStateService,
    public app: AppComponent,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    if (!this._appData.activeReportValue) {
      return;
    }

    this.report = this._appData.activeReportValue;
    this._activeReportStateSubscription$ = this._appData.activeReportState.subscribe((state) => {
      if (!state) {
        return;
      }

      this.state = state as NotifyWhistleblowerAboutReportEvaluationState;
      this._stateApi = Constants.stateApiMap.get(this.state.id) ?? '';

      // makes sense only when report is not just, else it would be undefined (and reason would be undefined, but again, it is important only when not just)
      const reasonState = this.report.states.find(state => state.id === 'JustifyReportNotJustState') as JustifyReportNotJustState | undefined;

      if (!this.state.notifyAboutReportEvaluation.notifyNowTextHtml) {
        this.translate.get(`OVERVIEW.DETAIL.NOTIFY_WB_EVALUATION.T_TEXT${this.state.isReportJust ? '' : '_NOT'}_JUST_HTML`, {
          date: Utilities.dateFormat(this.report.detail.reportedAt),
          reason: reasonState?.justifyReportNotJustTextHtml,
          cpName: this._appData.accountValue?.name ?? '',
          orgName: this._appData.organizationValue.name
        }).subscribe((res: string) => {
          this.state.notifyAboutReportEvaluation.notifyNowTextHtml = res;
        }).unsubscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this._activeReportStateSubscription$.unsubscribe();
  }

  public goBack(): void {
    this._appData.setActiveReportState(null);
  }

  public goToNextStateNotifyNow(): void {
    if (!this.state.notifyAboutReportEvaluation.notifyNowTextHtml || this.state.notifyAboutReportEvaluation.notifyNowTextHtml.trim().length === 0) {
      this.translate.get('OVERVIEW.DETAIL.N_FILL_TEXT_FOR_WB').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    this.stateFormData = new FormData();
    this.stateFormData.set('state', JSON.stringify(this.state));

    this.app.showLoading();
    this._reportStateService.send(`${this._stateApi}/notify_now`, this.report._id, this.report.lastUpdated, this.stateFormData).subscribe(res => {
      if (res) {
        // replace old report with the new one
        this.report = res.report;
        this._appData.setActiveReport(res.report);
        this._appData.replaceSingleReport(res.report);
        this._appData.setNewMessage(res.message);
        // set active report to null (=> redirect to states overview)
        this._appData.setActiveReportState(null);
        this.translate.get('OVERVIEW.DETAIL.NOTIFY_WB_EVALUATION.N_WAS_NOTIFIED_NOT_JUST_REPORT_FINISHED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      }
      this.app.hideLoading();
    }, (errorStatus) => {
      if (errorStatus === 409) {
        this.translate.get('GENERAL.N_DATA_CHANGED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      } else if (errorStatus < 500) {
        this.translate.get('GENERAL.N_BAD_REQUEST_OR_UNAUTHORIZED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      } else {
        this.translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      }
      this.app.hideLoading();
    });
  }
}