<div class="content-container">
  <div class="content-centered">
    <h1 class="custom-h1" translate>OVERVIEW.REPORTS.HEADING</h1>

    <button type="button" class="button-primary top-left-action-button" (click)="reloadData()" translate>
      OVERVIEW.REPORTS.RELOAD<mat-icon fontSet="material-icons-outlined">sync</mat-icon>
    </button>

    <input id="search-input" class="input-primary" [(ngModel)]="advancedFilter.fullTextRaw" (keyup)="applyFilter()"
      [placeholder]="searchPlaceholder" #searchinput>

    <!-- @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ -->
    <!-- Advanced Filter -->
    <button type="button" class="inline-button" aria-label="Toggle advanced filter" (click)="toggleAdvancedFilter()"
      translate>
      OVERVIEW.REPORTS.ADVANCED_FILTER
      <mat-icon fontSet="material-icons-outlined">{{ advancedFilterVisible ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
    <section id="advanced-filter-container" class="height-transition-container"
      [class]="advancedFilterVisible ? 'height-transition-visible' : 'height-transition-hidden'">
      <div></div>
      <div></div>

      <label for="advanced-filter-number" class="label-primary"
        translate>OVERVIEW.REPORTS.ADVANCED_FILTER_NUMBER_LABEL</label>
      <input id="advanced-filter-number" name="advanced-filter-number" type="number" class="input-primary"
        [(ngModel)]="advancedFilter.number" #advancedfilternumber="ngModel">

      <label for="advanced-filter-date-from" class="label-primary"
        translate>OVERVIEW.REPORTS.ADVANCED_FILTER_DATE_FROM_LABEL</label>
      <input id="advanced-filter-date-from" name="advanced-filter-date-from" type="date" class="input-primary"
        [ngModel]="advancedFilter.dateFrom | date:'yyyy-MM-dd'" (ngModelChange)="advancedFilter.dateFrom = $event"
        #advancedfilterdatefrom="ngModel">

      <label for="advanced-filter-date-to" class="label-primary"
        translate>OVERVIEW.REPORTS.ADVANCED_FILTER_DATE_TO_LABEL</label>
      <input id="advanced-filter-date-to" name="advanced-filter-date-to" type="date" class="input-primary"
        [ngModel]="advancedFilter.dateTo | date:'yyyy-MM-dd'" (ngModelChange)="advancedFilter.dateTo = $event"
        #advancedfilterdateto="ngModel">

      <label for="advanced-filter-state-select" class="label-primary"
        translate>OVERVIEW.REPORTS.ADVANCED_FILTER_STATE_LABEL</label>
      <select id="advanced-filter-state-select" name="advanced-filter-state-select" class="select-primary"
        [(ngModel)]="advancedFilter.state">
        <option *ngFor="let stateLabelTranslateId of advancedFilterStateLabels" [ngValue]="stateLabelTranslateId">
          {{ stateLabelTranslateId === '-' ? stateLabelTranslateId : ('OVERVIEW.DETAIL.STATE_LABELS.' +
          stateLabelTranslateId | translate)
          }}
        </option>
      </select>

      <ng-container *ngIf="utilities.canAccountEdit(account)">
        <label for="advanced-filter-deadline-select" class="label-primary"
          translate>OVERVIEW.REPORTS.ADVANCED_FILTER_DEADLINE_LABEL</label>
        <select id="advanced-filter-deadline-select" name="advanced-filter-deadline-select" class="select-primary"
          [(ngModel)]="advancedFilter.deadline">
          <option *ngFor="let deadlineLabelTranslateId of advancedFilterDeadlineLabels"
            [ngValue]="deadlineLabelTranslateId">
            {{ deadlineLabelTranslateId === '-' ? deadlineLabelTranslateId : ('OVERVIEW.REPORTS.' +
            deadlineLabelTranslateId | translate) }}
          </option>
        </select>
      </ng-container>

      <div></div>
      <div></div>

      <div id="advanced-filter-btn-container">
        <button id="advanced-filter-btn-cancel" type="button" class="button-primary button-shrinked"
          aria-label="Cancel advanced filter" (click)="cancelAdvancedFilter()"
          translate>OVERVIEW.REPORTS.ADVANCED_FILTER_CANCEL<mat-icon fontSet="material-icons-outlined">cancel
          </mat-icon>
        </button>
        <button id="advanced-filter-btn-apply" type="button" class="button-primary button-shrinked button-confirm"
          aria-label="Apply advanced filter" (click)="applyAdvancedFilter()"
          translate>OVERVIEW.REPORTS.ADVANCED_FILTER_APPLY<mat-icon fontSet="material-icons-outlined">filter_alt
          </mat-icon>
        </button>
      </div>

      <div></div>
      <div></div>
    </section>

    <div id="reports-container">
      <mat-tab-group [selectedIndex]="activeCategory ? reportCategories.indexOf(activeCategory) : null"
        (selectedTabChange)="setActiveCategory($event)" mat-align-tabs="center" animationDuration="0ms" dynamicHeight
        [class.header-less-tabs]="reportCategories.length === 1">
        <mat-tab *ngFor="let category of reportCategories">
          <ng-template mat-tab-label>
            <span [matBadge]="categoryNewReportsCountMap.get(category.id)"
              [matBadgeHidden]="categoryNewReportsCountMap.get(category.id) === 0" class="category-name-tab">{{
              utilities.shortText(category.name, 20) }}</span>
          </ng-template>

          <!-- @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ -->
          <!-- Reports: Competent Person view -->
          <mat-table *ngIf="activeRole === 'cp'" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef class="cell-smaller-1"></mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell-smaller-1">
                <button [class]="getVisualStateInfoClassButton(row)" type="button" class="icon-button"
                  aria-label="Go to detail">
                  <mat-icon fontSet="material-icons-outlined">{{ getVisualStateInfoIcon(row) }}</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="utilities.canAccountEdit(this.account)" matColumnDef="tillDays">
              <mat-header-cell *matHeaderCellDef class="cell-smaller-1" mat-sort-header>{{
                'OVERVIEW.REPORTS.TILL_DAYS_LABEL' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [attr.data-label]="'OVERVIEW.REPORTS.TILL_DAYS_LABEL' | translate"
                class="cell-smaller-1"
                [class]="row.seen || !utilities.canAccountEdit(this.account) ? '' : 'visual-new'">{{
                reportStateHelper.getStateTillDays(row) ?
                reportStateHelper.getStateTillDays(row) :
                (reportStateHelper.getStateTillDays(row) === 0 ? '!' : '-') }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="number">
              <mat-header-cell *matHeaderCellDef class="cell-smaller-1" mat-sort-header>{{
                'OVERVIEW.REPORTS.NUMBER_LABEL' |
                translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [attr.data-label]="'OVERVIEW.REPORTS.NUMBER_LABEL' | translate"
                class="cell-smaller-1"
                [class]="row.seen || !utilities.canAccountEdit(this.account) ? '' : 'visual-new'">{{
                row.detail.number }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="reportedAt">
              <mat-header-cell *matHeaderCellDef class="cell-smaller-2" mat-sort-header>{{
                'OVERVIEW.REPORTS.REPORTED_AT_LABEL' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [attr.data-label]="'OVERVIEW.REPORTS.REPORTED_AT_LABEL' | translate"
                class="cell-smaller-2"
                [class]="row.seen || !utilities.canAccountEdit(this.account) ? '' : 'visual-new'">{{
                utilities.dateFormat(row.detail.reportedAt) }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="subject">
              <mat-header-cell *matHeaderCellDef class="cell-bigger-1" mat-sort-header>{{
                'OVERVIEW.REPORTS.SUBJECT_LABEL' |
                translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [attr.data-label]="'OVERVIEW.REPORTS.SUBJECT_LABEL' | translate"
                class="cell-bigger-1" [class]="row.seen || !utilities.canAccountEdit(this.account) ? '' : 'visual-new'">
                {{
                utilities.shortText(row.detail.subject) }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="state">
              <mat-header-cell *matHeaderCellDef>{{ 'OVERVIEW.REPORTS.STATE_LABEL' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [attr.data-label]="'OVERVIEW.REPORTS.STATE_LABEL' | translate"
                [class]="row.seen || !utilities.canAccountEdit(this.account) ? '' : 'visual-new'">{{
                reportStateLabelMap.get(row._id) ?
                reportStateLabelMap.get(row._id) : '' }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="note">
              <mat-header-cell *matHeaderCellDef>{{ 'OVERVIEW.REPORTS.NOTES_LABEL' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let row" [attr.data-label]="'OVERVIEW.REPORTS.NOTES_LABEL' | translate">
                <div class="notes-container">
                  <button *ngFor="let note of row.detail.notes" type="button" class="inline-button note-notice"
                    aria-label="Open note" (click)="openNote(row, note)">
                    <mat-icon fontSet="material-icons-outlined">{{ utilities.canAccountEdit(account) ? 'edit' :
                      'north_east'
                      }}</mat-icon><span>{{ utilities.shortText(note.body)
                      }}</span>
                  </button>
                  <button *ngIf="utilities.canAccountEdit(account) && row.detail.notes.length < 5" type="button"
                    class="inline-button note-notice" aria-label="Add new note" (click)="addNote(row)">
                    <mat-icon fontSet="material-icons-outlined">add_circle_outline</mat-icon><span
                      translate>OVERVIEW.REPORTS.ADD_NOTE</span>
                  </button>
                </div>
              </mat-cell>
            </ng-container>

            <!-- Header and Row Declarations -->
            <mat-header-row *matHeaderRowDef="headersCp">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: headersCp" (click)="goToDetail(row)">
            </mat-row>

            <!-- Row shown when there is no matching data. -->
            <tr id="no-data-row" class="mat-row" *matNoDataRow>
              <td *ngIf="reports.length === 0" class="mat-cell" translate>OVERVIEW.REPORTS.REPORTS_EMPTY_ROW</td>
              <td *ngIf="reports.length !== 0" class="mat-cell" translate
                [translateParams]="{searchValue: searchinput.value}">OVERVIEW.REPORTS.SEARCH_EMPTY_ROW</td>
            </tr>
          </mat-table>

          <!-- @@@@@@@@@@@@@@@@@@@@@@@@@@@@ -->
          <!-- Reports: Obliged Entity view -->
          <mat-table *ngIf="activeRole === 'oe'" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef class="cell-smaller-1"></mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell-smaller-1">
                <button [class]="getVisualStateInfoClassButton(row)" type="button" class="icon-button"
                  aria-label="Go to detail">
                  <mat-icon fontSet="material-icons-outlined">{{ getVisualStateInfoIcon(row) }}</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <ng-container *ngIf="utilities.canAccountEdit(this.account)" matColumnDef="tillDays">
              <mat-header-cell *matHeaderCellDef class="cell-smaller-1" mat-sort-header>{{
                'OVERVIEW.REPORTS.TILL_DAYS_LABEL' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [attr.data-label]="'OVERVIEW.REPORTS.TILL_DAYS_LABEL' | translate"
                class="cell-smaller-1"
                [class]="row.seen || !utilities.canAccountEdit(this.account) ? '' : 'visual-new'">{{
                reportStateHelper.getStateTillDays(row) ? reportStateHelper.getStateTillDays(row)
                :
                (reportStateHelper.getStateTillDays(row) === 0 ?
                '!' : '-') }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="number">
              <mat-header-cell *matHeaderCellDef class="cell-smaller-1" mat-sort-header>{{
                'OVERVIEW.REPORTS.NUMBER_LABEL' |
                translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [attr.data-label]="'OVERVIEW.REPORTS.NUMBER_LABEL' | translate"
                class="cell-smaller-1"
                [class]="row.seen || !utilities.canAccountEdit(this.account) ? '' : 'visual-new'">{{
                row.detail.number }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="reportedByCompetentPerson">
              <mat-header-cell *matHeaderCellDef class="cell-smaller-2" mat-sort-header>{{
                'OVERVIEW.REPORTS.OE_DATE_LABEL'
                | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [attr.data-label]="'OVERVIEW.REPORTS.OE_DATE_LABEL' | translate"
                class="cell-smaller-2"
                [class]="row.seen || !utilities.canAccountEdit(this.account) ? '' : 'visual-new'">{{
                utilities.dateFormat(row.detail.reportedAt) }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="competentPerson">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'OVERVIEW.REPORTS.OE_CP_LABEL' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [attr.data-label]="'OVERVIEW.REPORTS.OE_CP_LABEL' | translate"
                [class]="row.seen || !utilities.canAccountEdit(this.account) ? '' : 'visual-new'">{{
                row.competentPerson?.name ?
                row.competentPerson.name : '-' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="summaryByCompetentPerson">
              <mat-header-cell *matHeaderCellDef class="cell-bigger-1" mat-sort-header>{{
                'OVERVIEW.REPORTS.OE_SUBJECT_LABEL' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [attr.data-label]="'OVERVIEW.REPORTS.OE_SUBJECT_LABEL' | translate"
                class="cell-bigger-1" [class]="row.seen || !utilities.canAccountEdit(this.account) ? '' : 'visual-new'">
                {{
                utilities.shortText(row.summaryByCompetentPerson) }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="state">
              <mat-header-cell *matHeaderCellDef>{{ 'OVERVIEW.REPORTS.STATE_LABEL' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [attr.data-label]="'OVERVIEW.REPORTS.STATE_LABEL' | translate"
                [class]="row.seen || !utilities.canAccountEdit(this.account) ? '' : 'visual-new'">{{
                reportStateLabelMap.get(row._id) ?
                reportStateLabelMap.get(row._id) : '' }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="note">
              <mat-header-cell *matHeaderCellDef>{{ 'OVERVIEW.REPORTS.NOTES_LABEL' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let row" [attr.data-label]="'OVERVIEW.REPORTS.NOTES_LABEL' | translate">
                <div class="notes-container">
                  <button *ngFor="let note of row.detail.notes" type="button" class="inline-button note-notice"
                    aria-label="Open note" (click)="openNote(row, note)">
                    <mat-icon fontSet="material-icons-outlined">{{ utilities.canAccountEdit(account) ? 'edit' :
                      'north_east'
                      }}</mat-icon><span>{{ utilities.shortText(note.body)
                      }}</span>
                  </button>
                  <button *ngIf="utilities.canAccountEdit(account) && row.detail.notes.length < 5" type="button"
                    class="inline-button note-notice" aria-label="Add new note" (click)="addNote(row)">
                    <mat-icon fontSet="material-icons-outlined">add_circle_outline</mat-icon><span
                      translate>OVERVIEW.REPORTS.ADD_NOTE</span>
                  </button>
                </div>
              </mat-cell>
            </ng-container>

            <!-- Header and Row Declarations -->
            <mat-header-row *matHeaderRowDef="headersOe">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: headersOe" (click)="goToDetail(row)">
            </mat-row>

            <!-- Row shown when there is no matching data. -->
            <tr id="no-data-row" class="mat-row" *matNoDataRow>
              <td *ngIf="reports.length === 0" class="mat-cell" translate>OVERVIEW.REPORTS.REPORTS_EMPTY_ROW</td>
              <td *ngIf="reports.length !== 0" class="mat-cell" translate>OVERVIEW.REPORTS.SEARCH_EMPTY_ROW</td>
            </tr>
          </mat-table>
        </mat-tab>
      </mat-tab-group>

      <mat-paginator [pageSizeOptions]="[20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>