/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ComponentPortal } from '@angular/cdk/portal';
import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContentChange } from 'ngx-quill';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { Account } from 'src/app/models/account';
import { Message } from 'src/app/models/message';
import { Attachment, Note, Report } from 'src/app/models/report';
import { ReportCategory } from 'src/app/models/report-category';
import { ReportState } from 'src/app/models/report-state';
import { MessageService } from 'src/app/services/message.service';
import { ReportService } from 'src/app/services/report.service';
import { AppData } from 'src/app/singletons/app-data';
import { Constants } from 'src/app/utilities/constants';
import { ReportStateComponentPortalGenerator } from 'src/app/utilities/report-state-component-portal-generator';
import { Utilities } from 'src/app/utilities/utilities';
import { ChangeCategoryDialogComponent } from '../../widgets/change-category-dialog/change-category-dialog.component';
import { ConfirmDialogComponent } from '../../widgets/confirm-dialog/confirm-dialog.component';
import { NoteDialogComponent } from '../../widgets/note-dialog/note-dialog.component';
import { ExecutionStateComponent } from './execution-state/execution-state.component';
import { GtNoticeReportStateComponent } from './gt-notice-report-state/gt-notice-report-state.component';
import { GtNotifyObligedEntityStateComponent } from './gt-notify-obliged-entity-state/gt-notify-obliged-entity-state.component';
import { GtObligedEntityUnderstoodStateComponent } from './gt-obliged-entity-understood-state/gt-obliged-entity-understood-state.component';
import { JustifyReportNotJustStateComponent } from './justify-report-not-just-state/justify-report-not-just-state.component';
import { NotifyCompetentPersonAboutAcceptedMeasuresStateComponent } from './notify-competent-person-about-accepted-measures-state/notify-competent-person-about-accepted-measures-state.component';
import { NotifyCompetentPersonAboutExecutionStateComponent } from './notify-competent-person-about-execution-state/notify-competent-person-about-execution-state.component';
import { NotifyWhistleblowerAboutAcceptedMeasuresStateComponent } from './notify-whistleblower-about-accepted-measures-state/notify-whistleblower-about-accepted-measures-state.component';
import { NotifyWhistleblowerAboutExecutionStateComponent } from './notify-whistleblower-about-execution-state/notify-whistleblower-about-execution-state.component';
import { NotifyWhistleblowerAboutReportEvaluationStateComponent } from './notify-whistleblower-about-report-evaluation-state/notify-whistleblower-about-report-evaluation-state.component';
import { NotifyWhistleblowerAboutReportNoticingStateComponent } from './notify-whistleblower-about-report-noticing-state/notify-whistleblower-about-report-noticing-state.component';
import { ObligedEntityUnderstoodReportStateComponent } from './obliged-entity-understood-report-state/obliged-entity-understood-report-state.component';
import { ReportEvaluationStateComponent } from './report-evaluation-state/report-evaluation-state.component';
import { StatesOverviewComponent } from './states-overview/states-overview.component';
import { SuggestionByObligedEntityStateComponent } from './suggestion-by-obliged-entity-state/suggestion-by-obliged-entity-state.component';
import { SuggestionForObligedEntityStateComponent } from './suggestion-for-obliged-entity-state/suggestion-for-obliged-entity-state.component';
import { UnderstoodAcceptedMeasuresStateComponent } from './understood-accepted-measures-state/understood-accepted-measures-state.component';
import { UnderstoodExecutionStateComponent } from './understood-execution-state/understood-execution-state.component';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit, AfterViewChecked, OnDestroy {

  statesComponentPortal!: ComponentPortal<
    StatesOverviewComponent
    | NotifyWhistleblowerAboutReportNoticingStateComponent
    | ReportEvaluationStateComponent
    | JustifyReportNotJustStateComponent
    | NotifyWhistleblowerAboutReportEvaluationStateComponent
    | SuggestionForObligedEntityStateComponent
    | UnderstoodAcceptedMeasuresStateComponent
    | NotifyWhistleblowerAboutAcceptedMeasuresStateComponent
    | UnderstoodExecutionStateComponent
    | NotifyWhistleblowerAboutExecutionStateComponent
    | ObligedEntityUnderstoodReportStateComponent
    | SuggestionByObligedEntityStateComponent
    | NotifyCompetentPersonAboutAcceptedMeasuresStateComponent
    | ExecutionStateComponent
    | NotifyCompetentPersonAboutExecutionStateComponent
    | GtNoticeReportStateComponent
    | GtNotifyObligedEntityStateComponent
    | GtObligedEntityUnderstoodStateComponent>;

  public utilities = Utilities;

  private _activeRoleSubscription$!: Subscription;
  public activeRole!: string;

  private _activeReportSubscription$!: Subscription;
  public report!: Report;

  private _activeReportStateSubscription$!: Subscription;
  public activeReportState!: ReportState;
  public reportCategory: ReportCategory | undefined = undefined;

  public account!: Account;

  public messagesLoading = true;

  // used for 'cp' to show messages related to 'wb' or 'oe'
  public cpViewingMessagesRelatedTo = 'wb';
  // general messages, used for fetching and by 'oe' in the view
  public messages: Message[] | undefined = undefined;
  // filtered arrays for 'cp'
  public messagesRelatedToWb: Message[] | undefined = undefined;
  public messagesRelatedToOe: Message[] | undefined = undefined;

  public newMessageTextHtml = '';
  public newMessageTextLength = 0;

  private _attachmentLastId = 0;
  public attachment: Attachment | undefined;
  private _messageFormData = new FormData();

  private _previousMessagesContainerScrollHeight = 0;
  @ViewChild('messagescontainer') private _messagesContainer!: ElementRef;

  private _newMessageSubscription$!: Subscription;

  constructor(
    public appData: AppData,
    private _reportService: ReportService,
    private _messageService: MessageService,
    public app: AppComponent,
    public dialog: MatDialog,
    private _translate: TranslateService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    if (!this.appData.accountValue) {
      return;
    }

    this.account = this.appData.accountValue;
    this._activeRoleSubscription$ = this.appData.activeRole.subscribe(activeRole => {
      if (!activeRole) {
        return;
      }

      this.activeRole = activeRole;
    });

    this._activeReportSubscription$ = this.appData.activeReport.subscribe((activeReport) => {
      if (!activeReport) {
        return;
      }

      this.report = activeReport;
      this.reportCategory = this.appData.organizationValue.categories.find(c => c.id === this.report.detail.categoryId);
    });

    // choose which component should be loaded through the portal
    this._activeReportStateSubscription$ = this.appData.activeReportState.subscribe((activeReportState) => {
      if (!activeReportState) {
        this.statesComponentPortal = new ComponentPortal(StatesOverviewComponent);
        return;
      }

      this.statesComponentPortal = ReportStateComponentPortalGenerator.generate(activeReportState.id);
    });

    // load messages
    this._loadMessages();

    // on new message emit
    this._newMessageSubscription$ = this.appData.newMessage.subscribe((message) => {
      if (!message) {
        return;
      }

      if (this.activeRole === 'cp') {
        if (this.messages === undefined || this.messagesRelatedToWb === undefined || this.messagesRelatedToOe === undefined) {
          // if messages are not correctly loaded, try again (do not add new message, because it would be loaded with the request)
          this._loadMessages();
          return;
        }

        if (message.sentTo === 'wb') {
          this.messagesRelatedToWb.push(message);
        } else {
          this.messagesRelatedToOe.push(message);
        }
      } else {
        if (this.messages === undefined) {
          // if messages are not correctly loaded, try again (do not add new message, because it would be loaded with the request)
          this._loadMessages();
          return;
        }

        this.messages.push(message);
      }
    });
  }

  ngOnDestroy(): void {
    this._activeRoleSubscription$.unsubscribe();
    this._activeReportSubscription$.unsubscribe();
    this._activeReportStateSubscription$.unsubscribe();
    this._newMessageSubscription$?.unsubscribe();
  }

  public changeCategory(): void {
    // empty category
    let chosenCategory = new ReportCategory();
    const dialogRef = this.dialog.open(ChangeCategoryDialogComponent, {
      width: '40rem',
      panelClass: 'dialog-container',
      data: {
        categories: this.appData.organizationValue.categories.filter(c => c.id !== this.reportCategory?.id),
        chosenCategory: chosenCategory,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result || result.id === '') {
        return;
      }
      chosenCategory = result;

      this.app.showLoading();
      this._reportService
        .changeCategory(this.report._id, chosenCategory.id)
        .subscribe(res => {
          if (res) {
            this._router.navigate(['/overview/reports'], { replaceUrl: true });
            this.appData.setReports(null);

            this._translate.get('OVERVIEW.DETAIL.N_CATEGORY_CHANGED').subscribe((res: string) => {
              this.app.buildNotification(res);
            }).unsubscribe();
          }
          this.app.hideLoading();
        }, () => {
          this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
          this.app.hideLoading();
        });
    });
  }

  public downloadAttachment(attachment: Attachment): void {
    this.app.showLoading();
    this._reportService.downloadAttachment(this.report._id, attachment.id, this.activeRole).subscribe((res) => {
      if (res) {
        Utilities.downloadFile(res, attachment.name);
        this._translate.get('GENERAL.N_FILE_DOWNLOADED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      }
      this.app.hideLoading();
    }, () => {
      this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      this.app.hideLoading();
    });
  }

  public openNote(report: Report, note: Note): void {
    const noteCopy = new Note(note.id, note.body);

    const dialogRef = this.dialog.open(NoteDialogComponent, {
      width: '40rem',
      panelClass: 'dialog-container',
      data: { note: noteCopy, isEditing: true, report: report, activeRole: this.activeRole, canEdit: Utilities.canAccountEdit(this.account) }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this._translate.get('GENERAL.N_CHANGES_SUCCESSFUL').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
    });
  }

  public addNote(report: Report): void {
    const lastNoteId = (report.detail.notes && report.detail.notes.length > 0) ? report.detail.notes[report.detail.notes.length - 1].id : 0;

    const newNote = new Note(lastNoteId + 1);

    const dialogRef = this.dialog.open(NoteDialogComponent, {
      width: '40rem',
      panelClass: 'dialog-container',
      data: { note: newNote, isEditing: false, report: report, activeRole: this.activeRole, canEdit: Utilities.canAccountEdit(this.account) }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this._translate.get('OVERVIEW.N_NOTE_SAVED').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
    });
  }

  // MESSAGES (CHAT)

  private _loadMessages(): void {
    this.messagesLoading = true;
    this._messageService.getAll(this.report._id, undefined, this.activeRole).subscribe((res) => {
      if (res) {
        this.messages = res;
        if (this.activeRole === 'cp') {
          this.messagesRelatedToWb = this.messages.filter(m => m.sentBy === 'wb' || m.sentTo === 'wb');
          this.messagesRelatedToOe = this.messages.filter(m => m.sentBy === 'oe' || m.sentTo === 'oe');
        }
      }
      this.messagesLoading = false;
    }, () => {
      this.messages = undefined;
      this.messagesRelatedToWb = undefined;
      this.messagesRelatedToOe = undefined;
      this.messagesLoading = false;
    });
  }

  public newMessageTextChanged(event: ContentChange): void {
    this.newMessageTextLength = event.text.length - 1;
  }

  ngAfterViewChecked(): void {
    // scroll to bottom of messages container everytime it gets updated
    if (this._messagesContainer && this._previousMessagesContainerScrollHeight < this._messagesContainer.nativeElement.scrollHeight) {
      this._messagesContainer.nativeElement.scrollTop = this._messagesContainer.nativeElement.scrollHeight;
      this._previousMessagesContainerScrollHeight = this._messagesContainer.nativeElement.scrollHeight;
    }
  }

  public setActiveChat(): void {
    this.cpViewingMessagesRelatedTo = this.cpViewingMessagesRelatedTo === 'wb' ? 'oe' : 'wb';
    this.attachment = undefined;
    this._messageFormData = new FormData();
    this.newMessageTextHtml = '';
    this.newMessageTextLength = 0;
  }

  public sendTextMessage(sentTo: string): void {
    if (this.newMessageTextLength === 0) {
      this._translate.get('REPORT.CHAT.N_MESSAGE_EMPTY').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    this._confirmSendMessage('text', sentTo);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public messageOnFileSelected(event: any, sentTo: string): void {
    const attachment: File = event.target.files[0];

    if (!attachment) {
      return;
    }

    if (attachment.size > Constants.maxSizeOfInputFile) {
      this._translate.get('GENERAL.N_ATTACHMENT_SIZE_LIMIT').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    const newId = this._attachmentLastId.toString();
    this._attachmentLastId++;
    this.attachment = new Attachment(newId, attachment.name);
    this._messageFormData.set('attachment', attachment);

    this._confirmSendMessage('attachment', sentTo);
  }

  private _confirmSendMessage(messageType: string, sentTo: string): void {
    this._translate.get(messageType === 'attachment'
      ? 'REPORT.CHAT.DIALOG_CONFIRM_ATTACHMENT_MESSAGE'
      : 'REPORT.CHAT.DIALOG_CONFIRM_TEXT_MESSAGE'
    ).subscribe((dialogTitle) => {
      this._translate.get('GENERAL.CANNOT_BE_UNDONE').subscribe((dialogBody) => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '40rem',
          panelClass: 'dialog-container',
          data: {
            title: dialogTitle,
            body: dialogBody,
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this._sendMessageToServer(messageType, sentTo);
          }
        });
      });
    });
  }

  private _sendMessageToServer(messageType: string, sentTo: string): void {
    if (!this.report) {
      return;
    }

    this.messagesLoading = true;

    const resultObservable = messageType === 'attachment'
      ? this._messageService.sendAttachmentMessage(this.report._id, this._messageFormData, undefined, this.activeRole, sentTo)
      : this._messageService.sendTextMessage(this.report._id, this.newMessageTextHtml, undefined, this.activeRole, sentTo);

    resultObservable.subscribe((res) => {
      if (res) {
        if (messageType === 'attachment') {
          this.attachment = undefined;
          this._messageFormData = new FormData();
        } else {
          this.newMessageTextHtml = '';
          this.newMessageTextLength = 0;
        }
        this.appData.setNewMessage(res);
      }
      this.messagesLoading = false;
    }, () => {
      this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      this.messagesLoading = false;
    });
  }

  public downloadMessageAttachment(message: Message): void {
    if (!message.attachment) {
      return;
    }

    this.app.showLoading();
    this._messageService.downloadAttachment(
      message._id,
      message.attachment.id,
      undefined,
      this.activeRole,
    ).subscribe((res) => {
      if (res && message.attachment) {
        Utilities.downloadFile(res, message.attachment.name);
        this._translate.get('GENERAL.N_FILE_DOWNLOADED').subscribe((res: string) => {
          this.app.buildNotification(res);
        }).unsubscribe();
      }
      this.app.hideLoading();
    }, () => {
      this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      this.app.hideLoading();
    });
  }
}
