<h2 class="custom-h2">Stav</h2>

<ol class="stepper">
  <li *ngFor="let state of report.states; last as isLast" [class]="isLast && utilities.canAccountEdit(account) ? 'step step-active' : 'step'">
    <div class="step-number" (click)="goToState(state, isLast)">{{ state.number }}</div>
    <div [class]="isLast ? '' : 'step-line'">
      <div></div>
    </div>
    <div class="step-label" (click)="goToState(state, isLast)">{{ 'OVERVIEW.DETAIL.STATE_LABELS.' + reportStateHelper.getStateLabelTranslateId(state,
      isLast) | translate:{ tillDays: reportStateHelper.getStateTillDays(report) } }}
    </div>
    <section [id]="state.id + '-archive-container'" class="step-content height-transition-container"
      [class]="stateArchiveHiddenMap.get(state.id) ? 'height-transition-visible' : 'height-transition-hidden'">
      <state-archive [report]="report" [state]="state"></state-archive>
    </section>
  </li>
</ol>

<p *ngIf="isStateArchivedForRole" class="states-right-notice" translate>OVERVIEW.DETAIL.STATES_OVERVIEW.REPORT_ARCHIVED
</p>
<p *ngIf="!isStateArchivedForRole && utilities.canAccountEdit(account)" class="states-right-notice" translate>OVERVIEW.DETAIL.STATES_OVERVIEW.STATES_WILL_FOLLOW
</p>
<p *ngIf="!isStateArchivedForRole && !utilities.canAccountEdit(account)" class="states-right-notice" translate>OVERVIEW.DETAIL.STATES_OVERVIEW.NOTICE_LOCKED_READ_ONLY
</p>