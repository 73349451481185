<div class="content-container">
  <div class="content-centered">
    <h1 *ngIf="organization.categories.length > 1" class="custom-h1" [translateParams]="{categoryName: reportCategory.name}" translate>REPORT.REPORT.HEADING</h1>
    <h1 *ngIf="organization.categories.length <= 1" class="custom-h1" translate>REPORT.REPORT.HEADING_WITHOUT_CATEGORY</h1>
    <div class="visual-container">
      <form>
        <label for="report-subject" class="label-primary" translate>REPORT.REPORT.SUBJECT_LABEL</label>
        <div class="element-hint-below">
          <input id="report-subject" name="report-subject" type="input" class="input-primary"
            [(ngModel)]="reportSubject" #reportsubject="ngModel" maxlength="200">
          <div class="hint-multi">
            <span translate>REPORT.REPORT.SUBJECT_HINT</span>
            <span *ngIf="reportSubject.length > 99">{{ reportSubject.length }}/200</span>
          </div>
        </div>

        <label for="report-body" class="label-primary" translate>REPORT.REPORT.BODY_LABEL</label>
        <div id="report-body-holder" class="element-hint-below">
          <textarea id="report-body" name="report-body" class="textarea-primary" [(ngModel)]="reportBody" required
            #reportbody="ngModel" placeholder="{{'REPORT.REPORT.BODY_PLACEHOLDER' | translate }}" maxlength="100000"
            rows="15" cols="50" wrap="hard" (input)="onTextareaInput($event)"></textarea>
          <div class="hint-multi">
            <span></span>
            <span *ngIf="reportBody.length > 85000">{{ reportBody.length }} / 100 000</span>
          </div>
        </div>

        <button type="button" (click)="toggleOptionalFields()" class="button-primary" translate>
          REPORT.REPORT.OPTIONAL_FIELDS<mat-icon fontSet="material-icons-outlined">{{ optionalFieldsVisible ?
            'expand_less'
            :
            'expand_more' }}</mat-icon>
        </button>

        <section class="height-transition-container"
          [class]="optionalFieldsVisible ? 'height-transition-visible' : 'height-transition-hidden'">

          <label for="whistleblower-name" class="label-primary" translate>REPORT.REPORT.WB_NAME_LABEL</label>
          <div class="element-hint-below">
            <input id="whistleblower-name" name="whistleblower-name" class="input-primary" type="input"
              [(ngModel)]="whistleblowerName" #whistleblowername="ngModel" maxlength="100">
            <div class="hint-multi">
              <span translate>REPORT.REPORT.WB_NAME_HINT</span>
              <span *ngIf="whistleblowerName.length > 49">{{ whistleblowerName.length }}/100</span>
            </div>
          </div>

          <label for="whistleblower-date-of-birth" class="label-primary"
            translate>REPORT.REPORT.DATE_OF_BIRTH_LABEL</label>
          <div class="element-hint-below">
            <input id="whistleblower-date-of-birth" name="whistleblower-date-of-birth" type="date" class="input-primary"
              [(ngModel)]="whistleblowerDateOfBirth" #whistleblowerdateofbirth="ngModel" maxlength="100">
            <span class="hint-single" translate>REPORT.REPORT.DATE_OF_BIRTH_HINT</span>
          </div>

          <label for="whistleblower-address" class="label-primary" translate>REPORT.REPORT.POST_LABEL</label>
          <div class="element-hint-below">
            <input id="whistleblower-address" name="whistleblower-address" type="input" class="input-primary"
              [(ngModel)]="whistleblowerAddress" #whistlebloweraddress="ngModel" maxlength="200">
            <div class="hint-multi">
              <span translate>REPORT.REPORT.POST_HINT</span>
              <span *ngIf="whistleblowerAddress.length > 149">{{ whistleblowerAddress.length }}/200</span>
            </div>
          </div>

          <div class="file-input-container">
            <input type="file" class="file-input" (change)="onFileSelected($event)" #fileupload>
            <button type="button" class="button-primary"
              (click)="attachments.length < 5 ? fileupload.click() : cannotUploadMore()" translate>
              REPORT.REPORT.UPLOAD_ATTACHMENT<mat-icon fontSet="material-icons-outlined">attach_file</mat-icon>
            </button>
            <span *ngFor="let attachment of attachments" class="uploaded-file-container">
              {{ attachment.name }}
              <span class="icon-button" aria-label="Remove attachment" (click)="removeAttachment(attachment.id)">
                <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
              </span>
            </span>
          </div>
        </section>

        <mat-checkbox id="terms-accepted" name="terms-accepted" type="checkbox" color="primary"
          [(ngModel)]="termsAccepted" translate>{{ 'REPORT.REPORT.WB_AGREES_WITH' | translate }} <a
            href="https://reportys.cz/assets/Zasady_zpracovani_Reportys_v062023.pdf" target="_blank"
            rel="noopener noreferrer" translate>REPORT.REPORT.PRIVACY</a>
        </mat-checkbox>
        <div class="centered">
          <button id="send-report" type="submit" class="button-primary button-confirm" (click)="sendReport()" translate>
            REPORT.REPORT.CONFIRM<mat-icon fontSet="material-icons-outlined">forward_to_inbox</mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>