/* eslint-disable @typescript-eslint/ban-types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Note, Report, ReportForWhistleblower } from '../models/report';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private _reportApiUrl = 'api/report';

  constructor(
    private _http: HttpClient,
  ) { }

  /**
   * 
   * @param reportFormData 
   * @returns Observable with generated report identification code.
   */
  send(reportFormData: FormData, orgIdentificationCode: string): Observable<{ identificationCode: string }> {
    reportFormData.set('identificationCode', orgIdentificationCode);
    return this._http.post<{ identificationCode: string }>(this._reportApiUrl, reportFormData)
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  getAll(organizationId: string, activeRole: string): Observable<Report[]> {
    return this._http.get<Report[]>(this._reportApiUrl, { params: { organizationId: organizationId, activeRole: activeRole } })
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  getByIdentificationCode(identificationCode: string): Observable<ReportForWhistleblower> {
    return this._http.post<ReportForWhistleblower>(`${this._reportApiUrl}/identification_code`,
      { identificationCode: identificationCode }
    ).pipe(
      catchError((error) => {
        console.error(error);
        // rethrow status back to the component
        return throwError(error);
      })
    );
  }

  subscribeForReportUpdate(identificationCode: string, email: string): Observable<{}> {
    return this._http.post<{}>(`${this._reportApiUrl}/subscribe_for_notifications`,
      {
        identificationCode: identificationCode,
        email: email,
      }
    ).pipe(
      catchError((error) => {
        console.error(error);
        // rethrow status back to the component
        return throwError(error);
      })
    );
  }

  changeCategory(reportId: string, newCategoryId: string): Observable<{ reportLastUpdated: Date }> {
    return this._http.patch<{ reportLastUpdated: Date }>(`${this._reportApiUrl}/${reportId}/change_category`,
      {
        newCategoryId: newCategoryId,
      }
    )
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  seen(reportId: string, activeRole: string): Observable<{ reportLastUpdated: Date }> {
    return this._http.patch<{ reportLastUpdated: Date }>(`${this._reportApiUrl}/${reportId}/seen`, {}, { params: { activeRole: activeRole } })
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  downloadAttachment(
    reportId: string,
    attachmentId: string,
    activeRole: string,
    identificationCode: string | null = null,
    organizationId: string | null = null,
  ): Observable<Blob> {
    return this._http.post<Blob>(`${this._reportApiUrl}/${reportId}/attachment/${attachmentId}`,
      identificationCode && organizationId ? {
        identificationCode: identificationCode,
        organizationId: organizationId,
      } : {},
      {
        responseType: 'blob' as 'json',
        params: { activeRole: activeRole },
      })
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  createNote(note: Note, reportId: string, activeRole: string): Observable<{ id: number, reportLastUpdated: Date }> {
    return this._http.post<{ id: number, reportLastUpdated: Date }>(`${this._reportApiUrl}/${reportId}/note`, { note: note }, { params: { activeRole: activeRole } })
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  updateNote(note: Note, reportId: string, activeRole: string): Observable<{ id: number, reportLastUpdated: Date }> {
    return this._http.put<{ id: number, reportLastUpdated: Date }>(`${this._reportApiUrl}/${reportId}/note/${note.id}`, { note: note }, { params: { activeRole: activeRole } })
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }

  deleteNote(note: Note, reportId: string, activeRole: string): Observable<{ id: number, reportLastUpdated: Date }> {
    return this._http.delete<{ id: number, reportLastUpdated: Date }>(`${this._reportApiUrl}/${reportId}/note/${note.id}`, { params: { activeRole: activeRole } })
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }
}
