<ol class="stepper">
  <li class="step step-active step-no-action">
    <div class="step-number">{{ state.number }}</div>
    <div class="step-label">{{ 'OVERVIEW.DETAIL.STATE_LABELS.' + reportStateHelper.getStateLabelTranslateId(state, true) | translate:{ tillDays: reportStateHelper.getStateTillDays(report) } }}</div>
  </li>
</ol>

<button id="go-back" type="button" class="inline-button go-back-in-states-button" (click)="goBack()" translate>
  <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>GENERAL.STEP_BACK
</button>

<section class="shrinked shrinked-more-width">
  <label class="label-primary" translate>OVERVIEW.DETAIL.NOTIFY_CP_ACCEPTED.TEXT_FOR_CP_LABEL</label>
  <div class="element-hint-below">
    <quill-editor class="editor" [(ngModel)]="state.notifyCompetentPersonTextHtml" placeholder=""
      [styles]="{'min-height': '25rem'}" [maxLength]="100000" [required]="true" [sanitize]="true"
      (onContentChanged)="textChanged($event)"></quill-editor>
    <div class="hint-multi">
      <span></span>
      <span *ngIf="notifyCompetentPersonTextLength > 85000">{{ notifyCompetentPersonTextLength }} / 100 000</span>
    </div>
  </div>

  <div class="file-input-container">
    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileupload>
    <button type="button" class="button-primary button-shrinked" (click)="fileupload.click()" translate>
      OVERVIEW.DETAIL.NOTIFY_CP_EXECUTION.UPLOAD_ATTACHMENT<mat-icon fontSet="material-icons-outlined">
        attach_file</mat-icon>
    </button>
    <span *ngIf="state.attachment" class="uploaded-file-container">
      {{ state.attachment.name }}
      <span class="icon-button" aria-label="Remove attachment" (click)="removeAttachment()">
        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
      </span>
    </span>
  </div>

  <button type="button" class="button-primary button-confirm" (click)="confirm()" translate>
    OVERVIEW.DETAIL.NOTIFY_CP_ACCEPTED.CONFIRM
  </button>
</section>