<ol class="stepper">
  <li class="step step-active step-no-action">
    <div class="step-number">{{ state.number }}</div>
    <div class="step-label">{{ 'OVERVIEW.DETAIL.STATE_LABELS.' + reportStateHelper.getStateLabelTranslateId(state, true) | translate:{ tillDays: reportStateHelper.getStateTillDays(report) } }}
    </div>
  </li>
</ol>

<button type="button" class="inline-button go-back-in-states-button" (click)="goBack()" translate>
  <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>GENERAL.STEP_BACK
</button>

<section class="shrinked shrinked-more-width">
  <label for="report-summary" class="label-primary" translate>OVERVIEW.DETAIL.SUGGESTION_FOR_OE.SUMMARY_LABEL</label>
  <div class="element-hint-below">
    <input id="report-summary" name="report-summary" type="input" class="input-primary"
      [(ngModel)]="report.summaryByCompetentPerson" #reportsummary="ngModel" maxlength="200">
    <div class="hint-multi">
      <span translate>OVERVIEW.DETAIL.SUGGESTION_FOR_OE.SUMMARY_HINT</span>
      <span *ngIf="report.summaryByCompetentPerson && report.summaryByCompetentPerson.length > 99">{{
        report.summaryByCompetentPerson.length }}/200</span>
    </div>
  </div>

  <label class="label-primary" translate>OVERVIEW.DETAIL.SUGGESTION_FOR_OE.SUMMARY_LONG_LABEL</label>
  <div class="element-hint-below">
    <quill-editor class="editor" [(ngModel)]="report.summaryLongByCompetentPersonHtml" placeholder=""
      [styles]="{'min-height': '25rem'}" [maxLength]="100000" [required]="true" [sanitize]="true"
      (onContentChanged)="reportSummaryTextChanged($event)"></quill-editor>
    <div class="hint-multi">
      <span translate>OVERVIEW.DETAIL.SUGGESTION_FOR_OE.SUMMARY_LONG_HINT</span>
      <span *ngIf="summaryLongByCompetentPersonTextLength > 85000">{{ summaryLongByCompetentPersonTextLength }} / 100 000</span>
    </div>
  </div>

  <label class="label-primary" translate>OVERVIEW.DETAIL.SUGGESTION_FOR_OE.SUGGESTION_LABEL</label>
  <div class="element-hint-below">
    <quill-editor class="editor" [(ngModel)]="report.suggestionByCompetentPersonHtml" placeholder=""
      [styles]="{'min-height': '25rem'}" [maxLength]="100000" [required]="true" [sanitize]="true"
      (onContentChanged)="suggestionTextChanged($event)"></quill-editor>
    <div class="hint-multi">
      <span></span>
      <span *ngIf="suggestionByCompetentPersonTextLength > 85000">{{ suggestionByCompetentPersonTextLength }} / 100 000</span>
    </div>
  </div>

  <div class="file-input-container">
    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileupload>
    <button type="button" class="button-primary button-shrinked" (click)="fileupload.click()" translate>
      OVERVIEW.DETAIL.SUGGESTION_FOR_OE.UPLOAD_ATTACHMENT<mat-icon fontSet="material-icons-outlined">
        attach_file</mat-icon>
    </button>
    <span *ngIf="report.attachmentByCompetentPerson" class="uploaded-file-container">
      {{ report.attachmentByCompetentPerson.name }}
      <span class="icon-button" aria-label="Remove attachment" (click)="removeAttachment()">
        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
      </span>
    </span>
  </div>

  <p class="right-notice" translate>GENERAL.REQUIRED_FIELDS</p>

  <button type="button" class="button-primary button-confirm" (click)="confirm()" translate>
    OVERVIEW.DETAIL.SUGGESTION_FOR_OE.CONFIRM
  </button>
</section>