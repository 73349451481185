import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReportCategory } from '../models/report-category';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private _organizationApiUrl = 'api/organization';

  constructor(
    private _http: HttpClient,
  ) { }

  get(identificationCode: string): Observable<{ _id: string, name: string, language: string, state: string, categories: ReportCategory[] }> {
    return this._http.get<{ _id: string, name: string, language: string, state: string, categories: ReportCategory[] }>(`${this._organizationApiUrl}/${identificationCode}`,)
      .pipe(
        catchError((error) => {
          console.error(error);
          // rethrow status back to the component
          return throwError(error);
        })
      );
  }
}
