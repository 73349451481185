<div class="content-container">
  <div class="content-centered">
    <h1 class="custom-h1" translate>ACCOUNT.HEADING</h1>

    <a [routerLink]="[activeRole === 'oa' ? '/organization-administration' : '/overview/reports']" class="a-btn a-primary top-left-action-button" translate>
      <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>ACCOUNT.BACK
    </a>

    <div class="visual-container">
      <section class="account-info">
        <button type="button" class="inline-button" aria-label="Edit account" (click)="editAccountSelf()">
          <h2>{{ account?.name }}</h2>
          <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
        </button>
        <span *ngFor="let role of account?.roles">{{ utilities.getRoleTextId(role) | translate }}</span>
      </section>
      <form>
        <a [routerLink]="['/account/change-password']" class="a-btn" translate>
          ACCOUNT.CHANGE_PASSWORD<mat-icon fontSet="material-icons-outlined">lock_open</mat-icon>
        </a>
        <button type="submit" class="button-primary" (click)="logout()" translate>
          GENERAL.LOGOUT<mat-icon fontSet="material-icons-outlined" class="rotate-180-deg">login</mat-icon>
        </button>
      </form>
    </div>
  </div>
</div>