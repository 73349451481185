import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Account } from "../models/account";
import { Message } from "../models/message";
import { Organization } from "../models/organization";
import { Report, ReportForWhistleblower } from "../models/report";
import { ReportCategory } from "../models/report-category";
import { ReportState } from "../models/report-state";

/**
 * Class representing app data, should be treated as singleton
 */
@Injectable({
  providedIn: 'root'
})
export class AppData {
  private _organizationSource = new BehaviorSubject<Organization>(new Organization('', '', 'cz', '', '', []));
  public organization: Observable<Organization> = this._organizationSource.asObservable();

  private _accountSource = new BehaviorSubject<Account | null>(null);
  public account: Observable<Account | null> = this._accountSource.asObservable();

  private _activeRoleSource = new BehaviorSubject<string | null>(null);
  public activeRole: Observable<string | null> = this._activeRoleSource.asObservable();

  private _activeCategorySource = new BehaviorSubject<ReportCategory | null>(null);
  public activeCategory: Observable<ReportCategory | null> = this._activeCategorySource.asObservable();

  private _reportsSource = new BehaviorSubject<Report[] | null>(null);
  public reports: Observable<Report[] | null> = this._reportsSource.asObservable();

  private _activeReportSource = new BehaviorSubject<Report | null>(null);
  public activeReport: Observable<Report | null> = this._activeReportSource.asObservable();

  private _activeReportStateSource = new BehaviorSubject<ReportState | null>(null);
  public activeReportState: Observable<ReportState | null> = this._activeReportStateSource.asObservable();

  private _newMessageSource = new BehaviorSubject<Message | null | undefined>(null);
  public newMessage: Observable<Message | null | undefined> = this._newMessageSource.asObservable();

  public reportIdentificationCode: string | null = null;
  public reportForWhistleblower: ReportForWhistleblower | null = null;

  public forcedLogout = false;

  public setOrganization(organization: Organization): void {
    this._organizationSource.next(organization);
  }

  public get organizationValue(): Organization {
    return this._organizationSource.value;
  }

  public setAccount(account: Account): void {
    // if (account.categories !== undefined && account.categories.length > 0 && account.categories[0].id === undefined) {
    //   console.log(account)
    // }
    this._accountSource.next(account);
  }

  public get accountValue(): Account | null {
    return this._accountSource.value;
  }

  public setActiveRole(role: string): void {
    this._activeRoleSource.next(role);
  }

  public get activeRoleValue(): string | null {
    return this._activeRoleSource.value;
  }

  public setActiveCategory(category: ReportCategory): void {
    this._activeCategorySource.next(category);
  }

  public get activeCategoryValue(): ReportCategory | null {
    return this._activeCategorySource.value;
  }

  /**
   * 
   * @param reports if null => force reports overview to reload reports
   */
  public setReports(reports: Report[] | null): void {
    this._reportsSource.next(reports);
  }

  public get reportsValue(): Report[] | null {
    return this._reportsSource.value;
  }

  public replaceSingleReport(newReport: Report): void {
    if (!this.reportsValue) {
      return;
    }
    this.setReports(this.reportsValue.map(report => report._id === newReport._id ? newReport : report));
  }

  public setActiveReport(report: Report): void {
    this._activeReportSource.next(report);
  }

  public get activeReportValue(): Report | null {
    return this._activeReportSource.value;
  }

  public setActiveReportState(reportState: ReportState | null): void {
    this._activeReportStateSource.next(reportState);
  }

  public get activeReportStateValue(): ReportState | null {
    return this._activeReportStateSource.value;
  }

  public setNewMessage(message: Message | null | undefined): void {
    this._newMessageSource.next(message);
  }

  public get newMessageValue(): Message | null | undefined {
    return this._newMessageSource.value;
  }

  public wipe(): void {
    this._accountSource.next(null);
    this._activeRoleSource.next(null);
    this._reportsSource.next(null);
    this._activeReportSource.next(null);
    this._activeReportStateSource.next(null);
    this._newMessageSource.next(null);
  }

  public autoLogout(): void {
    this.forcedLogout = true;
    this.wipe();
  }
}