import { ReportCategory } from "./report-category";

export class Organization {
  _id: string;
  name: string;
  language: string;
  identificationCode: string;
  state: string;
  categories!: ReportCategory[];

  constructor(id: string, name: string, language: string, identificationCode: string, state: string, categories: ReportCategory[]) {
    this._id = id;
    this.name = name;
    this.language = language;
    this.identificationCode = identificationCode;
    this.state = state;
    this.categories = categories;
  }
}