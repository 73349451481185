import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { Account, AccountAuthentication, AccountFromServer } from 'src/app/models/account';
import { Organization } from 'src/app/models/organization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { AppData } from 'src/app/singletons/app-data';
import { Utilities } from 'src/app/utilities/utilities';
import { ChooseOrganizationDialogComponent } from '../../widgets/choose-organization-dialog/choose-organization-dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public accountAuthentication: AccountAuthentication;

  public emailPlaceholder = '';

  public rememberCredentials = false;

  constructor(
    private _organizationService: OrganizationService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _route: ActivatedRoute,
    public app: AppComponent,
    private _appData: AppData,
    private _translate: TranslateService,
    private _dialog: MatDialog,
  ) {
    this.accountAuthentication = new AccountAuthentication('', '');
    this._translate.get('AUTH.LOGIN.EMAIL_PLACEHOLDER').subscribe((res: string) => {
      this.emailPlaceholder = res;
    }).unsubscribe();
  }

  ngOnInit(): void {
    if (this._appData.accountValue) {
      this._redirect();
      return;
    }

    const rememberCredentials = localStorage.getItem('rememberCredentials');
    this.rememberCredentials = rememberCredentials !== null && rememberCredentials === 'true';
    if (this.rememberCredentials) {
      const rememberedEmail = localStorage.getItem('email');
      this.accountAuthentication.email = rememberedEmail ?? '';
    }

    if (this._appData.forcedLogout) {
      this._appData.forcedLogout = false;
      this._translate.get('AUTH.LOGIN.N_FORCED_LOGOUT').subscribe((res: string) => {
        this.app.buildNotification(res, 8);
      }).unsubscribe();
    }

    this.revalidateToken();
  }

  revalidateToken(): void {
    this._authenticationService
      .revalidateToken()
      .subscribe((account) => {
        if (account) {
          this._setAccount(account);
        }
      });
  }

  authenticate(): void {
    if (!this.accountAuthentication.email
      || !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(this.accountAuthentication.email)) {
      this._translate.get('AUTH.LOGIN.N_EMAIL_FORMAT').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    if (!this.accountAuthentication.password
      || !/(?=.*\d)(?=.*[a-z,A-Z]).{8,}/.test(this.accountAuthentication.password)) {
      this._translate.get('AUTH.LOGIN.N_FILL_PASSWORD').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
      return;
    }

    this.app.showLoading();
    this._authenticationService
      .authenticate(this.accountAuthentication)
      .subscribe((value: { account: AccountFromServer | null, pretoken: string | null, organizations: Organization[] | null }) => {
        this.app.hideLoading();
        if (value.account) {
          // login normally
          this._setAccount(value.account);
        } else {
          // firstly, choose the organization
          this._translate.get('AUTH.LOGIN.N_CHOOSE_ORGANIZATION').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();

          let chosenOrganization = new Organization('', '', '', '', '', []);
          const dialogRef = this._dialog.open(ChooseOrganizationDialogComponent, {
            width: '40rem',
            panelClass: 'dialog-container',
            data: { organizations: value.organizations, chosenOrganization: chosenOrganization }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (!result || result._id === '') {
              this._translate.get('AUTH.LOGIN.N_NOT_CHOSEN_ORGANIZATION').subscribe((res: string) => {
                this.app.buildNotification(res);
              }).unsubscribe();
              return;
            }
            chosenOrganization = result;

            this.app.showLoading();
            this._authenticationService
              .chooseOrganization(chosenOrganization)
              .subscribe(account => {
                this._setAccount(account);
                this.app.hideLoading();
              }, () => {
                this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
                  this.app.buildNotification(res);
                }).unsubscribe();
                this.app.hideLoading();
              });
          });
        }
      }, (errorStatus) => {
        if (errorStatus < 500) {
          this._translate.get('AUTH.LOGIN.N_EMAIL_OR_PASSWORD_BAD').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
        } else {
          this._translate.get('GENERAL.N_SOMETHING_WENT_WRONG').subscribe((res: string) => {
            this.app.buildNotification(res);
          }).unsubscribe();
        }
        this.app.hideLoading();
      });
  }

  private _setAccount(account: Account | AccountFromServer): void {
    localStorage.setItem('identificationCode', account.organizationId);
    localStorage.setItem('email', this.accountAuthentication.email);
    if (this._appData.organizationValue.identificationCode.length === 0
      || this._appData.organizationValue._id !== account.organizationId) {
      // organization is unknown for now or is not set right, try to find it by organizationId
      this._organizationService
        .get(account.organizationId)
        .subscribe((res) => {
          if (res) {
            this._appData.setOrganization(new Organization(res._id, res.name, res.language, res._id, res.state, res.categories));
            localStorage.setItem('identificationCode', res._id);
            this._setAccountContinue(account);
          }
        });
    } else {
      // org should be known
      this._setAccountContinue(account);
    }
  }

  private _setAccountContinue(account: Account | AccountFromServer): void {
    if (this._appData.organizationValue && this._appData.organizationValue.state === 'demo' && !account.lastLogin) {
      // when first logged and demo, show demo info
      this.app.showDemoInfo();
    }
    const accountFromServer = Utilities.transformToClassInstance(AccountFromServer, account);
    this._appData.setAccount(accountFromServer.categoriesIds !== undefined ? accountFromServer.toAccount(this._appData.organizationValue) : account as Account);
    this._appData.setActiveRole(account.roles[0]);
    this._redirect();
  }

  private _redirect(): void {
    const defaultRoute = this._appData.activeRoleValue === 'oa' ? '/organization-administration' : '/overview/reports';
    const returnUrl = this._route.snapshot.queryParamMap.get('returnUrl');
    this._router.navigate([returnUrl ?? defaultRoute]);
    if (!returnUrl) {
      this._translate.get('AUTH.LOGIN.N_LOGIN_SUCCESSFUL').subscribe((res: string) => {
        this.app.buildNotification(res);
      }).unsubscribe();
    }
  }

  public changeRememberCredentials(): void {
    localStorage.setItem('rememberCredentials', this.rememberCredentials ? 'true' : 'false');
  }
}
