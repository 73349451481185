import { Component, OnInit } from '@angular/core';
import { Account } from 'src/app/models/account';
import { AppData } from 'src/app/singletons/app-data';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-organization-administration',
  templateUrl: './organization-administration.component.html',
  styleUrls: ['./organization-administration.component.css']
})
export class OrganizationAdministrationComponent implements OnInit {

  public account!: Account | null;

  public utilities = Utilities;

  constructor(
    private _appData: AppData,
  ) { }

  ngOnInit(): void {
    this.account = this._appData.accountValue;
  }

}
