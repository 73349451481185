<ng-template #template>
  <!-- Notify now -->
  <section class="shrinked shrinked-more-width">
    <label class="label-primary" translate>OVERVIEW.DETAIL._NOTIFY_ABOUT.NOTIFY_NOW.TEXT_LABEL</label>
    <div class="element-hint-below">
      <quill-editor class="editor" [(ngModel)]="notifyAboutObject.notifyNowTextHtml" placeholder=""
        [styles]="{'min-height': '25rem'}" [maxLength]="100000" [required]="true" [sanitize]="true"
        (onContentChanged)="notifyNowTextChanged($event)"></quill-editor>
      <div class="hint-multi">
        <span translate>OVERVIEW.DETAIL._NOTIFY_ABOUT.NOTIFY_NOW.TEXT_HINT</span>
        <span *ngIf="notifyNowTextLength > 85000">{{ notifyNowTextLength }} / 100 000</span>
      </div>
    </div>

    <button type="button" class="button-primary button-confirm" (click)="goToNextStateNotifyNow.emit()" translate>
      OVERVIEW.DETAIL._NOTIFY_ABOUT.NOTIFY_NOW.CONFIRM
    </button>
  </section>
</ng-template>